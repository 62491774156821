import React from 'react';

import IconChevronUp from '../../icons/IconChevronUp';

import './TourOptionsItem.scss';

export function TourOptionsItem(props: {
  tourID: any;
  tourName: any;
  selectedTour: number | undefined;
  numbers: Array<number>;
  onClick: () => void;
  onChange: () => void;
}) {
  return (
    <div className='buttons TourOptionsItem'>
      <input type='radio' name='checkbox' onChange={props.onChange} checked={props.selectedTour === props.tourID} />
      <button className='curated-tour-button' onClick={props.onClick}>
        {props.tourName}
      </button>
      <button className={'TourOptionsItem__description-toggle'} onClick={props.onClick}>
        <IconChevronUp rotate={props.numbers.includes(props.tourID) ? 0 : 180} />
      </button>
    </div>
  );
}
