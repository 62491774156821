import React from 'react';

import './PlantSearchResultsNoResults.scss';

export const PlantSearchResultsNoResults = () => {
  return (
    <div id='PlantSearchResultsNoResults'>
      <h3 className='PlantSearchResultsNoResults__title'>
        No plants were found.
        <br />
        Please refine search and try again.
      </h3>
    </div>
  );
};
