import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { selectShareModalActive, setShareModalActive } from '../../store/app/appSlice';

import Modal from '../modal/Modal';
import Share from './Share';
import IconShare from '../icons/IconShare';

const ShareWidget = () => {
  const dispatch = useDispatch();
  const shareModalActive = useSelector(selectShareModalActive);

  return (
    <>
      <button className='share-button-container' onClick={() => dispatch(setShareModalActive(!shareModalActive))}>
        <IconShare />
      </button>
      {shareModalActive && (
        <Modal
          open={true}
          showModal={shareModalActive}
          mainModalPaddingHide={true}
          handleClose={() => dispatch(setShareModalActive(!shareModalActive))}
          content={<Share />}
        />
      )}
    </>
  );
};

export default ShareWidget;
