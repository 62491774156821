import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { $searchTerms, $searchType, $searchTypeAdvanced, setSearchTerms } from '../../../store/app/appSlice';

import { alphabet } from '../../../config/plant.config';
import { setURLPath } from '../../../utils/query.utils';
import { getResultKey } from '../../../utils/plant.util';

import './SearchPlantsAlphabet.scss';

export default function SearchPlantsAlphabet() {
  const dispatch = useDispatch();
  const searchTerms = useSelector($searchTerms);
  const searchType = useSelector($searchType);
  const searchTypeAdvanced = useSelector($searchTypeAdvanced);
  const searchTermsKey = getResultKey('Advanced', searchTypeAdvanced);
  const searchValue = searchTerms[searchTermsKey];

  const handleSearchPlantsAlphabetLetterSelection = (letter: string) => {
    dispatch(setSearchTerms({ ...searchTerms, [searchTermsKey]: letter }));
    setURLPath(searchType, searchTypeAdvanced, letter);
  };

  return (
    <div id='SearchPlantAlphabet' className='SearchPlantsAlphabet slider'>
      {alphabet.map((letter: string, index: number) => {
        const regex = new RegExp(/[a-zA-Z]/gi);

        // Check if character is letter
        if (regex.test(letter)) {
          return (
            <button
              key={index}
              className={`SearchPlantAlphabet__letter ${letter === 'Explore' ? 'explore-by-location' : ''} ${
                searchValue === letter ? 'selected' : ''
              }`}
              onClick={() => handleSearchPlantsAlphabetLetterSelection(letter)}
            >
              {letter}
            </button>
          );
        }
      })}
    </div>
  );
}
