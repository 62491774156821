/**
 * Fetch Legend Data
 * @param url - Rest endpoint URL
 * @return Promise - Legend data
 */
export function fetchLegendData(url: string) {
  return fetch(url + '/legend?f=pjson')
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      console.log('Error fetching Legend Data', e);
      return undefined;
    });
}

/**
 * Get Image Base64 Src string
 * @param item - Legend item
 * @return string - Base64 Image string
 */
export function getImageSrc(item: { imageData: string; contentType: string }) {
  return `data:${item.contentType};base64,${item.imageData}`;
}
