import React from 'react';

export function ModalMain(props: { content?: any; mainModalPaddingHide?: boolean }) {
  const { content, mainModalPaddingHide } = props;
  return (
    <div id='ModalMain' data-main-modal-padding-hide={mainModalPaddingHide}>
      {content}
    </div>
  );
}
