import React from 'react';
import { Link } from 'react-router-dom';

import { splitByCharacter } from '../../../../utils/plantSyntax.util';

// Interface
import { PlantAllNames } from '../../../../interfaces/plantData';

import './PlantResultGenusCommonNamePrimary.scss';
import { getPath } from '../../../../utils/path.util';

export default function PlantResultGenusCommonNamePrimary({ plant }: { plant: PlantAllNames }) {
  const { GENUS, GENUS_COMMON_NAME_PRIMARY } = plant;

  const genusCommonNamePrimaryValue = splitByCharacter(GENUS_COMMON_NAME_PRIMARY);

  return (
    <Link className='genus_common_name_primary' to={getPath('genus', GENUS)}>
      {genusCommonNamePrimaryValue},
    </Link>
  );
}
