import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { $adaSublayersTitles, setActiveLayers } from '../../../store/app/layerSlice';

import { RootState } from '../../../store/store';

import IconWheelchair from '../../icons/IconWheelchair';

export function AccessibilityWidget() {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const activeLayerToggles = useSelector((state: RootState) => state.layerReducer.activeLayerToggles);
  const adaSublayersTitles = useSelector($adaSublayersTitles);

  const updateLayerToggle = () => {
    const adaSublayersON = [...activeLayerToggles].filter((layerID) => adaSublayersTitles.includes(layerID));
    const adaSublayersOFF = adaSublayersTitles.filter((layerID: string) => !adaSublayersON.includes(layerID));

    if (visible) {
      adaSublayersON.forEach((layerID: string) => dispatch(setActiveLayers(layerID)));
    } else {
      adaSublayersOFF.forEach((layerID: string) => dispatch(setActiveLayers(layerID)));
    }
    setVisible(!visible);
  };

  return (
    <button className='favorite-button' onClick={() => updateLayerToggle()}>
      <IconWheelchair />
    </button>
  );
}

export default AccessibilityWidget;
