/**
 * Handle Multiple Images from API
 *
 * @param {Object[]} data - List of images
 * @param {number[]} nameNums - List of NameNums
 * @return {{}} - All Images organized by NameNum
 */
export function handleMultipleImages(data: any[], nameNums: number[]) {
  const images: any = {};
  const PLANT_NAME_NUM_KEY = 'NameNum';

  nameNums.forEach((nameNum: number) => {
    images[nameNum] = {
      items: [],
      cover: undefined,
    };
  });

  data.forEach((image) => {
    const plantKeywords = image.displayAttributes.find((i: any) => i.label === 'Keywords').value.split(',');

    const nameNumKeyword = plantKeywords
      .find((i: string) => {
        return i.includes(PLANT_NAME_NUM_KEY) || i.includes(PLANT_NAME_NUM_KEY.toLowerCase());
      })
      .trim();
    const plantNameNum = nameNumKeyword.toLowerCase().replace(PLANT_NAME_NUM_KEY.toLowerCase(), '');

    const isCoverImage = plantKeywords.some((item: string) => item.toLowerCase().includes('titleimage'));
    if (isCoverImage || !images[plantNameNum].cover) {
      images[plantNameNum].cover = { id: image.id, previewUrl: image.previewUrl, thumbnailUrl: image.thumbnailUrl };
    }
    images[plantNameNum].items.push({ id: image.id, previewUrl: image.previewUrl, thumbnailUrl: image.thumbnailUrl });
  });

  return images;
}
