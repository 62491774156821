import React from 'react';
import IconClose from '../icons/IconClose';

export function ModalClose(props: { handleClose: () => void }) {
  return (
    <div id='ModalClose' onClick={props.handleClose}>
      <button id='ModalCloseButton' onClick={props.handleClose}>
        <IconClose fill='#d14444' />
      </button>
    </div>
  );
}
