import React from 'react';
import { Link } from 'react-router-dom';

import ScientificNameFormatted from './ScientificNameFormatted';
import { splitByCharacter } from '../../../../utils/plantSyntax.util';

import './PlantResultScientificName.scss';
import { getPath } from '../../../../utils/path.util';

export default function PlantResultScientificName({ NAME }: { NAME: string }) {
  const scientificNameValue: string = splitByCharacter(NAME);

  return NAME ? (
    <Link className='species' to={getPath('species', scientificNameValue)}>
      <ScientificNameFormatted plantName={scientificNameValue} />
    </Link>
  ) : (
    <span className='species'>Scientific name coming soon</span>
  );
}
