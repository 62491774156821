import _sortBy from 'lodash/sortBy';
import _pickBy from 'lodash/pickBy';
import _identity from 'lodash/identity';
import _keyBy from 'lodash/keyBy';
import _merge from 'lodash/merge';
import _pick from 'lodash/pick';
import _values from 'lodash/values';

import mapController from '../controllers/MapController';

// Store
import store from '../store/store';
import { setSearchDefinitionExpression } from '../store/app/filterSlice';
import { setSearchCurrentSelection, setSearchWidgetLocation } from '../store/app/appSlice';
import { appHistory } from '../store/appHistory';

// Config
import {
  LOCATIONS_ESRI_FIELD,
  LOCATIONS_GARDEN_CODE,
  LOCATIONS_GROUP_FIELD,
  LOCATIONS_SEARCH_LABEL,
} from '../config/plant.config';

/**
 * Generate Plants query Where Clause
 *
 * @param {String} searchValue - Search Value [eg. 'roses']
 * @param {String} searchField - Search Field [eg. 'Plants', 'Advanced', etc...]
 * @param {String} searchFieldAdvanced - Advanced Search Field [eg. 'FAMILY_AND_FAMILY_COMMON']
 *
 * Samples of search Field Advanced
 * [eg. FAMILY_AND_FAMILY_COMMON: CASUARINACEAE,SHE-OAK]
 * [eg. GENUS_AND_GENUS_COMMON: QUERCUS,OAK]
 *
 * @return {[] || String} - List of Definition Expressions OR 1=1
 */
export const generatePlantQueryWhereClause = (
  searchValue: string,
  searchField: string,
  searchFieldAdvanced: string
) => {
  const { filterDefinitionExpression } = store.getState().filterReducer;

  const expressions = [];
  const queriesDefault: string[] = [];
  const queriesBackup: string[] = [];

  if (searchValue) {
    const re = /\s+/i; // literal notation
    const values = searchValue.trim().split(re);

    values.forEach((value) => {
      const valueProcessed = value.replace(/'/g, '');
      const searchSegment = valueProcessed.toUpperCase();

      if (searchField === 'Advanced') {
        const SEARCH_FIELD_ADVANCED = searchFieldAdvanced.toUpperCase();

        const queryAdvancedDefault =
          `(UPPER(${SEARCH_FIELD_ADVANCED}) LIKE '${searchSegment},%' OR ` +
          `UPPER(${SEARCH_FIELD_ADVANCED}) LIKE '%,${searchSegment}' OR ` +
          `UPPER(${SEARCH_FIELD_ADVANCED}) LIKE '%,${searchSegment},%' OR ` +
          `UPPER(${SEARCH_FIELD_ADVANCED}) = '${searchSegment}')`;
        queriesDefault.push(queryAdvancedDefault);

        const queryAdvancedBackup = `UPPER(${SEARCH_FIELD_ADVANCED}) LIKE '%${searchSegment}%'`;
        queriesBackup.push(queryAdvancedBackup);
      } else {
        const SEARCH_FIELD = 'GENUS_AND_GENUS_COMMON';
        const BACKUP_FIELD = 'ETI';

        const queryDefault =
          `(UPPER(${SEARCH_FIELD}) LIKE '${searchSegment},%' OR ` +
          `UPPER(${SEARCH_FIELD}) LIKE '%,${searchSegment}' OR ` +
          `UPPER(${SEARCH_FIELD}) LIKE '%,${searchSegment},%' OR ` +
          `UPPER(${SEARCH_FIELD}) = '${searchSegment}')`;
        queriesDefault.push(queryDefault);

        const queryBackup = `(UPPER(${BACKUP_FIELD}) LIKE '%${searchSegment}%')`;
        queriesBackup.push(queryBackup);
      }
    });

    expressions.push(queriesDefault.join(' AND '), queriesBackup.join(' AND '));
  }

  const finalExpressions = filterDefinitionExpression.length
    ? expressions.map((query) => `${query} AND ${filterDefinitionExpression}`)
    : expressions;

  return finalExpressions.length ? finalExpressions : '1=1';
};

/**
 * Handle Search Results
 *
 * @param {String} searchTerm - Search term
 * @param {String} queryUrl - Query URL
 * @param {Object} results - Query results
 * @param {String} filterDefinitionExpression - Filter Definition Expression
 * @return {Object} Prepared result
 */
export function handleSearchResults(
  searchTerm: string,
  queryUrl: any,
  results: any,
  filterDefinitionExpression: string
) {
  return {
    filter: filterDefinitionExpression,
    query: queryUrl,
    results: results?.features.map((item: { attributes: any }) => item.attributes),
    searchTerm: searchTerm,
  };
}

/**
 * Query Locations
 *
 * @return {Object} - Query Locations Results
 */
export const queryLocations = async () => {
  const where = '1=1';
  const queryResults__PlantCenter = await mapController.queryLocationForSearch(
    `${where} AND LOCATION_GROUP_NOW <> 'NULL'`
  );
  const queryResults__GardenLocations = await mapController.queryGardenLocations(
    `${where} AND GardenCode <> 'NULL' AND Display <> 'No'`
  );

  const queryResults__PlantCenter__Attributes = queryResults__PlantCenter.features.map((item: any) => {
    return { ...item.attributes };
  });
  const queryResults__GardenLocations__Attributes = queryResults__GardenLocations.features.map((location: any) => {
    return {
      ...location.attributes,
      geometry: location.geometry.toJSON(),
    };
  });

  const mergedResults = _merge(
    _keyBy(queryResults__PlantCenter__Attributes, LOCATIONS_GROUP_FIELD),
    _keyBy(queryResults__GardenLocations__Attributes, LOCATIONS_GARDEN_CODE)
  );

  const filteredGardensKeys = Object.keys(mergedResults).filter((key) => {
    return key !== 'null' && !key.includes('@');
  });
  const mergedResultsClean = _pick(mergedResults, filteredGardensKeys);
  const gardenLocationsFinal = _values(mergedResultsClean);

  const locationsAttributes = gardenLocationsFinal.filter((attribute: any) => {
    return attribute[LOCATIONS_GROUP_FIELD] && attribute[LOCATIONS_ESRI_FIELD] && attribute[LOCATIONS_GARDEN_CODE];
  });
  const locationsAttributesSorted = _sortBy(locationsAttributes, LOCATIONS_ESRI_FIELD);

  return {
    locationsAttributes: locationsAttributesSorted,
    query: where,
    queryResults: queryResults__PlantCenter,
  };
};

/**
 * Get Update Filter Options
 *
 * @param {[]} filterSelectedOptions - List of filter Selected Options
 * @param {[]} locations - List of garden locations
 *
 * @return {[]} - Update Filter Options
 */
export function getUpdateFilterOptions(filterSelectedOptions: any, locations: string[]) {
  return filterSelectedOptions.map((filter: any) => {
    if (filter.label === LOCATIONS_SEARCH_LABEL) {
      return {
        ...filter,
        options: [
          ...filter.options,
          ...locations.map((location: string) => ({
            label: location,
            values: [location],
          })),
        ],
      };
    } else {
      return filter;
    }
  });
}

/**
 * Get Garden Location
 *
 * @return {Object} locations - List of garden locations
 */
export async function getGardenLocation() {
  const { locationsAttributes } = await queryLocations();

  const locations: string[] = locationsAttributes.map((attribute) => attribute[LOCATIONS_ESRI_FIELD]);

  return { locationsAttributes, locations };
}

/**
 * Get plants results
 * @param {String} searchValue - Search value
 * @param {String} searchField - Search field
 * @param {String} searchFieldAdvanced - Advanced search field
 * @return {Object} - Returning query and query results
 */
export const queryPlants = async (searchValue: string, searchField: string, searchFieldAdvanced: string) => {
  let results;
  let query;

  const where = generatePlantQueryWhereClause(searchValue, searchField, searchFieldAdvanced);
  const wherePrimary = where[0];
  query = wherePrimary;
  results = await mapController.queryPlantsForSearch(wherePrimary);

  if (results.features.length === 0) {
    const whereSecondary = where[1];
    query = whereSecondary;
    store.dispatch(setSearchDefinitionExpression(whereSecondary));
    results = await mapController.queryPlantsForSearch(whereSecondary);
  }
  results.features.sort((a: any, b: any) => {
    const aSortValue = a.attributes.SORT_NAME ?? a.attributes.NAME;
    const bSortValue = b.attributes.SORT_NAME ?? b.attributes.NAME;
    return aSortValue.toLowerCase() > bSortValue.toLowerCase() ? 1 : -1;
  });

  return {
    query: query,
    queryResults: results,
  };
};

/**
 * Set URL path
 *
 * @param {String} searchField - Search Field [eg. 'Plants', 'Advanced', etc...]
 * @param {String} searchFieldAdvanced - Advanced Search Field [eg. 'FAMILY_AND_FAMILY_COMMON']
 * @param {String} searchValue - Search Value [eg. 'roses']
 */
export const setURLPath = (searchField: string, searchFieldAdvanced: string, searchValue = '') => {
  const urlParams = {
    field: searchField,
    fieldAdvanced: searchField === 'Advanced' ? searchFieldAdvanced : undefined,
    value: searchValue?.length > 0 ? searchValue : undefined,
  };

  const urlParamsClean = _pickBy(urlParams, _identity);
  const urlSearchParams = new URLSearchParams(urlParamsClean as any).toString();
  const searchQuery = `?${urlSearchParams}`;
  const searchValues = `/map/search${searchQuery}`;

  store.dispatch(setSearchWidgetLocation(searchValues));
  store.dispatch(setSearchCurrentSelection({ ...urlParamsClean }));
  if (appHistory.location.search !== searchQuery) {
    appHistory.push(searchValues);
  }
};
