import { combineReducers, configureStore } from '@reduxjs/toolkit';

import appReducer from './app/appSlice';
import filterReducer from './app/filterSlice';
import layerReducer from './app/layerSlice';
import modalReducer from './app/modalSlice';
import counterReducer from './slices/counterSlice';
import tourReducer from './app/tourSlice';
import phenologyReducer from './app/phenologySlice';

const rootReducer = combineReducers({
  appReducer,
  filterReducer,
  counterReducer,
  layerReducer,
  modalReducer,
  tourReducer,
  phenologyReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default configureStore({
  reducer: rootReducer,
});
