import { getPointOfInterestPopupTemplate } from './popup/popup.util';

export const BASE_URL = process.env.REACT_APP_BASE_URL || '';

export const PLANT_ID_FIELD = 'NAME';

// COLOR
export const COLOR_PRIMARY = '#2ec16b';

// REST SERVICES
const GIS_REST_SERVICES = 'https://nybg-gispt.nybg.org/server/rest/services';

// LAYERS URL
export const PLANT_DATA_LAYER_URL = GIS_REST_SERVICES + '/PlantTracker/Plant_Center_Production_PublicView/MapServer';
export const GARDEN_LOCATIONS_LAYER_URL = GIS_REST_SERVICES + '/PlantTracker/GardenLocations/MapServer/0';
export const ART_LAYER_1_URL = GIS_REST_SERVICES + '/Hosted/ArtisticBasemapTiles/MapServer';
// export const ART_LAYER_2_URL = GIS_REST_SERVICES + '/GIS_Basemap_Layers/MapServer';
export const ART_LAYER_2_URL = GIS_REST_SERVICES + '/PlantTracker/Plant_Tracker_GIS_Basemap_Tiled/MapServer';
export const ADA_URL = GIS_REST_SERVICES + '/PlantTracker/ADA_Layers_PublicView/MapServer';
export const INTERACTIVE_FEATURE_LAYER_URL =
  GIS_REST_SERVICES + '/PlantTracker/Interactive_Map_Features_PublicView/MapServer/0';
export const LOCATION_CODES_MAP_IMAGE_LAYER_URL = GIS_REST_SERVICES + '/PlantTracker/LocationCodes/MapServer';

export const LOCATIONS_URL = PLANT_DATA_LAYER_URL + '/0';
export const PLANT_NAMES_DATA_URL = PLANT_DATA_LAYER_URL + '/1';
export const TOURS_URL = PLANT_DATA_LAYER_URL + '/2';

// MAP CONFIG
export const ZOOM_DEFAULT = 16;
export const ZOOM_MOBILE = 14;
export const MAP_ZOOM = window.innerWidth > 475 ? ZOOM_DEFAULT : ZOOM_MOBILE;

export const MAP_BASEMAP = 'gray-vector';
export const GRAY_VECTOR_PORTALID = '8a2cba3b0ebf4140b7c0dc5ee149549a';
export const MAP_CENTER = {
  latitude: 40.86143917224365,
  longitude: -73.87849534964407,
};

export const visibilityScale = 3375;
export const LABELS_CANOPIES_VISIBILITY_MIN_SCALE = 150;

export const INITIAL_DEFINITION_EXPRESSION = "ALIVE = 'A'";
export const plantLayerDefaultDefinitionExpression = INITIAL_DEFINITION_EXPRESSION;
// export const plantLayerDefaultDefinitionExpression = '';

export const PLANTS_FEATURE_LAYER_LABELING_INFO = {
  minScale: 1000,
  labelExpressionInfo: { expression: '$feature.NAME' },
  symbol: {
    type: 'text', // autocasts as new TextSymbol() - Plant Labels
    font: {
      // autocasts as new Font()
      family: 'Helvetica',
      size: 12,
    },
    color: 'black',
    verticalAlignment: 'middle',
    haloSize: 0.4,
    haloColor: '[255,255,255,0.1]',
  },
};

export const PLANTS_FEATURE_LAYER_CANOPY_RENDERER = {
  type: 'simple',
  symbol: {
    type: 'simple-marker',
    color: 'rgba(255,255,255,0.05)',
    outline: {
      color: '#98e600',
      width: 1,
    },
  },
  visualVariables: [
    {
      type: 'size',
      field: 'CURRENT_SPREAD', // tree height
      valueUnit: 'feet',
    },
  ],
};

export const PLANT_DATA_LAYER = {
  url: `${PLANT_DATA_LAYER_URL}/0`,
  definitionExpression: plantLayerDefaultDefinitionExpression,
};

export const SVG_CONFIG = {
  fill: '#5E5E5E',
};

export const ART_LAYER_1 = {
  url: ART_LAYER_1_URL,
  id: 'art',
};

export const ART_LAYER_2 = {
  url: ART_LAYER_2_URL,
  imageFormat: 'png8',
  id: 'art1',
  sublayers: [
    { id: 8 }, // Green Areas
    { id: 2 }, // Boundary
    {
      id: 5,
      labelsVisible: true,
      labelingInfo: [
        {
          labelExpression: '[Name]',
          labelPlacement: 'always-horizontal',
          symbol: {
            type: 'text', // autocasts as new TextSymbol() - Building Labels
            color: 'black',
            haloSize: 0.4,
            haloColor: '[255, 255, 255, 0.1]',
            // verticalAlignment: 'middle',
            font: {
              size: 13,
              weight: 'bold',
              family: 'Helvetica',
            },
          },
        },
      ],
    }, // Structures
    // { id: 6 },
    // { id: 7 },
    { id: 9 }, // Paths
    { id: 10 }, // Water
    { id: 3 }, // Conservatory Indoors
    { id: 11 }, // Rocks
  ],
  // sublayers: [2, 5, 6, 7, 8, 9, 10].map((sublayer) => ({ id: sublayer })),
  // minScale: visibilityScale,
};

export const ADA_MAP_IMAGE_LAYER = {
  url: ADA_URL,
  id: 'ada',
  visible: true,
  minScale: visibilityScale,
};

export const INTERACTIVE_FEATURE_LAYER = {
  url: INTERACTIVE_FEATURE_LAYER_URL,
  id: 'interactiveFeatures',
  visible: true,
  definitionExpression: "Symbol = 'Show None'",
  popupTemplate: getPointOfInterestPopupTemplate(),
};

export const LOCATION_CODES_MAP_IMAGE_LAYER = {
  url: LOCATION_CODES_MAP_IMAGE_LAYER_URL,
  id: 'locationCodes',
  visible: false,
};

export const GARDEN_LOCATIONS_DEFAULT_DEFINITION_EXPRESSION = "GardenCode = ''";
export const GARDEN_LOCATIONS_FEATURE_LAYER_RENDER = {
  type: 'simple',
  symbol: {
    type: 'simple-fill',
    color: [175, 37, 89, 0.5],
    outline: {
      color: [0, 0, 0, 0.75],
      width: 0.75,
    },
  },
};
export const GARDEN_LOCATIONS_FEATURE_LAYER = {
  id: 'Garden_Locations',
  url: GARDEN_LOCATIONS_LAYER_URL,
  definitionExpression: GARDEN_LOCATIONS_DEFAULT_DEFINITION_EXPRESSION,
  renderer: GARDEN_LOCATIONS_FEATURE_LAYER_RENDER,
};

export const isMobileDevice = matchMedia('(hover: none)').matches;

// Fonts
export const NYBG_GOTHIC_FONT = "'NY Botanical Gothic Extrabold Regular', , 'Times New Roman', Times, serif";
export const NYBG_GT_TEXT_FONT = "'GT Super Text Regular', 'GT Super Text Regular', sans-serif";
