import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import TourOptions from './TourOptions';
import TourStops from './TourStops';
import { RootState } from '../../store/store';

import '../../styles/css/tours.scss';
import { startCuratedTour } from '../../utils/tour.util';

const TourView = () => {
  const { search } = useLocation();
  const modalContent = useSelector((state: RootState) => state.modalReducer.modalContent);
  const tourStep = useSelector((state: RootState) => state.tourReducer.tourStep);
  const stopsByAccession = useSelector((state: RootState) => state.tourReducer.stopsByAccession);

  useEffect(() => {
    if (modalContent === 'TourStopsOpen') {
      const query = new URLSearchParams(search);
      const tourID = query.get('id');

      if (tourID != null) {
        startCuratedTour(parseInt(tourID));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalContent]);

  const returnCurrentTourStep = () => {
    switch (tourStep) {
      case 'ViewTours':
        return <TourOptions startCuratedTour={startCuratedTour} />;
      case 'TourStopsOpen':
        return <TourStops accessions={stopsByAccession} />;
      default:
        return <></>;
    }
  };

  return returnCurrentTourStep();
};

export default TourView;
