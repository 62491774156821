import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import Modal from './Modal';
import FavoritePlants from '../plants/FavoritePlants';
import FilterPlants from '../plants/FilterPlants';
import FilterPlantsClear from '../plants/FilterPlantsClear';
import TourView from '../tours/TourView';

import { setGAModalEvent } from '../../utils/googleAnalytics';
import { setModalContent } from '../../store/app/modalSlice';

import SearchPlantsAdvancedModal from './SearchPlantsAdvancedModal/SearchPlantsAdvancedModal';
import CollectionsLanguageModal from './CollectionsLanguageModal/CollectionsLanguageModal';
import SpecificPlantGallery from '../plants/SpecificPlantGallery/SpecificPlantGallery';
import GardenGallery from '../plants/GardenGallery/GardenGallery';

// REDUX Store
import { RootState } from '../../store/store';
import { $selectedPlant } from '../../store/app/appSlice';
import { $tourSelected, $tourStep } from '../../store/app/tourSlice';

const ModalView = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const modalContent = useSelector((state: RootState) => state.modalReducer.modalContent);
  const tourStep = useSelector($tourStep);
  const tourSelected = useSelector($tourSelected);

  const [title, setTitle] = useState<string | undefined>('');
  const [subTitle, setSubTitle] = useState('');

  useEffect(() => {
    if (modalContent) {
      // setGAModalEvent(modalContent);
    }

    switch (modalContent) {
      case 'Favorite':
        setTitle('My Favorites');
        subTitle.length && setSubTitle('');
        break;
      case 'Filter':
        setTitle('Filter by Characteristics');
        subTitle.length && setSubTitle('');
        break;
      case 'Layers':
        setTitle('');
        subTitle.length && setSubTitle('');
        break;
      case 'ViewTours':
        setTourModalTitle();
        break;
      case 'TourStopsOpen':
        setTitle('Tour Stops');
        subTitle.length && setSubTitle('');
        break;
      case 'LeafType - deciduous':
        setTitle('Deciduous');
        setSubTitle('Dropping its leaves during a dormant season, usually winter - as opposed to evergreen');
        break;
      case 'LeafType - evergreen':
        setTitle('Evergreen');
        subTitle.length && setSubTitle('Retaining its green leaves throughout the year.');
        break;
      case 'LeafType - semi-evergreen':
        setTitle('Semi-Evergreen');
        subTitle.length && setSubTitle('Retaining its green leaves throughout the year.');
        break;
      case 'LeafType - climbing':
        setTitle('Climbing');
        subTitle.length && setSubTitle('Description coming soon!');
        break;
      case 'Advanced':
        setTitle('Search Plants - Advanced');
        subTitle.length && setSubTitle('');
        break;
      case 'PlantGalleryModal':
        break;
      case 'GardenGalleryModal':
        break;
      case 'CollectionsLanguageModal':
        setTitle('');
        setSubTitle('');
        break;
      default:
        setTitle('');
        setSubTitle('');
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalContent]);

  useEffect(() => {
    if (modalContent === 'ViewTours') {
      setTourModalTitle();
    }
  }, [tourStep]);

  const setTourModalTitle = () => {
    if (tourStep === 'TourStopsOpen') {
      setTitle(tourSelected.Tour_Name);
      setSubTitle('');
    } else {
      setTitle('Select Curated Tours');
      setSubTitle('Choose from a wide range of tours curated by the NYBG staff.');
    }
  };

  const returnContent = () => {
    switch (modalContent) {
      case 'Favorite':
        return <FavoritePlants />;
      case 'Filter':
        return <FilterPlants />;
      case 'ViewTours':
        return <TourView />;
      case 'Advanced':
        return <SearchPlantsAdvancedModal />;
      case 'PlantGalleryModal':
        return <SpecificPlantGallery />;
      case 'GardenGalleryModal':
        return <GardenGallery />;
      case 'CollectionsLanguageModal':
        return <CollectionsLanguageModal />;
      default:
        break;
    }
  };

  const returnHeaderAction = () => {
    switch (modalContent) {
      case 'Filter':
        return <FilterPlantsClear />;
      default:
        break;
    }
  };

  const closeModal = () => {
    dispatch(setModalContent(''));
    // push('/map');
  };

  const showModal = modalContent !== 'TourStopsClose' && modalContent.length > 0;

  return (
    <Modal
      open={showModal}
      showModal={showModal}
      handleClose={() => closeModal()}
      headerAction={returnHeaderAction()}
      title={title}
      subTitle={subTitle}
      mainModalPaddingHide={true}
      content={returnContent()}
    />
  );
};

export default ModalView;
