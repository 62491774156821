import * as React from 'react';
import { SVG_CONFIG } from '../../config/config';

interface IconFilter {
  filterOn?: boolean;
  fillColor?: string;
}

const IconFilter = (prop: IconFilter) => {
  const { filterOn, fillColor } = prop;

  return (
    <svg width={35} height={35} viewBox='0 0 35 35'>
      <title>Filter</title>
      <g fill='none' fillRule='evenodd'>
        <path
          fill={fillColor ?? SVG_CONFIG.fill}
          d='M26.21 10.98l-7.355 9.358.001 5.881-3.268 1.918v-7.8L8.234 10.98H26.21z'
        />
        <circle cx={5} cy={5} r={5} fill={filterOn ? '#D14444' : 'transparent'} transform='translate(19 6)' />
      </g>
    </svg>
  );
};

export default IconFilter;
