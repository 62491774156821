import React from 'react';

import IconLeaf from '../icons/IconLeaf';

import '../../styles/css/error.scss';

const ResultsError = ({ value }: { value: string }) => {
  return (
    <div className='results-error-container'>
      <IconLeaf width={100} height={100} />
      <h3>
        There was an error searching for <span>{value}</span>. Try again or try another name.
      </h3>
    </div>
  );
};

export default ResultsError;
