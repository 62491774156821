import * as React from 'react';
import { SVG_CONFIG } from '../../config/config';

interface ChevronProps {
  width?: number;
  height?: number;
  fill?: string;
  rotate?: number;
}

const IconChevronUp = ({ width = 35, height = 35, fill, rotate }: ChevronProps) => (
  <svg width={width} height={height} viewBox='0 0 35 35' style={{ transform: `rotate(${rotate}deg)` }}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.455 20.329c-.703-.72-8.097-7.753-8.097-7.753a1.89 1.89 0 00-2.717 0S8.249 19.61 7.546 20.33c-.702.72-.75 2.014 0 2.782.75.767 1.797.828 2.717 0L17 16.659l6.738 6.452c.92.828 1.967.767 2.717 0 .75-.768.703-2.063 0-2.782z'
      fill={fill ? fill : SVG_CONFIG.fill}
    />
  </svg>
);

export default IconChevronUp;
