import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Redux
import { RootState } from '../../store/store';
import { setShareModalActive, updateFavoritePlants } from '../../store/app/appSlice';
import { FavoritePlantsItem } from './FavoritePlantsItem/FavoritePlantsItem';

interface PlantData {
  commonName: string;
  scientificName: string;
  img: string;
}

export default function FavoritePlants() {
  const dispatch = useDispatch();
  const shareModalActive = useSelector((state: RootState) => state.appReducer.shareModalActive);
  const [favoritePlants, setFavoritePlants] = useState<Array<PlantData> | []>([]);
  const allFavoritesString = localStorage.getItem('favoritePlants');
  const allFavorites = allFavoritesString ? JSON.parse(allFavoritesString) : [];

  useEffect(() => {
    if (allFavorites.length) {
      setFavoritePlants(allFavorites);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFavorites.length]);

  const unfavoritePlant = (scientificName: string) => {
    const favoritePlantsCopy = [...favoritePlants];
    const updatedFavoritePlants = favoritePlantsCopy.filter((plant) => plant.scientificName !== scientificName);

    localStorage.setItem('favoritePlants', JSON.stringify(updatedFavoritePlants));
    dispatch(updateFavoritePlants(updatedFavoritePlants));
    setFavoritePlants(updatedFavoritePlants);
  };

  return (
    <div className='favorite-plants-container'>
      <div className='FavoriteMain all-favorites'>
        {favoritePlants.length > 0 ? (
          (favoritePlants as Array<PlantData>).map(({ commonName, scientificName }: any, index: number) => {
            return (
              <FavoritePlantsItem
                key={`${scientificName} ${index}`}
                commonName={commonName}
                scientificName={scientificName}
                unfavoritePlant={() => unfavoritePlant(scientificName)}
                sharePlant={() => dispatch(setShareModalActive(!shareModalActive))}
              />
            );
          })
        ) : (
          <p className='no-faves'>You currently do not have favorites. Add plants to your list of favorites.</p>
        )}
      </div>
    </div>
  );
}
