import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  $searchTerms,
  $searchType,
  $searchTypeAdvanced,
  setFetchAdvancedSearch,
  setSearchTerms,
} from '../../../store/app/appSlice';
import IconSearch from '../../icons/IconSearch';
import AdvancedFiltersOptions from '../../plants/AdvancedFiltersOptions/AdvancedFiltersOptions';

import { setURLPath } from '../../../utils/query.utils';
import { getResultKey } from '../../../utils/plant.util';

import { SEARCH_PLANT_ALPHABET_SECTIONS } from '../../../config/plant.config';

import './SearchPlantsAdvancedFilters.scss';

export default function SearchPlantsAdvancedFilters() {
  const dispatch = useDispatch();
  const searchTerms = useSelector($searchTerms);
  const searchType = useSelector($searchType);
  const searchTypeAdvanced = useSelector($searchTypeAdvanced);

  const searchTermsKey = getResultKey('Advanced', searchTypeAdvanced);
  const searchTerm = searchTerms[searchTermsKey];

  function handleSearchPlantInputKeyDown(evt: React.KeyboardEvent<HTMLInputElement>) {
    if (evt.key === 'Enter') {
      handleFetchAdvancedSearch();
    }
  }

  function handleFetchAdvancedSearch() {
    dispatch(setFetchAdvancedSearch(true));
    setURLPath(searchType, searchTypeAdvanced, searchTerm);
  }

  return (
    <div className='SearchPlantsAdvancedFilters SearchPlantInput__container'>
      {!SEARCH_PLANT_ALPHABET_SECTIONS.includes(searchTypeAdvanced) ? (
        <div className='SearchPlantInput__field-container'>
          <input
            className='SearchPlantInput__field'
            type='text'
            placeholder='Search Plants'
            value={searchTerm}
            onChange={(evt) =>
              dispatch(
                setSearchTerms({
                  ...searchTerms,
                  [searchTermsKey]: evt.target.value,
                })
              )
            }
            onKeyUp={(evt) => handleSearchPlantInputKeyDown(evt)}
            onFocus={() => dispatch(setFetchAdvancedSearch(false))}
          />
          <button className='SearchPlantNav__button default-button' onClick={() => handleFetchAdvancedSearch()}>
            <IconSearch />
          </button>
        </div>
      ) : (
        <div className='SearchPlantInput__field-container alphabet__search-message'>
          <span className='SearchPlantNav__select-letter'>Select Letter from List</span>
        </div>
      )}

      <AdvancedFiltersOptions />
    </div>
  );
}
