import * as React from 'react';
import { SVG_CONFIG } from '../../config/config';

function IconShare({ width = 35, height = 35 }) {
  return (
    <svg viewBox='0 0 35 35' fill='none' width={width} height={height}>
      <path
        fill={SVG_CONFIG.fill}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.464 14.75c-.771 0-1.446.29-1.928.771l-6.847-3.953c.097-.193.097-.482.097-.675 0-1.64-1.254-2.893-2.893-2.893C10.253 8 9 9.254 9 10.893c0 1.64 1.254 2.893 2.893 2.893.771 0 1.446-.29 1.928-.772l6.847 3.954c-.097.193-.097.482-.097.675 0 .193 0 .482.097.675l-6.847 4.05c-.482-.482-1.157-.772-1.928-.772a2.798 2.798 0 00-2.797 2.797 2.798 2.798 0 002.797 2.796 2.798 2.798 0 002.796-2.796c0-.193 0-.386-.096-.675l6.846-4.05c.482.482 1.157.771 1.929.771 1.64 0 2.893-1.253 2.893-2.893 0-1.639-1.157-2.796-2.797-2.796z'
      />
    </svg>
  );
}

export default IconShare;
