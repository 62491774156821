import * as React from 'react';

export default function IconPlantClose(props: any) {
  return (
    <svg className='IconPlantClose plant-result-icon' viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fill='#333'
        d='M15 3C8.373 3 3 8.373 3 15s5.373 12 12 12 12-5.373 12-12S21.627 3 15 3zm1.414 12l3.293 3.293a.999.999 0 11-1.414 1.414L15 16.414l-3.293 3.293a.999.999 0 11-1.414-1.414L13.586 15l-3.293-3.293a.999.999 0 111.414-1.414L15 13.586l3.293-3.293a.999.999 0 111.414 1.414L16.414 15z'
      />
    </svg>
  );
}
