interface SubOption {
  url: string,
  menuTitle: string
}
interface MenuOption {
  url: string,
  menuTitle: string,
  subOptions: Array<SubOption>
}

export const menuOptions: Array<MenuOption> = [
  {
    url: 'https://www.nybg.org/join-support/',
    menuTitle: 'Join & Support',
    subOptions: [],
  },
  {
    url: 'https://www.nybg.org/about/privacy-policy/',
    menuTitle: 'Privacy Policy',
    subOptions: [],
  },
];
