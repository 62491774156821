import { withStyles } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { COLOR_PRIMARY } from '../../../config/config';

const GreenSwitch = withStyles({
  switchBase: {
    color: COLOR_PRIMARY,
    '&$checked': {
      color: COLOR_PRIMARY,
    },
    '&$checked + $track': {
      backgroundColor: COLOR_PRIMARY,
    },
  },
  checked: {},
  track: {},
})(Switch);

export default GreenSwitch;
