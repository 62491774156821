import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import IconShare from '../icons/IconShare';
import IconLocator from '../icons/IconLocator';
import IconChevronUp from '../icons/IconChevronUp';
import Loader from '../Loader';
import TourStopsClose from './TourStopsClose';
import WidgetImage from '../plants/WidgetImage/WidgetImage';

// REDUX Store
import { RootState } from '../../store/store';
import { setTourDefinitionExpressionByAccession } from '../../store/app/filterSlice';
import { setModalContent } from '../../store/app/modalSlice';
import { $tourStopIndex, setTourStep, setTourStopIndex } from '../../store/app/tourSlice';
import { setShareModalActive } from '../../store/app/appSlice';

import { TourStop } from '../../interfaces/plantData';
import TourStopNumber from './TourStopNumber/TourStopNumber';
import { getAndSetTourStops, resetTours } from '../../utils/tour.util';
import { COLOR_PRIMARY } from '../../config/config';

interface PlantStops {
  NAME: string;
  COMMON_NAME_PRIMARY: string;
}

const TourStops = ({ accessions }: any) => {
  const dispatch = useDispatch();
  const tourStops = useSelector((state: RootState) => state.tourReducer.tourStops);
  const tourStopIndex = useSelector($tourStopIndex);
  const shareModalActive = useSelector((state: RootState) => state.appReducer.shareModalActive);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!accessions.length) {
      return;
    }

    const tourStopsValidated = accessions.every((accession: string) => {
      if (!tourStops.length) {
        return false;
      }
      const plant = tourStops.find((stop: any) => stop.ACC_NUM_AND_QUAL === accession);

      return plant ? true : false;
    });

    if (tourStopsValidated) {
      setIsLoading(false);
      return;
    }

    dispatch(setTourDefinitionExpressionByAccession(accessions));
    getAndSetTourStops().then(() => {
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessions]);

  const closeTourWidget = () => {
    dispatch(setModalContent(''));
    dispatch(setTourStep('TourStopsClose'));
  };

  return (
    <>
      <div className='TourMain tour-stops-container'>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {tourStops.map(({ NAME, COMMON_NAME_PRIMARY, ACC_NUM_AND_QUAL, NAME_NUM }: TourStop, index: number) => {
              return (
                <div key={index} className='tour-stop' data-selected-plant={tourStopIndex === index}>
                  <div className='tour-text'>
                    <TourStopNumber index={index} />

                    <WidgetImage alt={NAME} NAME_NUM={NAME_NUM} tour={true} />

                    <div className='title-wrapper'>
                      <p className='title'>
                        <Link to={`/plants/accession?id=${ACC_NUM_AND_QUAL}`} onClick={() => closeTourWidget()}>
                          {NAME}
                        </Link>
                      </p>
                      <p className='subtitle'>{COMMON_NAME_PRIMARY}</p>
                      <Link
                        className='plant__read-more'
                        to={`/plants/accession?id=${ACC_NUM_AND_QUAL}`}
                        onClick={() => closeTourWidget()}
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                  <div className='tour-widgets'>
                    <button className='see-on-map' onClick={() => dispatch(setTourStopIndex(index))}>
                      <p className='green hide-on-mobile'>see on map</p>
                      <IconLocator />
                    </button>
                    <button className='share-tour' onClick={() => dispatch(setShareModalActive(!shareModalActive))}>
                      <IconShare />
                    </button>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>

      <div className='TourFooter tour-buttons-container tour-stop__go-back--container'>
        <div className={`tour-stop__go-back ${isLoading ? 'loading-border' : ''}`}>
          <button
            className='back-button'
            onClick={() => {
              dispatch(setModalContent('ViewTours'));
              resetTours();
            }}
          >
            <IconChevronUp rotate={270} fill={COLOR_PRIMARY} width={17.5} height={17.5} />
            <span>Back to Curated Tours</span>
          </button>
        </div>

        <TourStopsClose />
      </div>
    </>
  );
};

export default TourStops;
