import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../Loader';
import PlantSearchResults from '../searchResults/PlantSearchResults';

import mapController from '../../../controllers/MapController';

// REDUX Store
import {
  $cardMinimized,
  $fetchAdvancedSearch,
  $mapLoaded,
  $searchTerms,
  $searchType,
  $searchTypeAdvanced,
  setFetchAdvancedSearch,
  setMinimized,
} from '../../../store/app/appSlice';

// CONTEXT Store
import PlantsResultsContext from '../../../store/PlantsResultsContextProvider';

// Utils
import { getResultKey } from '../../../utils/plant.util';
import { queryPlants } from '../../../utils/query.utils';

// Config
import { SEARCH_PLANT_ALPHABET_SECTIONS } from '../../../config/plant.config';

export default function SearchPlantsAdvancedResults() {
  const { plantsResults, setPlantsResults } = useContext(PlantsResultsContext);
  const dispatch = useDispatch();
  const cardMinimized = useSelector($cardMinimized);
  const searchType = useSelector($searchType);
  const searchTypeAdvanced = useSelector($searchTypeAdvanced);
  const searchTerms = useSelector($searchTerms);
  const mapLoaded = useSelector($mapLoaded);
  const fetchAdvancedSearch = useSelector($fetchAdvancedSearch);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const searchTermsKey = getResultKey(searchType, searchTypeAdvanced);
  const searchTerm = searchTerms[searchTermsKey];

  // useEffect(() => {
  //   return () => {
  //     const { searchTerms } = store.getState().appReducer;
  //     const regularAdvancedSearchReset: any = {};
  //     SEARCH_PLANT_ALPHABET_EXCLUDE_SECTIONS.forEach((item) => {
  //       const key = getResultKey('Advanced', item);
  //       regularAdvancedSearchReset[key] = '';
  //     });
  //
  //     dispatch(
  //       setSearchTerms({
  //         ...searchTerms,
  //         ...regularAdvancedSearchReset,
  //       })
  //     );
  //   };
  // }, []);

  /**
   * Query plants for Advanced Search when advanced search type IS ALPHABET
   */
  useEffect(() => {
    if (
      SEARCH_PLANT_ALPHABET_SECTIONS.includes(searchTypeAdvanced) &&
      searchTerm &&
      searchTypeAdvanced &&
      plantsResults[searchTypeAdvanced]?.searchTerm !== searchTerm
    ) {
      setIsLoading(true);
      if (cardMinimized) dispatch(setMinimized(false));

      mapController.queryScientificCommonByLetter(searchTerm, searchTypeAdvanced).then((queryResults: any) => {
        const resultsAttributes = queryResults.features.map((item: { attributes: any }) => item.attributes);

        setPlantsResults({
          ...plantsResults,
          [searchTypeAdvanced]: {
            results: resultsAttributes,
            searchTerm: searchTerm,
          },
        });
        setIsLoading(false);
      });
    }
  }, [searchTypeAdvanced, searchTerm]);

  /**
   * Query plants for Advanced Search when advanced search type IS NOT ALPHABET
   */
  useEffect(() => {
    if (
      !SEARCH_PLANT_ALPHABET_SECTIONS.includes(searchTypeAdvanced) &&
      mapLoaded &&
      searchTerm.length > 2 &&
      fetchAdvancedSearch &&
      plantsResults[searchTypeAdvanced]?.searchTerm !== searchTerm
    ) {
      setIsLoading(true);
      if (cardMinimized) dispatch(setMinimized(false));

      queryPlants(searchTerm, searchType, searchTypeAdvanced).then(({ queryResults }) => {
        const resultsAttributes = queryResults.features.map((item: { attributes: any }) => item.attributes);

        setPlantsResults({
          ...plantsResults,
          [searchTypeAdvanced]: {
            results: resultsAttributes,
            searchTerm: searchTerm,
          },
        });
        setIsLoading(false);
        dispatch(setFetchAdvancedSearch(false));
      });
    }
  }, [mapLoaded, fetchAdvancedSearch]);

  return (
    <div className='SearchPlantsAdvancedResults'>
      {isLoading && <Loader />}

      {!isLoading && (
        <PlantSearchResults
          isAdvancedSearchField={true}
          results={plantsResults[searchTypeAdvanced]?.results || []}
          searchField={searchType}
          searchFieldAdvanced={searchTypeAdvanced}
          searchTerm={searchTerm}
        />
      )}
    </div>
  );
}
