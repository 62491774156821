import { AUTOSIZER__COLUMN_WIDTH } from '../config/plant.config';

/**
 * Get Number of Result Columns
 *
 * @param {number} width - REACT-VIRTUALIZED AutoSizer - Component width
 * @return {number} - Number of columns
 */
export const getNumberOfResultColumns = (width: number) => {
  const number = Math.floor(width / AUTOSIZER__COLUMN_WIDTH) || 1;
  return number > 3 ? 3 : number;
};
