import React from 'react';
import { Link } from 'react-router-dom';

import { splitByCharacter } from '../../../../utils/plantSyntax.util';

// Interface
import { PlantAllNames } from '../../../../interfaces/plantData';

import './PlantResultCommonNamePreferred.scss';
import { getPath } from '../../../../utils/path.util';

export default function PlantResultCommonNamePreferred({ plant }: { plant: PlantAllNames }) {
  const { COMMON_NAME_PRIMARY } = plant;

  const commonNamePreferredValue = splitByCharacter(COMMON_NAME_PRIMARY);

  return (
    <Link className='common_name_preferred' to={getPath('commonname', COMMON_NAME_PRIMARY)}>
      {commonNamePreferredValue}
    </Link>
  );
}
