import _includes from 'lodash/includes';
import { getDayOfYear, getWeek } from 'date-fns';

import { FloweringRecord } from '../../../store/app/phenologySlice';

export interface PlantsChartDatasets {
  dateOriginal: string;
  date: Date;
  year: number;
  flowering: boolean;
  fruiting: boolean;
  weekInYear: number;
}

export interface PhenologyChartDataInterface {
  flowering: string[];
  fruiting: string[];
}

export type PhenologyChartDataKeys = 'flowering' | 'fruiting';

export const getPlantsChartDatasets: any = (data: any, reproductiveStatusList: []) => {
  return data?.map((date: Date, index: number) => {
    return {
      date: date,
      year: date.getFullYear(),
      weekInYear: getWeek(date),
      flowering: reproductiveStatusList ? _includes(reproductiveStatusList[index], 'F') : undefined,
      fruiting: reproductiveStatusList ? _includes(reproductiveStatusList[index], 'R') : undefined,
      reproductiveStatus: reproductiveStatusList[index],
    };
  });
};

export const getUniqueYears = (datasets: PhenologyChartDataInterface) => {
  const years = new Set<number>();
  datasets.flowering.forEach((dateString) => {
    years.add(new Date(dateString).getFullYear());
  });
  datasets.fruiting.forEach((dateString) => {
    years.add(new Date(dateString).getFullYear());
  });

  return [...years].sort().reverse();
};

/**
 * Filter plants for year based on specified selection
 * @param {Array} plantsChartData - List of plant checked dates
 * @param {Number} year - Year integer
 * @param {String} section - Specified selection ['flowering','fruiting']
 */
export const filterByYear = (datasets: PhenologyChartDataInterface, year: number, section: PhenologyChartDataKeys) => {
  const dates = datasets[section];

  return dates.filter((dateString: string) => {
    const date = new Date(dateString);
    return year === date.getFullYear();
  });
};

/**
 * Filter plants for week based on specified selection
 * @param {Array} selectedPlantsByYear - List of selected Plants for Year
 * @param {Number} weekInt - Week in year
 */
export const getPlantsInWeek = (dateStrings: string[], weekInt: number) => {
  return dateStrings.filter((date) => {
    return getWeek(new Date(date)) === weekInt;
  });
};

/**
 * @description Get plant Check Dates
 *
 * @param {string} CHECK_DT - Plant Check Date
 * @return {[]} Plant Check Date
 */
const getCheckDates = (CHECK_DT: string) => {
  return CHECK_DT ? CHECK_DT.split('@') : [];
};

/**
 * @description Get plant Reproductive Statuses
 *
 * @param {string} REPRODUCTIVE_STATUS - Reproductive Status
 * @return {[]} Reproductive Statuses list
 */
const getReproductiveStatuses = (REPRODUCTIVE_STATUS: string) => {
  return REPRODUCTIVE_STATUS ? REPRODUCTIVE_STATUS.split('@') : [];
};

/**
 * @description Get Phenology Chart Data
 *
 * @param {[]} features - Query features
 * @return {Object} Phenology Chart Data
 */
export const getPhenologyChartData = (features: []) => {
  const flowering = new Set();
  const fruiting = new Set();
  features.forEach((feat: any) => {
    const { REPRODUCTIVE_STATUS, CHECK_DT } = feat.attributes;

    const checkDates = getCheckDates(CHECK_DT);
    const reproductiveStatuses = getReproductiveStatuses(REPRODUCTIVE_STATUS);

    checkDates.forEach((date: any, index: any) => {
      const reproductiveStatus = reproductiveStatuses[index];
      if (reproductiveStatus && _includes(reproductiveStatus, 'F')) {
        flowering.add(date);
      }

      if (reproductiveStatus && _includes(reproductiveStatus, 'R')) {
        fruiting.add(date);
      }
    });
  });

  return {
    flowering: [...flowering],
    fruiting: [...fruiting],
  };
};

/**
 * @description Get Phenology Data
 *
 * @param {[]} features - Query features
 * @return {FloweringRecord[]} - Phenology flowering Data
 */
export const getPhenologyFloweringData = (features: []) => {
  const flowering: FloweringRecord[] = [];
  features.forEach((feat: any) => {
    const { NAME, COMMON_NAME_PRIMARY, REPRODUCTIVE_STATUS, CHECK_DT, ALIVE } = feat.attributes;

    const checkDates = getCheckDates(CHECK_DT);
    const reproductiveStatuses = getReproductiveStatuses(REPRODUCTIVE_STATUS);

    checkDates.forEach((date: any, index: number) => {
      const reproductiveStatus = reproductiveStatuses[index];
      if (reproductiveStatus && _includes(reproductiveStatus, 'F')) {
        const dayOfYear = getDayOfYear(new Date(date));
        flowering.push({
          dateString: date,
          name: NAME,
          commonName: COMMON_NAME_PRIMARY,
          dayOfYear: dayOfYear,
          NAME,
          COMMON_NAME_PRIMARY,
          ALIVE,
        });
      }
    });
  });

  return flowering;
};
