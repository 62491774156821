import React, { createContext, useState } from 'react';

type PlantsResultsContextType = {
  plantsResults?: any;
  setPlantsResults: (plantsResults: any) => void;
};

const PlantsResultsContext = createContext<PlantsResultsContextType>({
  plantsResults: {},
  setPlantsResults: (plantsResults: any) => {},
});

export const PlantsResultsContextProvider = ({ children }: { children: any }) => {
  const [plantsResults, setPlantResults] = useState({});

  return (
    <PlantsResultsContext.Provider value={{ plantsResults: plantsResults, setPlantsResults: setPlantResults }}>
      {children}
    </PlantsResultsContext.Provider>
  );
};

export default PlantsResultsContext;
