import * as React from 'react';
import { SVG_CONFIG } from '../../config/config';

function IconBurgerMenuFilter({ fill }: { fill?: string }) {
  return (
    <svg width={35} height={35} viewBox='0 0 35 35'>
      <path
        fill={fill ? fill : SVG_CONFIG.fill}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 24.4h6V22H8v2.4zM8 10v2.4h18V10H8zm0 8.4h12V16H8v2.4z'
      />
    </svg>
  );
}

export default IconBurgerMenuFilter;
