import React from 'react';
import _every from 'lodash/every';
import { useDispatch, useSelector } from 'react-redux';

import { setModalContent } from '../../../store/app/modalSlice';
import { selectMapFilterSelectedOptions } from '../../../store/app/filterSlice';

import IconFilter from '../../icons/IconFilter';

export function Filter() {
  const dispatch = useDispatch();
  const mapFilterSelectedOptions = useSelector(selectMapFilterSelectedOptions);
  const allDefaultFilterValuesSelected = _every(mapFilterSelectedOptions, ['activeRadioButton', 'All']);

  return (
    <>
      <button className='filter-button' onClick={() => dispatch(setModalContent('Filter'))}>
        <IconFilter filterOn={!allDefaultFilterValuesSelected} />
      </button>
    </>
  );
}

export default Filter;
