import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TourStop } from '../../interfaces/plantData';

// REDUX Store
import { RootState } from '../../store/store';
import { setModalContent } from '../../store/app/modalSlice';
import { setTourStopIndex } from '../../store/app/tourSlice';

import mapController from '../../controllers/MapController';

const TourStopsClose = () => {
  const dispatch = useDispatch();
  const tourStep = useSelector((state: RootState) => state.tourReducer.tourStep);
  const tourStops: Array<TourStop> = useSelector((state: RootState) => state.tourReducer.tourStops);
  const tourStopIndex = useSelector((state: RootState) => state.tourReducer.tourStopIndex);

  const ACC_NUM_AND_QUAL = tourStops.length ? tourStops[tourStopIndex].ACC_NUM_AND_QUAL : null;

  useEffect(() => {
    if (tourStops.length && ACC_NUM_AND_QUAL) {
      mapController.setTourStopsActiveStop(ACC_NUM_AND_QUAL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourStopIndex]);

  return tourStep === 'TourStopsOpen' ? (
    <button
      className='modal-clear__button'
      onClick={() => {
        mapController.goToAllTourStops();
        dispatch(setTourStopIndex(0));
        dispatch(setModalContent(''));
      }}
    >
      <div className='IconReset'>Start Tour</div>
    </button>
  ) : null;
};

export default TourStopsClose;
