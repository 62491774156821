import * as React from 'react';

export default function IconTrashBin({ width = 32, height = 32 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 32 32'
      className='prefix__svg-icon'
    >
      <path
        fill={'#fff'}
        d='M20 2V0H10v2H2v4h26V2zM6 32h18l2-24H4l2 24zm14-21c0-.55.45-1 1-1s1 .45 1 1v18c0 .55-.45 1-1 1s-1-.45-1-1V11zm-6 0c0-.55.45-1 1-1s1 .45 1 1v18c0 .55-.45 1-1 1s-1-.45-1-1V11zm-6 0c0-.55.45-1 1-1s1 .45 1 1v18c0 .55-.45 1-1 1s-1-.45-1-1V11z'
      />
    </svg>
  );
}
