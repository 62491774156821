import React from 'react';

const IconFacebook = () => {
  return (
    <svg width='33px' height='33px' viewBox='0 0 33 33' version='1.1'>
      <title>Facebook</title>
      <g id='Homepage-Mobile_v2' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Mobile-Social_Share' transform='translate(-73.000000, -500.000000)'>
          <g id='Group-89' transform='translate(33.000000, 469.000000)'>
            <g id='Group-47' transform='translate(40.000000, 31.000000)'>
              <g id='FB'>
                <path
                  d='M16.3038193,-2.13162821e-13 C7.29935579,-2.13162821e-13 3.55271368e-14,7.28996881 3.55271368e-14,16.2828526 C3.55271368e-14,25.2760756 7.29935579,32.5657052 16.3038193,32.5657052 C25.3082829,32.5657052 32.6076387,25.2760756 32.6076387,16.2828526 C32.6076387,7.28996881 25.3082829,-2.13162821e-13 16.3038193,-2.13162821e-13 L16.3038193,-2.13162821e-13 Z'
                  id='C-Facebook'
                  fill='#3B5998'
                ></path>
                <path
                  d='M20.1654469,11.2521296 L17.7140997,11.2521296 C17.4240276,11.2521296 17.1010082,11.6324021 17.1010082,12.1419197 L17.1010082,13.9096268 L20.1651072,13.9096268 L20.1651072,16.4293983 L17.1010082,16.4293983 L17.1010082,23.9961756 L14.2074199,23.9961756 L14.2074199,16.4293983 L11.5842033,16.4293983 L11.5842033,13.9096268 L14.2074199,13.9096268 L14.2074199,12.4265304 C14.2074199,10.299922 15.6856329,8.57020809 17.7140997,8.57020809 L20.1654469,8.57020809 L20.1654469,11.2521296 L20.1654469,11.2521296 Z'
                  id='Path'
                  fill='#FFFFFF'
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconFacebook;
