import * as React from 'react';
import { SVG_CONFIG } from '../../config/config';

function IconFlower({ fill }: { fill?: string }) {
  return (
    <svg width={35} height={35} viewBox='0 0 35 35'>
      <path
        fill={fill ? fill : SVG_CONFIG.fill}
        d='M18.571 9l.227.006s2.42.588 2.106 4.324c0 0-.035.725-.354 1.644a6.278 6.278 0 011.743-.654s3.5-.866 4.337 1.644c0 0 .362 2.462-3.219 3.574 0 0-.903.316-2.107.296a6.29 6.29 0 011.5 1.76s1.893 3.068-.241 4.632c0 0-2.234 1.097-4.386-1.974 0 0-.584-.773-.932-1.938a6.272 6.272 0 01-1.03 1.695s-2.249 2.82-4.441 1.34c0 0-1.788-1.733.375-4.796 0 0 .586-.896 1.662-1.642a6.292 6.292 0 01-2.441-.775s-3.197-1.666-2.165-4.102c0 0 1.358-2.086 4.78-.55 0 0 .808.33 1.657 1.067-.11-.651-.13-1.37.006-2.142 0 0 .51-3.569 3.15-3.403L18.572 9zm-.76 6.928a2 2 0 100 4 2 2 0 000-4z'
      />
    </svg>
  );
}

export default IconFlower;
