import React from 'react';
import { Link } from 'react-router-dom';

import PlantResultAdvancedField from '../PlantResultAdvancedField/PlantResultAdvancedField';

import { getPath } from '../../../../utils/path.util';
import { splitByCharacter } from '../../../../utils/plantSyntax.util';

import { FIELD_FAMILY_AND_FAMILY_COMMON, FIELD_GENUS_AND_GENUS_COMMON } from '../../../../config/plant.config';

import './PlantResultCommonName.scss';

export default function PlantResultCommonName({
  plant,
  searchField,
  searchFieldAdvanced,
}: {
  searchField: any;
  searchFieldAdvanced: any;
  plant: any;
}) {
  const { COMMON_NAME_PRIMARY, COMMON_NAMES } = plant;

  return (
    <>
      {searchField === 'Advanced' &&
      [FIELD_FAMILY_AND_FAMILY_COMMON, FIELD_GENUS_AND_GENUS_COMMON].includes(searchFieldAdvanced) ? (
        <PlantResultAdvancedField searchFieldAdvanced={searchFieldAdvanced} plant={plant} />
      ) : (
        <span className='commonname'>
          {COMMON_NAME_PRIMARY ? (
            <Link to={getPath('commonname', COMMON_NAME_PRIMARY)}>{splitByCharacter(COMMON_NAME_PRIMARY)}</Link>
          ) : COMMON_NAMES ? (
            <span className='common-names__label'>{splitByCharacter(COMMON_NAMES)}</span>
          ) : null}
        </span>
      )}
    </>
  );
}
