import { RefObject } from 'react';
import { loadModules, setDefaultOptions } from 'esri-loader';
import _values from 'lodash/values';
import _debounce from 'lodash/debounce';
import store from '../store/store';
import {
  setDefinitionExpressionQueryAttributes,
  setGardenCodeSelected,
  setMapLoaded,
  setSpecies,
} from '../store/app/appSlice';
import { GardenLocation } from '../store/app/modalSlice';
import { setSearchDefinitionExpression } from '../store/app/filterSlice';
import { setAdaSublayersTitles, setCurrentMapViewScale } from '../store/app/layerSlice';

import { PlantData, TourStop } from '../interfaces/plantData';

import {
  ADA_MAP_IMAGE_LAYER,
  ART_LAYER_1,
  ART_LAYER_2,
  GARDEN_LOCATIONS_FEATURE_LAYER,
  GARDEN_LOCATIONS_LAYER_URL,
  GRAY_VECTOR_PORTALID,
  INITIAL_DEFINITION_EXPRESSION,
  INTERACTIVE_FEATURE_LAYER,
  LABELS_CANOPIES_VISIBILITY_MIN_SCALE,
  LOCATION_CODES_MAP_IMAGE_LAYER,
  LOCATIONS_URL,
  MAP_CENTER,
  MAP_ZOOM,
  PLANT_DATA_LAYER,
  PLANT_DATA_LAYER_URL,
  PLANT_ID_FIELD,
  plantLayerDefaultDefinitionExpression,
  PLANTS_FEATURE_LAYER_CANOPY_RENDERER,
  PLANTS_FEATURE_LAYER_LABELING_INFO,
  TOURS_URL,
  visibilityScale,
  ZOOM_MOBILE,
} from '../config/config';
import { highlightSelectedPlant } from '../utils/map/highlight.util';
import { splitByCharacter, splitByComma } from '../utils/plantSyntax.util';

import { mapWidgetAccessibility, mapWidgetLegend, mapWidgetTour } from '../utils/map/widgets';

import { getPhenologyChartData } from '../components/plants/SpecificPlant/PhenologyChart.util';

import TourStopSelected from '../images/TourStopSelected.svg';
import iconPinPointExact from '../images/map/IconLocationFilled.svg';
import iconPinPointExactSelected from '../images/map/IconLocationFilledSelected.svg';
import iconPinPointApproximate from '../images/map/IconLocationOutline.svg';
import iconPinPointApproximateSelected from '../images/map/IconLocationOutlineSelected.svg';
import { getPopupTemplate } from '../config/popup/popup.util';
import filterOptionsConfig from '../config/filterPlants';
import {
  LOCATIONS_ESRI_FIELD,
  LOCATIONS_GARDEN_CODE,
  LOCATIONS_GARDEN_LOCATION,
  LOCATIONS_GARDEN_WEBSITE,
  LOCATIONS_GROUP_FIELD,
  LOCATIONS_SEARCH_LABEL,
  SEARCH_PLANT_ALPHABET_EXCLUDE_SECTIONS,
  SEARCH_PLANT_ALPHABET_SECTIONS,
} from '../config/plant.config';
import _orderBy from 'lodash/orderBy';
// import { SpatialReference } from 'esri/geometry';

setDefaultOptions({
  version: '4.20',
  css: true,
});

interface PlantQueryParams {
  field: string;
  fieldValue?: string;
  geometry?: any;
  selectedLetter: string;
}

let selectedGardenCenter = '';

/**
 * Get Plants by Accession where Query
 *
 * @param {Array<string>} accesionIDs - Array of accession IDs
 * @return {string} - Where Query
 */
function getPlantsByAccessionWhereQuery(accesionIDs: Array<string>) {
  return `ACC_NUM_AND_QUAL IN ('${accesionIDs.join(`','`)}')`;
}

export class MapController {
  _map?: __esri.Map;
  _mapview?: __esri.MapView;
  _plantsFeatureLayer?: __esri.FeatureLayer;
  _plantsFeatureLayerView?: __esri.FeatureLayerView;
  _plantsFeatureLayerLabels?: __esri.FeatureLayer;
  _plantsFeatureLayerCanopy?: __esri.FeatureLayer;
  _interactiveFeatureLayer?: __esri.FeatureLayer;
  _artLayer1?: __esri.MapImageLayer;
  _artLayer2?: __esri.MapImageLayer;
  _popupTriggerAction?: IHandle;
  _mapViewUI?: void;
  _adaMapImageLayer?: __esri.MapImageLayer;
  _filterDefinitionExpression: string;
  _tourGraphicsLayer?: __esri.GraphicsLayer;
  _locationCodesMapImageLayer?: __esri.MapImageLayer;
  _mapPointsGraphicsLayer?: __esri.GraphicsLayer;
  _gardenLocationsFeatureLayer?: __esri.FeatureLayer;

  constructor() {
    this._map = undefined;
    this._mapview = undefined;
    this._plantsFeatureLayer = undefined;
    this._plantsFeatureLayerView = undefined;
    this._popupTriggerAction = undefined;
    this._mapViewUI = undefined;
    this._interactiveFeatureLayer = undefined;
    this._artLayer1 = undefined;
    this._artLayer2 = undefined;
    this._adaMapImageLayer = undefined;
    this._filterDefinitionExpression = '';
    this._tourGraphicsLayer = undefined;
    this._locationCodesMapImageLayer = undefined;
    this._mapPointsGraphicsLayer = undefined;
    this._gardenLocationsFeatureLayer = undefined;
  }

  initializeMap = async (domRef: RefObject<any>) => {
    const [
      Map,
      MapView,
      MapImageLayer,
      Home,
      Locate,
      TileLayer,
      Basemap,
      VectorTileLayer,
      FeatureLayer,
      FeatureFilter,
      GraphicsLayer,
    ] = await loadModules([
      'esri/WebMap',
      'esri/views/MapView',
      'esri/layers/MapImageLayer',
      'esri/widgets/Home',
      'esri/widgets/Locate',
      'esri/layers/TileLayer',
      'esri/Basemap',
      'esri/layers/VectorTileLayer',
      'esri/layers/FeatureLayer',
      'esri/views/layers/support/FeatureFilter',
      'esri/layers/GraphicsLayer',
    ]);

    const grayVector = new VectorTileLayer({
      id: 'greyVector',
      portalItem: {
        id: GRAY_VECTOR_PORTALID,
      },
    });
    this._artLayer1 = new TileLayer(ART_LAYER_1);
    this._artLayer2 = new MapImageLayer(ART_LAYER_2);
    this._interactiveFeatureLayer = new FeatureLayer(INTERACTIVE_FEATURE_LAYER);

    this._adaMapImageLayer = new MapImageLayer(ADA_MAP_IMAGE_LAYER);
    this._adaMapImageLayer?.when((adaLayerView: any) => {
      const adaSublayersTitles: string[] = [];

      adaLayerView.allSublayers.forEach((subLayer: { id: number; title: string; visible: boolean }) => {
        subLayer.visible = false;
        adaSublayersTitles[subLayer.id] = subLayer.title;
      });

      store.dispatch(setAdaSublayersTitles(adaSublayersTitles));
    });

    this._locationCodesMapImageLayer = new MapImageLayer(LOCATION_CODES_MAP_IMAGE_LAYER);

    const basemap = new Basemap({
      baseLayers: [grayVector, this._artLayer1, this._artLayer2],
    });

    this._plantsFeatureLayer = new FeatureLayer({
      id: 'plantsFeatureLayer',
      ...PLANT_DATA_LAYER,
      popupTemplate: getPopupTemplate(),
      minScale: visibilityScale,
    });

    this._plantsFeatureLayerLabels = new FeatureLayer({
      id: 'plantsFeatureLayerLabels',
      ...PLANT_DATA_LAYER,
      minScale: LABELS_CANOPIES_VISIBILITY_MIN_SCALE,
    });

    this._plantsFeatureLayerLabels?.load().then((loadedLayer) => {
      loadedLayer.labelingInfo = PLANTS_FEATURE_LAYER_LABELING_INFO;
    });

    this._plantsFeatureLayerCanopy = new FeatureLayer({
      id: 'plantsFeatureLayerCanopy',
      ...PLANT_DATA_LAYER,
      minScale: LABELS_CANOPIES_VISIBILITY_MIN_SCALE,
      definitionExpression: `${plantLayerDefaultDefinitionExpression} and MAPPED = 'Y' `,
    });

    this._plantsFeatureLayerCanopy?.load().then((loadedLayer) => {
      loadedLayer.renderer = PLANTS_FEATURE_LAYER_CANOPY_RENDERER;
    });

    this._mapPointsGraphicsLayer = new GraphicsLayer({
      id: 'mapPointsGraphicsLayer',
    });

    this._gardenLocationsFeatureLayer = new FeatureLayer({
      ...GARDEN_LOCATIONS_FEATURE_LAYER,
    });

    this._map = new Map({
      basemap: basemap,
      layers: [
        this._plantsFeatureLayer,
        this._plantsFeatureLayerLabels,
        this._plantsFeatureLayerCanopy,
        this._adaMapImageLayer,
        this._interactiveFeatureLayer,
        this._locationCodesMapImageLayer,
        this._mapPointsGraphicsLayer,
        this._gardenLocationsFeatureLayer,
      ],
    });

    this._mapview = new MapView({
      map: this._map,
      container: domRef.current,
      zoom: MAP_ZOOM,
      center: [MAP_CENTER.longitude, MAP_CENTER.latitude],
      rotation: 270,
      constraints: {
        minZoom: window.innerWidth > 960 ? ZOOM_MOBILE + 1 : ZOOM_MOBILE,
        maxZoom: 21,
        geometry: {
          // Constrain lateral movement to Lower Manhattan
          type: 'extent',
          xmax: -73.867,
          ymax: 40.877,
          xmin: -73.89,
          ymin: 40.846,
        },
      },
    });

    this._mapview?.when(() => {
      if (this._artLayer2) {
        this._artLayer2.visible = (this._mapview?.scale || 0) < visibilityScale;
      }
      if (this._artLayer1) {
        this._artLayer1.visible = (this._mapview?.scale || 0) >= visibilityScale;
      }

      store.dispatch(setCurrentMapViewScale(this._mapview?.scale!));
      if (this._mapview?.widthBreakpoint === 'xsmall' || this._mapview?.heightBreakpoint === 'xsmall') {
        this._mapview.zoom = ZOOM_MOBILE;
      }
    });

    if (this._mapview && this._plantsFeatureLayer) {
      this._mapview?.whenLayerView(this._plantsFeatureLayer).then((layerView: any) => {
        this._plantsFeatureLayerView = layerView;
        if (mapController._filterDefinitionExpression !== '') {
          const filter = new FeatureFilter({
            where: mapController._filterDefinitionExpression,
          });
          layerView.filter = filter;
        }
      });
    }

    let fadeInTimeout: ReturnType<typeof setTimeout>;
    let fadeOutTimeout: ReturnType<typeof setTimeout>;
    let oldScale = this._mapview?.scale || 0;

    this._mapview?.watch('stationary', (stationary) => {
      if (stationary) {
        store.dispatch(setCurrentMapViewScale(this._mapview?.scale!));
      }
    });

    this._mapview?.watch('zoom', () => {
      const newScale = this._mapview?.scale || 0;
      const updateVisibility = Math.sign(visibilityScale - newScale) === Math.sign(visibilityScale - oldScale);
      oldScale = newScale;
      if (updateVisibility) {
        clearTimeout(fadeInTimeout);
        clearTimeout(fadeOutTimeout);
        const layerToShow = newScale >= visibilityScale ? this._artLayer1 : this._artLayer2;
        const layerToHide = newScale >= visibilityScale ? this._artLayer2 : this._artLayer1;

        const baseOpacity = 1;
        const fadeIn = (layer: any) => {
          if (!layer.visible) {
            layer.visible = true;
          }
          fadeInTimeout = setTimeout(() => {
            if (layer.opacity >= baseOpacity) {
              return;
            }
            layer.opacity = layer.opacity + 0.05;
            fadeIn(layer);
          }, 5);
        };

        const fadeOut = (layer: any) => {
          if (layer.opacity <= 0) {
            layer.visible = false;
            return;
          }
          fadeOutTimeout = setTimeout(() => {
            layer.opacity = layer.opacity - 0.05;
            fadeOut(layer);
          }, 5);
        };

        fadeOut(layerToHide);
        fadeIn(layerToShow);
      }
    });

    // Event handler that fires each time an action is clicked.
    this._popupTriggerAction = this._mapview?.popup.on('trigger-action', (event) => {
      console.log('popup trigger-action', event);
      if (event.action.id === 'get-details') {
        this.getDetails();
      }

      if (event.action.id === 'popup-close-list') {
        const popup: any = this._mapview?.popup as __esri.Popup;
        popup.close();
      }
    });

    /**
     * Expand Popup menu list if more then one feature selected
     */
    this._mapview?.popup.watch('features', (event) => {
      const popup: any = this._mapview?.popup as __esri.Popup;

      if (popup.featureCount > 1) {
        const popupGardenCenter = popup.features[0].attributes.CURRENT_LOCATION_FULL;

        if (popupGardenCenter !== selectedGardenCenter) {
          selectedGardenCenter = popupGardenCenter;
          popup.features = _orderBy(popup.features, 'attributes.NAME');
          setTimeout(() => {
            popup.featureMenuOpen = true;
            const popupHeaderSelector = popup.domNode.querySelector('.esri-popup__feature-menu-header');
            popupHeaderSelector.innerText = `${popupHeaderSelector.innerText} - ${popupGardenCenter}`;
          }, 300);
        }
      } else {
        selectedGardenCenter = '';
      }
    });

    this._mapview?.popup.watch('selectedFeature', (event) => {
      if (!this._mapview) {
        return;
      }

      (this._mapview.popup as __esri.Popup).collapsed = false;

      if (!this._mapview.popup.selectedFeature) {
        highlightSelectedPlant(this._mapview, event);
        return;
      }

      const attributes = { ...this._mapview.popup.selectedFeature.attributes };
      Object.keys(attributes).forEach((key) => {
        if (typeof attributes[key] === 'string') {
          attributes[key] = splitByCharacter(attributes[key]);
        }
      });
      this._mapview.popup.selectedFeature.attributes = attributes;

      highlightSelectedPlant(this._mapview, event);
    });

    //@ts-ignore
    window.mapview = this._mapview;

    (this._mapview?.popup as __esri.Popup).collapseEnabled = true;
    (this._mapview?.popup as __esri.Popup).collapsed = false;
    const homeWidget = new Home({
      view: this._mapview,
    });

    const locateWidget = new Locate({
      view: this._mapview,
    });

    mapWidgetLegend(this._mapview, 'top-right');
    // mapWidgetFavorite(this._mapview, 'top-left');
    mapWidgetAccessibility(this._mapview, 'bottom-right');
    this._mapViewUI = this._mapview?.ui.add([homeWidget, locateWidget], 'bottom-right');
    this._mapview?.ui.move(['zoom'], 'bottom-right');
    mapWidgetTour(this._mapview, 'bottom-right');

    this._mapview
      ?.when(() => {
        store.dispatch(setMapLoaded(true));
      })
      .catch((e: any) => console.log('error loading mapview', e));
    (window as any).mapController = this;
  };
  // TODO: Refactor after get more details relate to ['Get Details'] action flow

  getDetails = () => {
    if (this._mapview) {
      const { selectedFeature } = this._mapview.popup.viewModel;
      const species = selectedFeature.attributes.NAME;

      store.dispatch(setSpecies(''));
      store.dispatch(setSpecies(species));
    }
  };

  queryPlantBySpeciesName = async (scientificName: string, queryLayer = 0) => {
    const [QueryTask, Query, SpatialReference] = await loadModules([
      'esri/tasks/QueryTask',
      'esri/tasks/support/Query',
      'esri/geometry/SpatialReference',
    ]);
    const name = scientificName.replace(/'/gi, "''");
    var queryTask = new QueryTask({
      url: `${PLANT_DATA_LAYER_URL}/${queryLayer}`,
    });

    var query = new Query();
    query.returnGeometry = true;
    query.outSpatialReference = SpatialReference.WebMercator;
    query.outFields = ['*'];
    query.where = `${PLANT_ID_FIELD} = '${name}'`;

    try {
      const queryResult = await queryTask.execute(query);

      const data: any = [];

      queryResult.features.forEach((feature: any) => {
        const featureData: any = { attributes: {}, geometry: feature.geometry };

        Object.keys(feature.attributes).forEach((key) => {
          const specificAttribute = feature.attributes[key];
          featureData.attributes[key] =
            typeof specificAttribute === 'string' ? splitByCharacter(specificAttribute) : specificAttribute;
        });

        data.push(featureData);
      });

      return data;
    } catch (error) {
      console.warn('QUERY ERROR', error);
    }
  };

  queryPlantsByAccession = async (accesionIDs: Array<string>): Promise<Array<TourStop> | undefined> => {
    const [QueryTask, Query, SpatialReference] = await loadModules([
      'esri/tasks/QueryTask',
      'esri/tasks/support/Query',
      'esri/geometry/SpatialReference',
    ]);

    var queryTask = new QueryTask({
      url: `${PLANT_DATA_LAYER_URL}/0`,
    });
    var query = new Query();
    query.returnGeometry = true;
    query.outSpatialReference = SpatialReference.WebMercator;
    query.outFields = '*';
    query.where = getPlantsByAccessionWhereQuery(accesionIDs);

    try {
      const queryResult = await queryTask.execute(query);
      const results = queryResult.features.map(({ attributes }: any) => {
        const data: any = {};
        Object.keys(attributes).forEach((key) => {
          data[key] = typeof attributes[key] === 'string' ? splitByCharacter(attributes[key]) : attributes[key];
        });

        return data;
      });

      return results;
    } catch (error) {
      console.warn('QUERY ERROR', error);
    }
  };

  queryPlantBySpeciesNameForPhenologyChartData = async (scientificName: string) => {
    const [QueryTask, Query] = await loadModules(['esri/tasks/QueryTask', 'esri/tasks/support/Query']);
    const name = scientificName.replace(/'/gi, "''");

    const queryTask = new QueryTask({
      url: `${PLANT_DATA_LAYER_URL}/0`,
    });

    const query = new Query();
    query.returnGeometry = false;
    query.outFields = ['CHECK_DT', 'REPRODUCTIVE_STATUS'];
    query.where = `NAME = '${name}'`;

    try {
      const queryResult = await queryTask.execute(query);
      return getPhenologyChartData(queryResult.features);
    } catch (error) {
      console.warn('QUERY ERROR', error);
    }
  };

  queryPlantsBySpecificAccession = async (accession: string) => {
    const [QueryTask, Query] = await loadModules(['esri/tasks/QueryTask', 'esri/tasks/support/Query']);

    var queryTask = new QueryTask({
      url: `${PLANT_DATA_LAYER_URL}/0`,
    });
    var query = new Query();
    query.returnGeometry = true;
    query.num = 1;
    query.outFields = [
      'NAME',
      'COMMON_NAME_PRIMARY',
      'ACC_NUM_AND_QUAL',
      'CATALOG_DESC',
      'FAMILY',
      'HABIT_FULL',
      'FL_COLOR_FULL',
      // 'FL_COLOR_NOTE',
      'ACC_YR',
      'CURRENT_MEASURE_DT',
      'MAPPED',
      'CURRENT_DBH_FULL',
      'CURRENT_HEIGHT',
      'CURRENT_SPREAD',
      'MOST_RECENT_DBH',
      'MOST_RECENT_DBH_DT',
      'MOST_RECENT_HEIGHT',
      'MOST_RECENT_HEIGHT_DT',
      'MOST_RECENT_SPREAD',
      'MOST_RECENT_SPREAD_DT',
    ];
    query.where = `ACC_NUM_AND_QUAL = '${accession}' AND ALIVE = 'A'`;

    try {
      const queryResult = await queryTask.execute(query);
      const feature = queryResult.features[0].attributes;
      const geometry = queryResult.features[0].geometry;
      const data: any = {
        attributes: {},
        geometry: geometry,
      };

      Object.keys(feature).forEach((key) => {
        data.attributes[key] = typeof feature[key] === 'string' ? splitByCharacter(feature[key]) : feature[key];
      });

      return data;
    } catch (error) {
      console.warn('QUERY ERROR', error);
    }
  };

  goToGeometry = (geometry: any) => {
    if (!geometry.latitude || !geometry.longitude || !this._mapview) {
      return;
    }

    this._mapview.goTo({
      target: geometry,
      zoom: 16,
    });
  };

  queryPlantsForAccessionIDsBySpecies = async (species: string) => {
    const [QueryTask, Query] = await loadModules(['esri/tasks/QueryTask', 'esri/tasks/support/Query']);
    const name = splitByComma(species).replace(/'/gi, "''");

    var queryTask = new QueryTask({
      url: `${PLANT_DATA_LAYER_URL}/0`,
    });
    var query = new Query();
    query.returnGeometry = false;
    query.outFields = ['ACC_NUM_AND_QUAL', 'ACC_YR', 'CURRENT_LOCATION_FULL', 'MAPPED', 'PUBLICLY_ACCESSIBLE'];
    query.where = `NAME = '${name}' AND ALIVE = 'A'`;

    try {
      const queryResult = await queryTask.execute(query);
      const results = queryResult.features.map((feature: any, index: number) => [
        index + 1,
        feature.attributes.MAPPED,
        feature.attributes.ACC_NUM_AND_QUAL,
        feature.attributes.ACC_YR,
        feature.attributes.CURRENT_LOCATION_FULL,
        feature.attributes.PUBLICLY_ACCESSIBLE,
      ]);

      return results;
    } catch (error) {
      console.warn('QUERY ERROR', error);
    }
  };

  queryPlantsForAccessionIDsByCommonName = async (commonname: string) => {
    const [QueryTask, Query] = await loadModules(['esri/tasks/QueryTask', 'esri/tasks/support/Query']);
    const name = splitByComma(commonname).replace(/'/gi, "''");

    var queryTask = new QueryTask({
      url: `${PLANT_DATA_LAYER_URL}/0`,
    });
    var query = new Query();
    query.returnGeometry = false;
    query.outFields = ['ACC_NUM_AND_QUAL', 'ACC_YR', 'CURRENT_LOCATION_FULL', 'MAPPED', 'PUBLICLY_ACCESSIBLE'];
    query.where = `COMMON_NAME_PRIMARY = '${name}' AND ALIVE = 'A'`;

    try {
      const queryResult = await queryTask.execute(query);
      const results = queryResult.features.map((feature: any, index: number) => [
        index + 1,
        feature.attributes.MAPPED,
        feature.attributes.ACC_NUM_AND_QUAL,
        feature.attributes.ACC_YR,
        feature.attributes.CURRENT_LOCATION_FULL,
        feature.attributes.PUBLICLY_ACCESSIBLE,
      ]);

      return results;
    } catch (error) {
      console.warn('QUERY ERROR', error);
    }
  };

  queryPlantByCommonName = async (commonName: string): Promise<PlantData | undefined> => {
    const [QueryTask, Query, SpatialReference] = await loadModules([
      'esri/tasks/QueryTask',
      'esri/tasks/support/Query',
      'esri/geometry/SpatialReference',
    ]);
    const name = splitByComma(commonName).replace(/'/gi, "''");

    var queryTask = new QueryTask({
      url: `${PLANT_DATA_LAYER_URL}/0`,
    });
    var query = new Query();
    query.returnGeometry = true;
    query.outSpatialReference = SpatialReference.WebMercator;
    query.outFields = ['*'];
    query.where = `COMMON_NAME_PRIMARY = '${name}'`;

    try {
      const queryResult = await queryTask.execute(query);

      const data: any = [];

      queryResult.features.forEach((feature: any) => {
        const featureData: any = { attributes: {}, geometry: feature.geometry };

        Object.keys(feature.attributes).forEach((key) => {
          const specificAttribute = feature.attributes[key];
          featureData.attributes[key] =
            typeof specificAttribute === 'string' ? splitByCharacter(specificAttribute) : specificAttribute;
        });

        data.push(featureData);
      });

      return data;
    } catch (error) {
      console.warn('QUERY ERROR', error);
    }
  };

  querySpeciesByGenera = async (value: string): Promise<Array<string> | []> => {
    const [QueryTask, Query] = await loadModules(['esri/tasks/QueryTask', 'esri/tasks/support/Query']);

    const queryTask = new QueryTask({
      url: `${PLANT_DATA_LAYER_URL}/0`,
    });
    const query = new Query();

    query.outFields = 'NAME';
    query.returnDistinctValues = true;
    query.returnGeometry = false;
    query.where = `GENUS LIKE UPPER('%${value}%')`;

    const queryResults = await queryTask.execute(query).then((results: any) => {
      return results.features.map((feature: any) => splitByCharacter(feature.attributes.NAME));
    });
    return queryResults;
  };

  queryPlantsByCommonNamePreferred = async (value: string): Promise<Array<string> | []> => {
    const [QueryTask, Query] = await loadModules(['esri/tasks/QueryTask', 'esri/tasks/support/Query']);

    const queryTask = new QueryTask({ url: `${PLANT_DATA_LAYER_URL}/0` });
    const query = new Query();

    query.outFields = ['ALIVE', 'NAME'];
    query.returnGeometry = false;
    query.returnDistinctValues = true;
    query.where = `COMMON_NAME_PRIMARY = '${value}'`;

    return await queryTask.execute(query);
  };

  resetMapGraphics = async () => {
    if (!this._mapview) {
      return;
    }

    this.updateMapSelectedGardenLocations();
    const [Point] = await loadModules(['esri/geometry/Point']);

    this._mapview.graphics.removeAll();
    this._mapview.zoom = MAP_ZOOM;
    this._mapview.center = new Point({
      ...MAP_CENTER,
    });
  };

  goToLocations = async (features: any) => {
    if (!this._mapview || !features.length) {
      return;
    }

    const PLANT_LOCATION_UNDEFINED = [NaN, 0];
    const plantFeaturesWithExistingLocations = features.filter((feature: any) => {
      return (
        !PLANT_LOCATION_UNDEFINED.includes(feature.geometry.x) || !PLANT_LOCATION_UNDEFINED.includes(feature.geometry.y)
      );
    });

    this._mapview?.goTo({
      target: plantFeaturesWithExistingLocations,
      zoom: 16,
    });
  };

  // queryPlantsByGardenName = async (geometry: any) => {
  //   const [QueryTask, Query] = await loadModules(['esri/tasks/QueryTask', 'esri/tasks/support/Query']);

  //   const queryTask = new QueryTask({
  //     url: `${PLANT_DATA_LAYER_URL}/1`,
  //   });

  //   const query = new Query();
  //   query.outFields = ['NAME'];
  //   query.orderByFields = ['NAME'];
  //   query.returnDistinctValues = true;
  //   query.returnGeometry = false;
  //   query.geometry = geometry;
  //   query.outSpatialReference = this._mapview?.spatialReference;

  //   const queryResults = await queryTask
  //     .execute(query)
  //     .catch((e: any) => console.log('error in queryPlantsByGardenName()', e));

  //   const species = queryResults.features
  //     .map((feature: any) => feature.attributes.NAME)
  //     .filter((NAME: string) => NAME && NAME);

  //   return species;
  // };

  queryLocationByGardenName = async (gardenName: string) => {
    const [QueryTask, Query, SpatialReference] = await loadModules([
      'esri/tasks/QueryTask',
      'esri/tasks/support/Query',
      'esri/geometry/SpatialReference',
    ]);

    const queryTask = new QueryTask({
      url: LOCATIONS_URL,
    });

    const query = new Query();
    const filterPlantsFields = filterOptionsConfig.map((item) => item.esriField);
    query.outFields = [
      'OBJECTID',
      'ALIVE',
      'NAME',
      'COMMON_NAME_PRIMARY',
      'MAPPED',
      LOCATIONS_ESRI_FIELD,
      ...filterPlantsFields,
    ];
    query.where = `${LOCATIONS_GROUP_FIELD} LIKE '%${gardenName}%'`;
    query.returnGeometry = true;
    query.outSpatialReference = SpatialReference.WebMercator;

    store.dispatch(setSearchDefinitionExpression(query.where));

    const queryResults = await queryTask
      .execute(query)
      .catch((e: any) => console.log('error in queryLocationByGardenName()', e));

    const results = queryResults.features[0].attributes;

    return {
      results,
      features: queryResults.features,
    };
  };

  queryLocationForSearch = async (where: string) => {
    const [QueryTask, Query] = await loadModules(['esri/tasks/QueryTask', 'esri/tasks/support/Query']);

    const queryTask = new QueryTask({
      url: LOCATIONS_URL,
    });

    const query = new Query();
    query.outFields = [LOCATIONS_ESRI_FIELD, LOCATIONS_GROUP_FIELD];
    query.returnGeometry = false;
    query.returnDistinctValues = true;
    query.where = where;
    const queryResults = await queryTask.execute(query);

    return queryResults.toJSON();
  };

  queryGardenLocations = async (where: string) => {
    const [Query, SpatialReference, execute] = await loadModules([
      'esri/rest/support/Query',
      'esri/geometry/SpatialReference',
      'esri/rest/query/executeQueryPBF',
    ]);

    const query = new Query();
    query.where = where;
    query.outFields = [LOCATIONS_GARDEN_LOCATION, LOCATIONS_GARDEN_CODE, LOCATIONS_GARDEN_WEBSITE];
    query.outSpatialReference = SpatialReference.WebMercator;
    query.returnGeometry = true;

    const queryResults = await execute.executeQueryPBF(GARDEN_LOCATIONS_LAYER_URL, query);

    return queryResults;
  };

  queryPlantsForSearch = async (where: string) => {
    const [QueryTask, Query] = await loadModules(['esri/tasks/QueryTask', 'esri/tasks/support/Query']);

    const queryTask = new QueryTask({
      url: `${PLANT_DATA_LAYER_URL}/0`,
    });

    const query = new Query();
    // query.outFields = ['*'];
    query.outFields = ['SORT_NAME', 'ALIVE', 'NAME', 'OBJECTID', 'COMMON_NAMES', 'COMMON_NAME_PRIMARY'];
    query.orderByFields = ['SORT_NAME'];
    query.returnDistinctValues = true;
    query.returnGeometry = false;
    query.where = where;
    const queryResults = await queryTask.execute(query);

    const queryCount = query;
    if (this._filterDefinitionExpression) {
      queryCount.where = queryCount.where = `(${queryCount.where}) AND (${this._filterDefinitionExpression})`;
    }

    // NOTE: Snackbar removed
    // this._plantsFeatureLayer!.queryFeatureCount(queryCount).then((count: any) => {
    //   store.dispatch(setSnackbarResults(count));
    //   store.dispatch(setSnackbar(true));
    // });

    return queryResults;
  };

  queryAccessionsByTourID = async (tourID: number) => {
    const [QueryTask, Query] = await loadModules(['esri/tasks/QueryTask', 'esri/tasks/support/Query']);

    const queryTask = new QueryTask({
      url: TOURS_URL,
    });

    const query = new Query();

    query.outFields = ['ACC_NUM_AND_QUAL'];
    query.returnDistinctValues = true;
    query.returnGeometry = false;
    query.where = `Tour_ID = ${tourID}`;

    const queryResults = await queryTask.execute(query);
    const accessionIDs = queryResults.features.map((feature: any) => feature.attributes.ACC_NUM_AND_QUAL);

    return accessionIDs;
  };

  queryScientificCommonByLetter = async (letter: string, searchField: string) => {
    const [QueryTask, Query] = await loadModules(['esri/tasks/QueryTask', 'esri/tasks/support/Query']);

    const queryTask = new QueryTask({
      url: `${PLANT_DATA_LAYER_URL}/0`,
    });

    const query = new Query();

    // query.outFields = ['*'];
    query.outFields = [
      searchField,
      'ALIVE',
      'NAME',
      'OBJECTID',
      'GENUS',
      ...SEARCH_PLANT_ALPHABET_EXCLUDE_SECTIONS,
      ...SEARCH_PLANT_ALPHABET_SECTIONS,
    ];
    query.returnDistinctValues = true;
    query.returnGeometry = false;
    query.orderByFields = [searchField];
    query.where = `UPPER(${searchField}) LIKE '${letter}%'`;

    return await queryTask.execute(query);
  };

  getTourStopsPlantData = async (accesionIDs: Array<string>) => {
    if (!this._mapview || !this._map) {
      return;
    }
    const [QueryTask, Query, SpatialReference] = await loadModules([
      'esri/tasks/QueryTask',
      'esri/tasks/support/Query',
      'esri/geometry/SpatialReference',
    ]);

    const queryTask = new QueryTask({
      url: `${PLANT_DATA_LAYER_URL}/0`,
    });

    const query = new Query();
    query.outFields = ['*'];
    query.returnGeometry = true;
    query.outSpatialReference = SpatialReference.WebMercator;
    query.where = getPlantsByAccessionWhereQuery(accesionIDs);

    const queryResults = await queryTask.execute(query);

    // eslint-disable-next-line array-callback-return
    const geometries = queryResults.features.map((feature: any) => {
      if (feature.geometry.latitude && feature.geometry.longitude) {
        return {
          attributes: feature.attributes,
          geometry: feature.geometry,
        };
      }
    });

    return geometries;
  };

  setTourStopsActiveStop = (accession: string) => {
    if (!this._tourGraphicsLayer) {
      return;
    }

    this._tourGraphicsLayer.graphics.forEach((graphic: any) => {
      if (splitByComma(graphic.attributes.ACC_NUM_AND_QUAL) === accession) {
        graphic.symbol = {
          type: 'picture-marker',
          url: TourStopSelected,
          width: 30,
          height: 30,
        };
      } else {
        graphic.symbol = {
          type: 'simple-marker',
          style: 'circle',
          color: 'white',
          size: '12px',
          outline: {
            color: 'blue',
            width: 2,
          },
        };
      }
    });
  };

  resetTourStopsGraphicsLayer = () => {
    if (!this._tourGraphicsLayer) {
      return;
    }
    this._tourGraphicsLayer.graphics.removeAll();
  };

  goToAllTourStops = () => {
    if (!this._mapview || !this._tourGraphicsLayer) {
      return;
    }

    this._mapview.goTo({
      target: this._tourGraphicsLayer.graphics,
      zoom: 15,
    });
  };

  setTourStopsGraphicsLayer = async (results: Array<any>) => {
    if (!this._map) {
      return;
    }
    const [Graphic, GraphicsLayer] = await loadModules(['esri/Graphic', 'esri/layers/GraphicsLayer']);

    const graphics = results.map((feature: any) => {
      return new Graphic({
        attributes: feature && feature.attributes && feature.attributes,
        geometry: feature && feature.geometry && feature.geometry,
        symbol: {
          type: 'simple-marker',
          style: 'circle',
          color: 'white',
          size: '8px',
          outline: {
            color: 'blue',
            width: 2,
          },
        },
      });
    });

    if (!this._tourGraphicsLayer) {
      this._tourGraphicsLayer = new GraphicsLayer({
        id: 'tourStops',
      });
    } else {
      this._tourGraphicsLayer.graphics.removeAll();
    }

    this._tourGraphicsLayer?.addMany(graphics);

    this._map.add(this._tourGraphicsLayer as any);
  };

  queryTours = async () => {
    const [QueryTask, Query] = await loadModules(['esri/tasks/QueryTask', 'esri/tasks/support/Query']);

    const queryTask = new QueryTask({
      url: TOURS_URL,
    });

    const query = new Query();

    query.outFields = ['Tour_ID', 'Tour_Name', 'Description'];
    query.returnDistinctValues = true;
    query.returnGeometry = false;
    query.where = '1=1';

    const queryResults = await queryTask.execute(query);
    const tourResults = queryResults.features.map((feature: any) => feature.attributes);

    return tourResults;
  };

  goToTourStop = async (accession: string) => {
    if (!this._mapview || !this._tourGraphicsLayer) {
      return;
    }

    const graphic = this._tourGraphicsLayer.graphics.find(
      (graphic: __esri.Graphic) => graphic.attributes.ACC_NUM_AND_QUAL === accession
    );

    this._mapview.goTo(
      {
        target: graphic,
        // zoom: 16,
      },
      { duration: 1000 }
    );
  };

  queryGeneraByFamily = async (value: string): Promise<Array<string> | []> => {
    const [QueryTask, Query] = await loadModules(['esri/tasks/QueryTask', 'esri/tasks/support/Query']);

    const queryTask = new QueryTask({
      url: `${PLANT_DATA_LAYER_URL}/0`,
    });
    const query = new Query();

    query.outFields = 'GENUS';
    query.returnDistinctValues = true;
    query.returnGeometry = false;
    query.where = `FAMILY LIKE UPPER('%${value}%')`;

    const queryResults = await queryTask.execute(query);

    const results = queryResults.features.map((feature: any) => splitByCharacter(feature.attributes.GENUS)).sort();
    return results;
  };

  toggleADASublayers = (layerID: string, activeLayerToggles: Array<string>, adaSublayersTitles: string[]) => {
    if (!this._map || !this._adaMapImageLayer) {
      return;
    }

    const sublayerToggles = [...activeLayerToggles].filter((id) => adaSublayersTitles.includes(id));

    if (sublayerToggles.includes(layerID)) {
      const index = sublayerToggles.indexOf(layerID);
      sublayerToggles.splice(index, 1);
    } else {
      sublayerToggles.push(layerID);
    }

    this._adaMapImageLayer?.allSublayers?.forEach((sublayer) => {
      sublayer.visible = sublayerToggles.includes(sublayer.title);
    });

    this._adaMapImageLayer.visible = true;
  };

  togglePlantData = () => {
    if (!this._map || !this._plantsFeatureLayer) {
      return;
    }
    this._plantsFeatureLayer.visible = !this._plantsFeatureLayer.visible;
  };

  toggleArtBasemap = () => {
    if (!this._map || !this._artLayer1) {
      return;
    }

    this._artLayer1.visible = !this._artLayer1.visible;
  };

  togglePointsOfInterest = (mapActiveLayerToggles: Array<string>) => {
    if (!this._map || !this._interactiveFeatureLayer) {
      return;
    }

    let definitionExpression = '';

    if (mapActiveLayerToggles.length === 0) {
      this._interactiveFeatureLayer.definitionExpression = definitionExpression;
      this._interactiveFeatureLayer.visible = false;
      return;
    }

    const onlyPoints = (layerID: string) => layerID !== 'art' && layerID !== 'plantData';

    definitionExpression = mapActiveLayerToggles
      .filter((layerID: string) => onlyPoints(layerID))
      .map((layerID: string) => `Symbol = '${layerID}'`)
      .join(' OR ');

    definitionExpression = definitionExpression.length ? definitionExpression : "Symbol = 'Show None'";

    this._interactiveFeatureLayer.definitionExpression = definitionExpression;
  };

  toggleLocationCodes = () => {
    if (!this._map || !this._locationCodesMapImageLayer) {
      return;
    }
    this._locationCodesMapImageLayer.visible = !this._locationCodesMapImageLayer.visible;
  };

  waitForMapLoad = () => {
    return new Promise((resolve, reject) => {
      let checks = 0;
      const checkInterval = setInterval(() => {
        if (this._mapview) {
          clearInterval(checkInterval);
          resolve(true);
        }
        if (checks > 30) {
          //3 seconds
          clearInterval(checkInterval);
          reject();
        }
        checks++;
      }, 100);
    });
  };

  waitForPlantFeatureLayerViewLoad = () => {
    return new Promise((resolve, reject) => {
      let checks = 0;
      const checkInterval = setInterval(() => {
        if (this._plantsFeatureLayerView) {
          clearInterval(checkInterval);
          resolve(true);
        }
        if (checks > 30) {
          clearInterval(checkInterval);
          reject();
        }
        checks++;
      }, 100);
    });
  };

  handleDefinitionExpressionChange = async (definitionExpression: string) => {
    const appReducer = store.getState().appReducer;
    const { selectedAccession, searchType } = appReducer;

    this._mapPointsGraphicsLayer?.graphics?.removeAll();
    if (definitionExpression === '1=1' || definitionExpression === INITIAL_DEFINITION_EXPRESSION) {
      return;
    }

    const [Graphic] = await loadModules(['esri/Graphic']);
    if (this._plantsFeatureLayer && this._plantsFeatureLayerView && searchType !== LOCATIONS_SEARCH_LABEL) {
      let query = this._plantsFeatureLayer?.createQuery();
      if (definitionExpression) {
        query.where = `(${query.where}) AND (${definitionExpression})`;
      }
      query.outSpatialReference = this._mapview!.spatialReference;

      const response = await this._plantsFeatureLayer.queryFeatures(query);
      const { features } = response.toJSON();
      const filteredFeatures = features.filter((feature: any) => {
        return feature.geometry && !isNaN(feature.geometry.x) && !isNaN(feature.geometry.y);
      });

      if (features) {
        const definitionExpressionQueryAttributes = features.map((item: { attributes: any }) => item.attributes);
        store.dispatch(setDefinitionExpressionQueryAttributes(definitionExpressionQueryAttributes));
      }

      if (filteredFeatures.length > 0) {
        const { ACC_NUM_AND_QUAL } = selectedAccession?.attributes;
        const graphics = filteredFeatures.map((feature: any) => {
          const symbolImage = (
            feature: { attributes: { ACC_NUM_AND_QUAL: string; MAPPED: string } },
            ACC_NUM_AND_QUAL: string
          ) => {
            if (feature.attributes.ACC_NUM_AND_QUAL === ACC_NUM_AND_QUAL) {
              return feature.attributes.MAPPED === 'Y' ? iconPinPointExactSelected : iconPinPointApproximateSelected;
            } else {
              return feature.attributes.MAPPED === 'Y' ? iconPinPointExact : iconPinPointApproximate;
            }
          };

          const graphic = new Graphic({
            attributes: feature.attributes,
            geometry: { type: 'point', ...feature.geometry },
            symbol: {
              type: 'picture-marker', // autocasts as new PictureFillSymbol()
              url: symbolImage(feature, ACC_NUM_AND_QUAL),
              width: '20px',
              height: '25px',
            },
          });

          return graphic;
        });

        // Selected graphic based on selected plant ACC_NUM_AND_QUAL value
        const selectedGraphic = graphics.find((graphic: { attributes: { ACC_NUM_AND_QUAL: string } }) => {
          return graphic.attributes.ACC_NUM_AND_QUAL === ACC_NUM_AND_QUAL;
        });

        if (selectedGraphic) {
          this._mapview?.goTo(
            {
              center: [selectedGraphic.geometry.longitude, selectedGraphic.geometry.latitude],
              zoom: 21,
            },
            { duration: 1000 }
          );
        }

        this._mapPointsGraphicsLayer?.graphics?.addMany(graphics);
      }
    }
  };

  debounceHandleDefinitionExpressionChange: any = _debounce(
    (definitionExpression: string) => this.handleDefinitionExpressionChange(definitionExpression),
    500
  );

  updatePlantsLayerDefinitionExpression = async (definitionExpressions: object) => {
    try {
      await this.waitForMapLoad();
      await this.waitForPlantFeatureLayerViewLoad();

      let definitionExpression = '1=1';
      const values = _values(definitionExpressions).filter((value) => value !== '');
      if (values.length === 1) {
        definitionExpression = values[0];
      }
      if (values.length > 1) {
        definitionExpression = `(${values.join(') AND (')})`;
      }
      this._filterDefinitionExpression = definitionExpression;
      this.debounceHandleDefinitionExpressionChange(definitionExpression);
    } catch (e) {
      console.log('error in updatePlantsLayerDefinitionExpression()', e);
    }
  };

  updateMapSelectedGardenLocations = async (gardenCode = '') => {
    const { gardenLocations } = store.getState().appReducer;
    const definitionExpression = `GardenCode = '${gardenCode}'`;
    store.dispatch(setGardenCodeSelected(gardenCode));

    if (gardenCode) {
      const [Polygon] = await loadModules(['esri/geometry/Polygon']);
      const garden: any = gardenLocations.find(({ GardenCode }) => GardenCode === gardenCode);
      const polygon = new Polygon({
        ...garden.geometry,
      });

      this._mapview?.goTo(polygon);
    }

    if (
      this._gardenLocationsFeatureLayer &&
      this._gardenLocationsFeatureLayer.definitionExpression !== definitionExpression
    ) {
      this._gardenLocationsFeatureLayer.definitionExpression = definitionExpression;
    }
  };

  toggleAllMapLayers = (visible: boolean) => {
    if (!this._map || !this._adaMapImageLayer) {
      return;
    }

    this._map.basemap.baseLayers.forEach((layer: any) => {
      if (layer.id !== 'greyVector') {
        layer.visible = visible;
      }
    });

    this._map.layers.forEach((layer: any) => {
      if (layer.id !== 'interactiveFeatures') {
        layer.visible = visible;
      }
    });

    this.toggleAllADASublayers(visible);
  };

  toggleAllADASublayers = (visible: boolean) => {
    if (!this._adaMapImageLayer) {
      return;
    }

    this._adaMapImageLayer.visible = visible;
    this._adaMapImageLayer.loadAll().then(() => {
      this._adaMapImageLayer?.allSublayers.forEach((sublayer) => {
        sublayer.visible = visible;
      });
    });
  };
}

const mapController = new MapController();
//@ts-ignore
window.mapController = mapController;

export default mapController;
