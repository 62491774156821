import React from 'react';
import { Link } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { setMobileMenuOpen } from '../../store/app/appSlice';

import { BASE_URL } from '../../config/config';
import NYBGLogo from '../../styles/images/NYBGLogo.png';
import LogoSVG from '../../images/logo';
import NYBG_Logo_Black_RGB from '../../images/NYBG_Logo_Black_RGB.svg';

const HeaderLogoSection = () => {
  const dispatch = useDispatch();

  return (
    <div className='logo-section'>
      <a
        href='https://www.nybg.org/'
        target='_blank'
        rel='noopener noreferrer'
        onClick={() => dispatch(setMobileMenuOpen(false))}
      >
        <img src={NYBG_Logo_Black_RGB} alt='NYBG logo' />
      </a>
      <Link to={`${BASE_URL}/`} onClick={() => dispatch(setMobileMenuOpen(false))}>
        <span className='logo-section__label'>PLANT TRACKER</span>
      </Link>
    </div>
  );
};

export default HeaderLogoSection;
