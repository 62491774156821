import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import IconClose from '../../icons/IconClose';
import IconChevronUp from '../../icons/IconChevronUp';

// REDUX Store
import { RootState } from '../../../store/store';
import { setModalContent } from '../../../store/app/modalSlice';
import {
  $tourSelected,
  $tourStopIndex,
  $tourStops,
  defaultTourStopIndex,
  setToursAll,
  setTourStep,
  setTourStopIndex,
} from '../../../store/app/tourSlice';

import mapController from '../../../controllers/MapController';

import { TourStop } from '../../../interfaces/plantData';
import TourStopNumber from '../../tours/TourStopNumber/TourStopNumber';

import { getAndSetTourStops, resetTours, startCuratedTour, updateTourPathURL } from '../../../utils/tour.util';

import '../../../styles/css/tours.scss';

export function Tour() {
  const dispatch = useDispatch();
  const cardMinimized = useSelector((state: RootState) => state.appReducer.cardMinimized);
  const tourStep = useSelector((state: RootState) => state.tourReducer.tourStep);
  const tourSelected = useSelector($tourSelected);
  const tourStopIndex = useSelector($tourStopIndex);

  useEffect(() => {
    mapController.queryTours().then((results) => {
      dispatch(setToursAll(results));
      if (tourSelected.Tour_ID) {
        startCuratedTour(tourSelected.Tour_ID).then(() => {
          getAndSetTourStops();
        });
      }
    });
  }, []);

  useEffect(() => {
    if (tourStopIndex > defaultTourStopIndex) {
      updateTourPathURL(tourSelected.Tour_ID, tourStopIndex + 1);
    }
  }, [tourStopIndex]);

  const ViewTours = () => {
    return (
      <button
        className={`tour-button ${cardMinimized ? '' : 'hide-on-mobile'}`}
        onClick={() => dispatch(setModalContent('ViewTours'))}
        style={{ justifyContent: 'center' }}
      >
        TOURS
      </button>
    );
  };

  const TourStopsMapWidget = () => {
    const tourStops: Array<TourStop> = useSelector($tourStops);
    const tourStopIndex = useSelector($tourStopIndex);

    const ACC_NUM_AND_QUAL = tourStops.length ? tourStops[tourStopIndex].ACC_NUM_AND_QUAL : null;
    const SCIENTIFIC_NAME = tourStops.length ? tourStops[tourStopIndex].NAME : null;

    useEffect(() => {
      if (tourStops.length && ACC_NUM_AND_QUAL) {
        mapController.setTourStopsActiveStop(ACC_NUM_AND_QUAL);
        mapController.goToTourStop(ACC_NUM_AND_QUAL);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tourStops, tourStopIndex]);

    const updateActiveStop = (newActiveStop: number) => {
      if (!ACC_NUM_AND_QUAL) {
        return;
      }

      dispatch(setTourStopIndex(newActiveStop));
    };

    const returnButton = () => {
      switch (tourStep) {
        case 'ViewTours':
          return (
            <button
              className={`tour-button ${cardMinimized ? '' : 'hide-on-mobile'}`}
              onClick={() => {
                dispatch(setModalContent('ViewTours'));
                dispatch(setTourStep('TourStopsOpen'));
              }}
            >
              Tour stops
            </button>
          );
        case 'TourStopsOpen':
        case 'TourStopsClose':
          const TOUR_ARROW__LENGTH = 25;
          return (
            <>
              <div className={`tour-button ${cardMinimized ? '' : 'hide-on-mobile'}`}>
                <button className='close-tour' onClick={() => resetTours()}>
                  <IconClose size={35} />
                </button>
                <div className='stops-container'>
                  <button
                    className={`previous-stop ${tourStopIndex === 0 ? 'hidden' : ''}`}
                    onClick={() => updateActiveStop(tourStopIndex - 1)}
                  >
                    <IconChevronUp width={TOUR_ARROW__LENGTH} height={TOUR_ARROW__LENGTH} rotate={270} />
                  </button>
                  <button
                    className='active-stop'
                    onClick={() => {
                      dispatch(setModalContent('ViewTours'));
                      dispatch(setTourStep('TourStopsOpen'));
                    }}
                  >
                    <TourStopNumber index={tourStopIndex} />
                    <span>{SCIENTIFIC_NAME}</span>
                  </button>
                  <button
                    className={`next-stop ${tourStopIndex < tourStops.length - 1 ? '' : 'hidden'}`}
                    onClick={() => updateActiveStop(tourStopIndex + 1)}
                  >
                    <IconChevronUp width={TOUR_ARROW__LENGTH} height={TOUR_ARROW__LENGTH} rotate={90} />
                  </button>
                </div>
              </div>
            </>
          );
        default:
          return <></>;
      }
    };

    return returnButton();
  };

  const returnTourButton = () => {
    switch (tourStep) {
      case 'ViewTours':
        return <ViewTours />;
      case 'TourStopsOpen':
      case 'TourStopsClose':
        return <TourStopsMapWidget />;
      default:
        break;
    }
  };

  return <>{returnTourButton()}</>;
}

export default Tour;
