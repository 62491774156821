import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

import {
  SEARCH_OPTIONS,
  SEARCH_OPTIONS_ADVANCED,
  SEARCH_PLANT_ALPHABET_SECTIONS,
  URL__SEARCH_TYPE,
  URL__SEARCH_TYPE_ADVANCED,
  URL__SEARCH_VALUE,
} from '../../config/plant.config';
import { getResultKey } from '../../utils/plant.util';
import { PlantPreviewData } from '../../interfaces/plantData';
import { GardenLocation } from './modalSlice';

const query = new URLSearchParams(window.location.search);
const searchTypeFromURL: string | null = query.get(URL__SEARCH_TYPE);
const searchTypeAdvancedFromURL: string | null = query.get(URL__SEARCH_TYPE_ADVANCED);
const searchValueFromURL: string | null = query.get(URL__SEARCH_VALUE);

const allFavoritesString = localStorage.getItem('favoritePlants');
const allFavorites = allFavoritesString ? JSON.parse(allFavoritesString) : [];
const initialSearchTerms: any = {};
SEARCH_OPTIONS.forEach((option: string) => {
  if (option === 'Advanced') {
    SEARCH_OPTIONS_ADVANCED.forEach((item) => {
      if (searchValueFromURL && searchTypeAdvancedFromURL === item.esriField) {
        initialSearchTerms[getResultKey(option, item.esriField)] = searchValueFromURL;
      } else {
        if (SEARCH_PLANT_ALPHABET_SECTIONS.includes(item.esriField)) {
          initialSearchTerms[getResultKey(option, item.esriField)] = 'A';
        } else {
          initialSearchTerms[getResultKey(option, item.esriField)] = '';
        }
      }
    });
  } else {
    if (searchValueFromURL && searchTypeFromURL === option) {
      initialSearchTerms[getResultKey(option)] = searchValueFromURL;
    } else {
      initialSearchTerms[getResultKey(option)] = '';
    }
  }
});

export const DEFAULT_SEARCH_TYPE = 'Plants';
export const selectedAccessionDefault = {
  attributes: {
    ACC_NUM_AND_QUAL: '',
    MAPPED: '',
  },
  geometry: {},
};

export const apiPlantImagesDefault = {
  NAME_NUMS: [],
  images: {},
};

export const selectedPlanInitialState = {
  NAME: '',
  NAME_NUM: undefined,
  FAMILY: '',
  COMMON_NAME_PRIMARY: '',
  RANGE: '',
  HABIT_FULL: '',
  CATALOG_DESC: '',
};

export interface AppState {
  shareModalActive: boolean;
  species: string;
  cardContent: string;
  cardMinimized: boolean;
  legendExpanded: boolean;
  mobileMenuOpen: boolean;
  // snackbarOpen: boolean;
  // snackbarResults: number;
  mapLoaded: boolean;
  favoritePlants: Array<object>;
  searchWidgetLocation: string;
  searchType: string;
  searchTypeAdvanced: string;
  searchTerms: any;
  searchCurrentSelection: any;
  allLivingRecordsCheckbox: boolean;
  selectedAccession: {
    attributes?: any;
    geometry?: any;
  };
  selectedPanel: string;
  gardenLocations: object[];
  gardenCodeSelected: string;
  definitionExpressionQueryAttributes: Array<object>;
  fetchAdvancedSearch: boolean;
  selectedPlant: PlantPreviewData;
  apiPlantImages: {
    NAME_NUMS: any;
    images: any;
  };
}

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    shareModalActive: false,
    species: '',
    cardContent: '',
    cardMinimized: true,
    legendExpanded: false,
    mobileMenuOpen: false,
    // snackbarOpen: false,
    // snackbarResults: 0,
    mapLoaded: false,
    favoritePlants: allFavorites,
    searchWidgetLocation: '',
    searchType: searchTypeFromURL || DEFAULT_SEARCH_TYPE,
    searchTypeAdvanced: searchTypeAdvancedFromURL || SEARCH_OPTIONS_ADVANCED[0].esriField,
    searchTerms: initialSearchTerms,
    searchCurrentSelection: {},
    allLivingRecordsCheckbox: true,
    selectedAccession: selectedAccessionDefault,
    selectedPanel: '',
    gardenLocations: [],
    gardenCodeSelected: '',
    definitionExpressionQueryAttributes: [],
    fetchAdvancedSearch: true,
    selectedPlant: selectedPlanInitialState,
    apiPlantImages: apiPlantImagesDefault,
  },
  reducers: {
    setShareModalActive: (state: AppState, action: PayloadAction<AppState['shareModalActive']>) => {
      state.shareModalActive = action.payload;
    },
    setSpecies: (state: AppState, action: PayloadAction<AppState['species']>) => {
      state.species = action.payload;
    },
    setCardContent: (state: AppState, action: PayloadAction<AppState['cardContent']>) => {
      state.cardContent = action.payload;
    },
    setMinimized: (state: AppState, action: PayloadAction<AppState['cardMinimized']>) => {
      state.cardMinimized = action.payload;
    },
    setLegendExpanded: (state: AppState, action: PayloadAction<AppState['legendExpanded']>) => {
      state.legendExpanded = action.payload;
    },
    setMobileMenuOpen: (state: AppState, action: PayloadAction<AppState['mobileMenuOpen']>) => {
      state.mobileMenuOpen = action.payload;
    },
    // setSnackbar: (state: AppState, action: PayloadAction<AppState['snackbarOpen']>) => {
    //   state.snackbarOpen = action.payload;
    // },
    // setSnackbarResults: (state: AppState, action: PayloadAction<AppState['snackbarResults']>) => {
    //   state.snackbarResults = action.payload;
    // },
    setMapLoaded: (state: AppState, action: PayloadAction<AppState['mapLoaded']>) => {
      state.mapLoaded = action.payload;
    },
    updateFavoritePlants: (state: AppState, action: PayloadAction<AppState['favoritePlants']>) => {
      state.favoritePlants = action.payload;
    },
    setSearchWidgetLocation: (state: AppState, action: PayloadAction<AppState['searchWidgetLocation']>) => {
      state.searchWidgetLocation = action.payload;
    },
    setSearchType: (state: AppState, action: PayloadAction<AppState['searchType']>) => {
      state.searchType = action.payload;
    },
    setSearchTypeAdvanced: (state: AppState, action: PayloadAction<AppState['searchTypeAdvanced']>) => {
      state.searchTypeAdvanced = action.payload;
    },
    setSearchTerms: (state: AppState, action: PayloadAction<AppState['searchTerms']>) => {
      state.searchTerms = action.payload;
    },
    setSearchCurrentSelection: (state: AppState, action: PayloadAction<AppState['searchCurrentSelection']>) => {
      state.searchCurrentSelection = action.payload;
    },
    setAllLivingRecordsCheckbox: (state: AppState, action: PayloadAction<AppState['allLivingRecordsCheckbox']>) => {
      state.allLivingRecordsCheckbox = action.payload;
    },
    setSelectedAccession: (state: AppState, action: PayloadAction<AppState['selectedAccession']>) => {
      state.selectedAccession = action.payload;
    },
    setSelectedPanel: (state: AppState, action: PayloadAction<AppState['selectedPanel']>) => {
      state.selectedPanel = action.payload;
    },
    setGardenLocations: (state: AppState, action: PayloadAction<AppState['gardenLocations']>) => {
      state.gardenLocations = action.payload;
    },
    setGardenCodeSelected: (state: AppState, action: PayloadAction<AppState['gardenCodeSelected']>) => {
      state.gardenCodeSelected = action.payload;
    },
    setDefinitionExpressionQueryAttributes: (
      state: AppState,
      action: PayloadAction<AppState['definitionExpressionQueryAttributes']>
    ) => {
      state.definitionExpressionQueryAttributes = action.payload;
    },
    setFetchAdvancedSearch: (state: AppState, action: PayloadAction<AppState['fetchAdvancedSearch']>) => {
      state.fetchAdvancedSearch = action.payload;
    },
    setSelectedPlant: (state: AppState, action: PayloadAction<AppState['selectedPlant']>) => {
      state.selectedPlant = action.payload;
    },
    setApiPlantImages: (state: AppState, action: PayloadAction<AppState['apiPlantImages']>) => {
      state.apiPlantImages = action.payload;
    },
  },
});

export const {
  setShareModalActive,
  setSpecies,
  setCardContent,
  setMinimized,
  setLegendExpanded,
  setMobileMenuOpen,
  // setSnackbar,
  // setSnackbarResults,
  setMapLoaded,
  updateFavoritePlants,
  setSearchWidgetLocation,
  setSearchType,
  setSearchTypeAdvanced,
  setSearchTerms,
  setSearchCurrentSelection,
  setAllLivingRecordsCheckbox,
  setSelectedAccession,
  setSelectedPanel,
  setGardenLocations,
  setGardenCodeSelected,
  setDefinitionExpressionQueryAttributes,
  setFetchAdvancedSearch,
  setSelectedPlant,
  setApiPlantImages,
} = appSlice.actions;

export const selectShareModalActive = (state: RootState) => state.appReducer.shareModalActive;
export const species = (state: any) => state.app.species;
export const cardContent = (state: RootState) => state.appReducer.cardContent;
export const $cardMinimized = (state: RootState) => state.appReducer.cardMinimized;
export const $legendExpanded = (state: RootState) => state.appReducer.legendExpanded;
export const selectMobileMenuOpen = (state: RootState) => state.appReducer.mobileMenuOpen;
// export const snackbarOpen = (state: RootState) => state.appReducer.snackbarOpen;
// export const snackbarResults = (state: RootState) => state.appReducer.snackbarResults;
export const $mapLoaded = (state: RootState) => state.appReducer.mapLoaded;
export const selectFavoritePlants = (state: RootState) => state.appReducer.favoritePlants;
export const searchWidgetLocation = (state: RootState) => state.appReducer.searchWidgetLocation;
export const $searchType = (state: RootState) => state.appReducer.searchType;
export const $searchTypeAdvanced = (state: RootState) => state.appReducer.searchTypeAdvanced;
export const $searchTerms = (state: RootState) => state.appReducer.searchTerms;
export const $searchCurrentSelection = (state: RootState) => state.appReducer.searchCurrentSelection;
export const $allLivingRecordsCheckbox = (state: RootState) => state.appReducer.allLivingRecordsCheckbox;
export const $selectedAccession = (state: RootState) => state.appReducer.selectedAccession;
export const $selectedPanel = (state: RootState) => state.appReducer.selectedPanel;
export const $gardenLocations = (state: RootState) => state.appReducer.gardenLocations;
export const $gardenCodeSelected = (state: RootState) => state.appReducer.gardenCodeSelected;
export const $definitionExpressionQueryAttributes = (state: RootState) =>
  state.appReducer.definitionExpressionQueryAttributes;
export const $fetchAdvancedSearch = (state: RootState) => state.appReducer.fetchAdvancedSearch;
export const $selectedPlant = (state: RootState) => state.appReducer.selectedPlant;
export const $apiPlantImages = (state: RootState) => state.appReducer.apiPlantImages;

export default appSlice.reducer;
