import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

// Store
import { $searchTerms, $searchType, $searchTypeAdvanced, setSearchTypeAdvanced } from '../../../store/app/appSlice';
import { SEARCH_OPTIONS_ADVANCED } from '../../../config/plant.config';

import './AdvancedFiltersOptions.scss';
import { setURLPath } from '../../../utils/query.utils';
import { getResultKey } from '../../../utils/plant.util';

export default function AdvancedFiltersOptions() {
  const dispatch = useDispatch();
  const searchTerms = useSelector($searchTerms);
  const searchType = useSelector($searchType);
  const searchTypeAdvanced = useSelector($searchTypeAdvanced);

  const handleAdvancedFiltersOptionsChange = (searchTypeAdvancedLocal: string) => {
    const searchTermsKey = getResultKey(searchType, searchTypeAdvancedLocal);
    const searchTerm = searchTerms[searchTermsKey];
    dispatch(setSearchTypeAdvanced(searchTypeAdvancedLocal));
    setURLPath(searchType, searchTypeAdvancedLocal, searchTerm);
  };

  return (
    <div className='AdvancedFiltersOptions__container'>
      <FormControl className='AdvancedFiltersOptions'>
        <NativeSelect
          disableUnderline
          value={searchTypeAdvanced}
          onChange={(event) => handleAdvancedFiltersOptionsChange(event.target.value)}
          name='Search Type'
          inputProps={{ 'aria-label': 'Advanced Search Type' }}
        >
          {SEARCH_OPTIONS_ADVANCED.map(({ label, esriField }) => {
            return (
              <option key={esriField} value={esriField}>
                {label}
              </option>
            );
          })}
        </NativeSelect>
      </FormControl>
    </div>
  );
}
