import React from 'react';
import { useSelector } from 'react-redux';
import ImageGallery from 'react-image-gallery';

import { $modalGardenLocation } from '../../../store/app/modalSlice';
import { PlantGalleryImage } from '../../../interfaces/plantData';

import './GardenGallery.scss';

export default function GardenGallery() {
  const { previewUrl, thumbnailUrl, GardenLocation }: any = useSelector($modalGardenLocation);

  const plantGalleryImages: PlantGalleryImage[] = [
    { original: previewUrl, thumbnail: thumbnailUrl, originalAlt: GardenLocation },
  ];

  return (
    <div className='GardenGallery'>
      <ImageGallery items={plantGalleryImages} showPlayButton={false} showFullscreenButton={false} />
    </div>
  );
}
