import { getDayOfYear } from 'date-fns';
import dayjs from 'dayjs';
import {
  setPhenologyFloweringDateEnd,
  setPhenologyFloweringDateStart,
  setPhenologySliderStartingDate,
  setPhenologySliderValues,
} from '../store/app/phenologySlice';
import store from '../store/store';

export const PHENOLOGY_SEARCH_YEAR = 2022;

export const getSliderValueFromDate = (date: Date, sliderStartingDate: Date) => {
  return dayjs(date).diff(sliderStartingDate, 'day') + 1;
};

export const getInitialDateRange = () => {
  const today = dayjs();
  let startDate = today.subtract(7, 'day').toDate();
  startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  let endDate = today.add(7, 'day').toDate();
  endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

  return {
    startDate,
    endDate,
  };
};

export const setInitialDateRange = () => {
  const { startDate, endDate } = getInitialDateRange();

  let phenologySliderStartingDate = new Date(store.getState().phenologyReducer.phenologySliderStartingDate);

  let startDaySliderValue = getSliderValueFromDate(startDate, phenologySliderStartingDate);
  let endDaySliderValue = getSliderValueFromDate(endDate, phenologySliderStartingDate);

  // Update slider starting date if initial range is not in current slider range
  if (startDaySliderValue > endDaySliderValue) {
    phenologySliderStartingDate = dayjs(startDate).startOf('month').toDate();
    store.dispatch(setPhenologySliderStartingDate(phenologySliderStartingDate.toString()));

    startDaySliderValue = getSliderValueFromDate(startDate, phenologySliderStartingDate);
    endDaySliderValue = getSliderValueFromDate(endDate, phenologySliderStartingDate);
  }

  const startDayOfTheYear = getDayOfYear(startDate);
  const endDayOfTheYear = getDayOfYear(endDate);

  store.dispatch(setPhenologyFloweringDateStart({ dayOfYear: startDayOfTheYear, date: startDate.toString() }));
  store.dispatch(setPhenologyFloweringDateEnd({ dayOfYear: endDayOfTheYear, date: endDate.toString() }));

  const updatedSliderValues = {
    start: startDaySliderValue,
    end: endDaySliderValue,
  };
  store.dispatch(setPhenologySliderValues(updatedSliderValues));
};

export const getUpdatedSliderMarks = (initialSliderDate: Date) => {
  const marks: {
    value: number;
    label: string;
  }[] = [];

  const initialMonth = initialSliderDate.getMonth();

  let currentMonth = initialMonth;
  let currentDate = initialSliderDate;
  let currentYear = initialSliderDate.getFullYear();

  do {
    if (currentDate.getDate() === 1) {
      marks.push({
        value: getSliderValueFromDate(currentDate, initialSliderDate),
        label: currentDate.toLocaleString('en-US', { month: 'short' }),
      });
    }

    if (currentMonth < 11) {
      currentMonth++;
    } else {
      currentMonth = 0;
      currentYear++;
    }

    currentDate = new Date(currentYear, currentMonth, 1);
  } while (currentMonth !== initialMonth);

  marks.push({
    value: getSliderValueFromDate(dayjs(currentDate).subtract(1, 'day').toDate(), initialSliderDate),
    label: currentDate.toLocaleString('en-US', { month: 'short' }),
  });

  return marks;
};
