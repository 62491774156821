import React from 'react';

export function ModalFooter(props: {
  footerLeftLabel: string | undefined;
  footerLeftClass: string | undefined;
  footerRightLabel: string | undefined;
  footerRightClass: string | undefined;
}) {
  const { footerLeftLabel, footerLeftClass, footerRightLabel, footerRightClass } = props;
  return (
      <div id='ModalFooter'>
        {footerLeftLabel && (
          <div id='ModalFooterLeft'>
            <button className={footerLeftClass}>{footerLeftLabel}</button>
          </div>
        )}

        {footerRightLabel && (
          <div id='ModalFooterRight'>
            <button className={footerRightClass}>{footerRightLabel}</button>
          </div>
        )}
      </div>
  );
}
