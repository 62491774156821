import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { format } from 'date-fns';

import { setCardContent, setMinimized, setSelectedAccession } from '../../store/app/appSlice';
import { RootState } from '../../store/store';

import IconWhiteMap from '../icons/IconWhiteMap';
import IconChevronUp from '../icons/IconChevronUp';
import ResultsError from '../error/ResultsError';

import mapController from '../../controllers/MapController';
import ScientificNameFormatted from './searchResults/PlantResultScientificName/ScientificNameFormatted';
import { MAP_LINK__SPECIES, encodePathValue } from '../../utils/path.util';
import { getClean__CATALOG_DESC } from '../../utils/plant.util';

import { BASE_URL } from '../../config/config';

import '../../styles/css/plant.scss';

const NO_RESULTS = 'Data coming soon';

const plantAttributes = {
  NAME: '',
  COMMON_NAME_PRIMARY: '',
  ACC_NUM_AND_QUAL: '',
  CATALOG_DESC: '',
  FAMILY: '',
  HABIT_FULL: '',
  FL_COLOR_FULL: '',
  FL_COLOR_NOTE: '',
  ACC_YR: '',
  CURRENT_MEASURE_DT: '',
  CURRENT_SPREAD: '',
  HEIGHT: '',
  CURRENT_HEIGHT: '',
  SPREAD_UNIT: '',
  SPREAD_ACCURACY: '',
  CURRENT_SPREAD_FULL: '',
  DBH: '',
  DBH_UNIT: '',
  DBH_ACCURACY: '',
  CURRENT_DBH: '',
  CURRENT_DBH_FULL: '',
  SPREAD_1: '',
  MOST_RECENT_DBH: '',
  MOST_RECENT_DBH_DT: '',
  MOST_RECENT_HEIGHT: '',
  MOST_RECENT_HEIGHT_DT: '',
  MOST_RECENT_SPREAD: '',
  MOST_RECENT_SPREAD_DT: '',
};

function getDateFormated(timestamp: string) {
  return format(parseInt(timestamp), 'dd MMM yyyy');
}

const SpecificAccession = () => {
  let { search } = useLocation();
  const { goBack } = useHistory();
  const dispatch = useDispatch();
  const cardMinimized = useSelector((state: RootState) => state.appReducer.cardMinimized);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [plant, setPlant] = useState({
    attributes: plantAttributes,
    geometry: {},
  });

  const query = new URLSearchParams(search);
  const accession = query.get('id');

  useEffect(() => {
    mapController.queryPlantsBySpecificAccession(accession as string).then((results) => {
      if (results) {
        const { geometry, attributes } = results;
        dispatch(
          setSelectedAccession({
            attributes: attributes,
            geometry: geometry.toJSON(),
          })
        );
        setPlant(results as any);
      } else {
        setError(true);
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='plant-container__wrapper'>
      <div className='plant-container index'>
        <div className='header' onClick={() => dispatch(setMinimized(!cardMinimized))}>
          <Link
            id='ViewOnMap__link'
            to={{
              pathname: `${BASE_URL}${MAP_LINK__SPECIES}${encodePathValue(plant.attributes.NAME)}`,
              state: { fromView: 'SpecificAccessionView' },
            }}
            onClick={() => {
              dispatch(setCardContent(''));
              dispatch(setSelectedAccession(plant));
            }}
          >
            <IconChevronUp fill={'#fff'} rotate={-90} />
            View on Map
            <IconWhiteMap />
          </Link>

          <div id='BackToSearch'>
            <div className='BackToSearch__button'>
              <button className='BackToSearch__button-element' onClick={() => goBack()}>
                <IconChevronUp rotate={-90} fill='#fff' />
              </button>
              <span className='BackToSearch__title'>
                {plant?.attributes?.COMMON_NAME_PRIMARY ? plant.attributes.COMMON_NAME_PRIMARY : NO_RESULTS}
              </span>
            </div>
          </div>
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <ResultsError value={accession ? accession : 'this accession number'} />
        ) : (
          plant?.attributes && (
            <>
              <p className='subtitle'>
                {plant.attributes.NAME ? <ScientificNameFormatted plantName={plant.attributes.NAME} /> : NO_RESULTS}
              </p>
              {plant.attributes.ACC_NUM_AND_QUAL && (
                <p className='plant-line'>
                  <strong>Accession Number: </strong>
                  {plant.attributes.ACC_NUM_AND_QUAL}
                </p>
              )}
              <div className='grid section-two'>
                {plant.attributes.CATALOG_DESC && (
                  <>
                    <p className='plant-line'>
                      <strong>Landscape Uses:</strong>
                    </p>
                    <p
                      className='plant-line green-color'
                      dangerouslySetInnerHTML={{ __html: getClean__CATALOG_DESC(plant.attributes.CATALOG_DESC) }}
                    />
                  </>
                )}
                {plant.attributes.FAMILY && (
                  <>
                    <p className='plant-line'>
                      <strong>Family:</strong>
                    </p>
                    <p className='plant-line green-color'>{`${plant.attributes.FAMILY} Family`}</p>
                  </>
                )}
                {plant.attributes.HABIT_FULL && (
                  <>
                    <p className='plant-line'>
                      <strong>Habit:</strong>
                    </p>
                    <p className='plant-line green-color'>{plant.attributes.HABIT_FULL}</p>
                  </>
                )}

                {plant.attributes.FL_COLOR_FULL && (
                  <>
                    <p className='plant-line'>
                      <strong>Flowers:</strong>
                    </p>
                    <p className='plant-line green-color'>
                      {plant.attributes.FL_COLOR_FULL && plant.attributes.FL_COLOR_FULL}
                      {/* {plant.attributes.FL_COLOR_NOTE && plant.attributes.FL_COLOR_NOTE} */}
                    </p>
                  </>
                )}
                {plant.attributes.ACC_YR && (
                  <>
                    <p className='plant-line'>
                      <strong>Accession Year:</strong>
                    </p>
                    <p className='plant-line green-color'>{plant.attributes.ACC_YR}</p>
                  </>
                )}

                {/*{plant.attributes.CURRENT_MEASURE_DT && (*/}
                {/*  <>*/}
                {/*    <p className='plant-line'>*/}
                {/*      <strong>Date Last Measured:</strong>*/}
                {/*    </p>*/}
                {/*    <p className='plant-line green-color'>*/}
                {/*      {format(parseInt(plant.attributes.CURRENT_MEASURE_DT), 'dd MMM yyyy')}*/}
                {/*    </p>*/}
                {/*  </>*/}
                {/*)}*/}

                {/*{plant.attributes.CURRENT_DBH_FULL && (*/}
                {/*  <>*/}
                {/*    <p className='plant-line'>*/}
                {/*      <strong>Trunk Diameter (inches):</strong>*/}
                {/*    </p>*/}
                {/*    <p className='plant-line green-color'>{plant.attributes.CURRENT_DBH_FULL}</p>*/}
                {/*  </>*/}
                {/*)}*/}

                {/*{plant.attributes.CURRENT_HEIGHT && (*/}
                {/*  <>*/}
                {/*    <p className='plant-line'>*/}
                {/*      <strong>Height (feet):</strong>*/}
                {/*    </p>*/}
                {/*    <p className='plant-line green-color'>{plant.attributes.CURRENT_HEIGHT}</p>*/}
                {/*  </>*/}
                {/*)}*/}

                {/*{plant.attributes.CURRENT_SPREAD && (*/}
                {/*  <>*/}
                {/*    <p className='plant-line'>*/}
                {/*      <strong>Spread (feet):</strong>*/}
                {/*    </p>*/}
                {/*    <p className='plant-line green-color'>{plant.attributes.CURRENT_SPREAD}</p>*/}
                {/*  </>*/}
                {/*)}*/}

                {plant.attributes.MOST_RECENT_DBH && (
                  <>
                    <p className='plant-line'>
                      <strong>Trunk Diameter (inches):</strong>
                    </p>
                    <p className='plant-line green-color'>{plant.attributes.MOST_RECENT_DBH}</p>
                  </>
                )}

                {plant.attributes.MOST_RECENT_DBH_DT && (
                  <>
                    <p className='plant-line'>
                      <strong>Trunk Diameter (inches) Date Last Measured:</strong>
                    </p>
                    {/*<p className='plant-line green-color'>{plant.attributes.MOST_RECENT_DBH_DT}</p>*/}
                    <p className='plant-line green-color'>{getDateFormated(plant.attributes.MOST_RECENT_DBH_DT)}</p>
                  </>
                )}

                {plant.attributes.MOST_RECENT_HEIGHT && (
                  <>
                    <p className='plant-line'>
                      <strong>Height (feet):</strong>
                    </p>
                    <p className='plant-line green-color'>{plant.attributes.MOST_RECENT_HEIGHT}</p>
                  </>
                )}

                {plant.attributes.MOST_RECENT_HEIGHT_DT && (
                  <>
                    <p className='plant-line'>
                      <strong>Height (feet) Date Last Measured:</strong>
                    </p>
                    <p className='plant-line green-color'>{getDateFormated(plant.attributes.MOST_RECENT_HEIGHT_DT)}</p>
                  </>
                )}

                {plant.attributes.MOST_RECENT_SPREAD && (
                  <>
                    <p className='plant-line'>
                      <strong>Spread (feet):</strong>
                    </p>
                    <p className='plant-line green-color'>{plant.attributes.MOST_RECENT_SPREAD}</p>
                  </>
                )}

                {plant.attributes.MOST_RECENT_SPREAD_DT && (
                  <>
                    <p className='plant-line'>
                      <strong>Spread (feet) Date Last Measured:</strong>
                    </p>
                    <p className='plant-line green-color'>{getDateFormated(plant.attributes.MOST_RECENT_SPREAD_DT)}</p>
                  </>
                )}
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
};

export default SpecificAccession;
