import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSwipeable } from 'react-swipeable';

import IconTrashBin from '../../icons/IconTrashBin';
import IconHeartRed from '../../icons/IconHeartRed';
import IconShare from '../../icons/IconShare';
import WidgetImage from '../WidgetImage/WidgetImage';

import { encodePathValue, PLANTS_LINK__SPECIES } from '../../../utils/path.util';

// REDUX Store
import { setModalContent } from '../../../store/app/modalSlice';

// Style
import './FavoritePlantsItem.scss';

const ICON__SIZE = 28;
const REMOVE_BUTTON__SIZE = 40;

export function FavoritePlantsItem({
  commonName,
  scientificName,
  sharePlant,
  unfavoritePlant,
}: {
  commonName: string;
  scientificName: string;
  unfavoritePlant: () => void;
  sharePlant: () => void;
}) {
  const dispatch = useDispatch();
  const [remove, setRemove] = useState<boolean>(false);

  const handlers = useSwipeable({
    onSwipedLeft: () => setRemove(false),
    onSwipedRight: () => setRemove(true),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    trackTouch: true,
  });

  return (
    <div className='specific-favorite__wrapper'>
      <div className='specific-favorite' {...handlers} style={{ left: remove ? 0 : -REMOVE_BUTTON__SIZE }}>
        <div className='favorite-remove-button__confirm-wrapper'>
          <button
            className='favorite-remove-button__confirm'
            onClick={unfavoritePlant}
            style={{ width: REMOVE_BUTTON__SIZE }}
          >
            <IconTrashBin width={ICON__SIZE} height={ICON__SIZE} />
          </button>
        </div>
        <div className='specific-favorite-content'>
          <WidgetImage alt={scientificName} />

          <div className='names'>
            <h4>{commonName}</h4>
            <p>{scientificName}</p>
            <Link
              className='learn-more'
              to={`${PLANTS_LINK__SPECIES}${encodePathValue(scientificName)}`}
              onClick={() => dispatch(setModalContent(''))}
            >
              Learn More
            </Link>
          </div>
        </div>
      </div>

      <div className='favorite-icons' style={{ width: REMOVE_BUTTON__SIZE }}>
        <button onClick={() => setRemove(!remove)} style={{ width: REMOVE_BUTTON__SIZE, height: REMOVE_BUTTON__SIZE }}>
          <IconHeartRed width={ICON__SIZE} height={ICON__SIZE} />
        </button>
        <button onClick={sharePlant} style={{ width: REMOVE_BUTTON__SIZE, height: REMOVE_BUTTON__SIZE }}>
          <IconShare width={ICON__SIZE} height={ICON__SIZE} />
        </button>
      </div>
    </div>
  );
}
