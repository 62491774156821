import * as React from 'react';
import { SVG_CONFIG } from '../../config/config';

function IconClose({ fill, size = 50 }: { fill?: string; size?: number }) {
  return (
    <svg width={size} height={size} fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'>
      <circle cx={25} cy={25} r={25} fill='#fff' />
      <path
        fill={fill ? fill : SVG_CONFIG.fill}
        d='M35.417 17.064L33.353 15l-8.145 8.145L17.064 15 15 17.064l8.145 8.144L15 33.353l2.064 2.064 8.144-8.145 8.145 8.145 2.064-2.064-8.145-8.145 8.145-8.144z'
      />
    </svg>
  );
}

export default IconClose;
