import { scientificNameFormatted } from '../../components/plants/searchResults/PlantResultScientificName/ScientificNameFormatted';
import { splitByCharacter } from '../../utils/plantSyntax.util';

import './popup.scss';

interface PopupTemplateData {
  graphic: __esri.Graphic;
}

const getPopupTemplateContent = (graphic: __esri.Graphic) => {
  const formattedSunFullAttribute = splitByCharacter(graphic?.attributes?.SUN_FULL);

  return `<div class="esri-popup__custom-content">
    <p><strong>Common Name</strong>: {COMMON_NAME_PRIMARY}</p>
    <p><strong>Accession ID</strong>: {ACC_NUM_AND_QUAL}</p>
    <p><strong>Family</strong>: {FAMILY}</p>
    <p><strong>Habit</strong>: {HABIT_FULL}</p>
    <p><strong>Native</strong>: {RANGE}</p>
    <p><strong>Hardiness</strong>: 
      <a href='https://planthardiness.ars.usda.gov/phzmweb/interactivemap.aspx'
      target='_blank'
      rel='noopener noreferrer'>USDA Zone {HARDI_ZONE_USDA}</a>
    </p>
    <p><strong>Light</strong>: ${formattedSunFullAttribute}</p>
    <p><strong>Location</strong>: {CURRENT_LOCATION_FULL}</p>
  </div>
  `;
};

const getDetailsAction = {
  title: 'Get Details',
  id: 'get-details',
  className: 'get-details',
};

const colosePopupAction = {
  title: 'Close Popup',
  id: 'popup-close-list',
  className: 'esri-icon-close popup-close-list',
};

const title = (evt: any) => {
  const { NAME } = evt.graphic.attributes;
  return scientificNameFormatted(NAME);
};

export const getPopupTemplate = () => {
  return {
    title: title,
    content: (item: PopupTemplateData) => getPopupTemplateContent(item?.graphic),
    actions: [getDetailsAction, colosePopupAction],
    overwriteActions: true,
    outFields: [
      'ACC_NUM_AND_QUAL',
      'COMMON_NAME_PRIMARY',
      'FAMILY',
      'HABIT_FULL',
      'HARDI_ZONE_USDA',
      'NAME',
      'RANGE',
      'SUN_FULL',
      'CURRENT_LOCATION_FULL',
    ],
  };
};

const POINT_OF_INTEREST_POPUP_TEMPLATE_CONTENT = `<div class="esri-popup__custom-content">
  <p><i>{Current_Content}</i></p>
</div>
`;

export const getPointOfInterestPopupTemplate = () => {
  return {
    title: '{Name}',
    content: POINT_OF_INTEREST_POPUP_TEMPLATE_CONTENT,
  };
};
