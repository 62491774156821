//@ts-nocheck
import React, { useEffect, useState } from 'react';
import _chunk from 'lodash/chunk';
import _uniqBy from 'lodash/uniqBy';
import _sortBy from 'lodash/sortBy';
import _isEqual from 'lodash/isEqual';
import List from 'react-virtualized/dist/commonjs/List';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';

// STORE
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { $allLivingRecordsCheckbox, $definitionExpressionQueryAttributes } from '../../../store/app/appSlice';
import { selectMapFilterSelectedOptions } from '../../../store/app/filterSlice';

import PlantResult from './PlantResult';
import { PlantSearchFindPlantInGarden } from './PlantSearchFindPlantInGarden/PlantSearchFindPlantInGarden';
import { PlantSearchResultsNoResults } from './PlantSearchResultsNoResults/PlantSearchResultsNoResults';
import {
  AUTOSIZER__ROW_HEIGHT,
  FIELD_COMMON_NAME_ESRI,
  SEARCH_PLANT_ALPHABET_SECTIONS,
} from '../../../config/plant.config';
import { getNumberOfResultColumns } from '../../../utils/results.util';

const PlantSearchResults = ({
  isAdvancedSearchField,
  results,
  searchField,
  searchFieldAdvanced,
  isBloomResults = false,
  searchTerm = '',
}) => {
  const filterDefinitionExpression = useSelector((state: RootState) => state.filterReducer.filterDefinitionExpression);
  const allLivingRecordsCheckbox = useSelector($allLivingRecordsCheckbox);
  const mapFilterSelectedOptions = useSelector(selectMapFilterSelectedOptions);
  const definitionExpressionQueryAttributes = useSelector($definitionExpressionQueryAttributes);

  // let { search } = useLocation();
  const [numberOfColumns, setNumberOfColumns] = useState(1);
  const [rows, setRows] = useState([]);

  // const searchQuery = new URLSearchParams(search);
  // const isGenericSearch = searchQuery.get('field') === 'Plants' ? true : false;
  // const sortAttribute = searchFieldAdvanced ? searchFieldAdvanced : 'COMMON_NAME_PRIMARY';
  // TODO: Move sort to Query request
  const sortByAttribute = 'SORT_NAME';

  useEffect(() => {
    const filtersRegular = mapFilterSelectedOptions.filter((option) => {
      if (option.activeRadioButton) {
        return option.activeRadioButton !== 'All';
      }
    });

    const filtersCharacteristics = mapFilterSelectedOptions.find((option) => {
      if (option.activeValues.length > 0 && !option.activeRadioButton) {
        return !_isEqual(option.activeValues, ['All']);
      }
    });

    if (!results || results.length === 0) {
      let rows;

      if (filtersRegular?.length > 0 || filtersCharacteristics?.length > 0) {
        let filteredResults = definitionExpressionQueryAttributes;

        filteredResults = _uniqBy(filteredResults, (plant) => `${plant.NAME} - ${plant.COMMON_NAME_PRIMARY}`);
        filteredResults = _sortBy(filteredResults, (plant) => `${plant.NAME} - ${plant.COMMON_NAME_PRIMARY}`);

        rows = _chunk(filteredResults, numberOfColumns);
      } else {
        rows = [];
      }
      setRows(rows);
      return;
    }

    const plantsAttributes = results
      .filter((plant) => {
        return allLivingRecordsCheckbox ? plant.ALIVE === 'A' : true;
      })
      .filter((plant) => {
        if (filtersRegular.length > 0) {
          const filtersMatching = filtersRegular.map((filter) => {
            const plantValue = plant[filter.esriField];
            const filterValue = filter.activeRadioButton;
            if (plantValue && filterValue) {
              return plantValue.toLowerCase().includes(filterValue.toLowerCase());
            } else {
              return false;
            }
          });

          return filtersMatching.every(Boolean);
        } else {
          return true;
        }
      })
      .filter((plant) => {
        if (filtersCharacteristics) {
          const filtersMatchingChar = filtersCharacteristics.activeValues.map((filter) => {
            const plantCharValue = plant['SPEC_CHAR_FULL'];
            if (plantCharValue) {
              return plantCharValue.toLowerCase().includes(filter.toLowerCase());
            } else {
              return false;
            }
          });

          return filtersMatchingChar.every(Boolean);
        }

        return true;
      });

    let plants;
    const uniquePlantResults = _uniqBy(plantsAttributes, (plant) => `${plant.NAME} - ${plant.COMMON_NAME_PRIMARY}`);

    if (filtersRegular?.length > 0 || filtersCharacteristics?.length > 0) {
      plants = _sortBy(uniquePlantResults, (plant) => `${plant.NAME} - ${plant.COMMON_NAME_PRIMARY}`);
    } else if (searchField === 'Advanced' && searchFieldAdvanced === FIELD_COMMON_NAME_ESRI) {
      plants = _sortBy(uniquePlantResults, [
        (plant) => (plant[FIELD_COMMON_NAME_ESRI] ? plant[FIELD_COMMON_NAME_ESRI].toLowerCase() : plant),
        (plant) => (plant.COMMON_NAME_PRIMARY ? plant.COMMON_NAME_PRIMARY.toLowerCase() : plant),
        'NAME',
      ]);
    } else {
      plants = _sortBy(uniquePlantResults, sortByAttribute);
    }

    const rows = _chunk(plants, numberOfColumns);
    setRows(rows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    results,
    searchField,
    numberOfColumns,
    filterDefinitionExpression,
    allLivingRecordsCheckbox,
    definitionExpressionQueryAttributes,
  ]);

  const rowRenderer = ({ index, isScrolling, key, style }) => {
    const width = parseInt(100 / numberOfColumns);

    return (
      <div key={index} style={{ display: 'flex', ...style }} data-virtual-row-style={index % 2 ? 'even' : 'odd'}>
        {rows[index].map((plant) => {
          return (
            <PlantResult
              key={plant.OBJECTID}
              plant={plant}
              width={width}
              searchFieldAdvanced={searchFieldAdvanced}
              searchField={searchField}
            />
          );
        })}
      </div>
    );
  };

  const updateColumns = (width) => {
    const columns = getNumberOfResultColumns(width);
    if (numberOfColumns !== columns) setNumberOfColumns(columns);
  };

  return (
    <div
      id='SearchPlantResults'
      data-search-type={searchField}
      data-advanced-search-field={searchFieldAdvanced}
      data-advanced-search-alphabet={SEARCH_PLANT_ALPHABET_SECTIONS.includes(searchFieldAdvanced)}
      data-bloom-results={isBloomResults}
    >
      <div id='PlantSearchResultsVirtualized__container' style={{ width: '100%' }}>
        <AutoSizer>
          {({ height, width }) => {
            updateColumns(width);
            return (
              <List
                ref='List'
                className={'list'}
                width={width}
                height={height}
                overscanRowCount={10}
                noRowsRenderer={
                  rows.length === 0 && searchTerm?.length > 0 && !isAdvancedSearchField
                    ? PlantSearchResultsNoResults
                    : PlantSearchFindPlantInGarden
                }
                rowCount={rows.length}
                rowHeight={AUTOSIZER__ROW_HEIGHT}
                rowRenderer={rowRenderer}
              />
            );
          }}
        </AutoSizer>
      </div>
    </div>
  );
};

export default PlantSearchResults;
