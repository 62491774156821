import React from 'react';

const PhenologyChartLegendFruiting = () => (
  <React.Fragment>
    <span className='phenology-chart__legend-fruiting--title'>Fruiting</span>
    <span className='phenology-chart__legend-fruiting--color' />
  </React.Fragment>
);

export default PhenologyChartLegendFruiting;
