import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _uniq from 'lodash/uniq';
import _concat from 'lodash/concat';
import _pullAll from 'lodash/pullAll';

import mapController from '../../controllers/MapController';

import { RootState } from '../store';
import { adaConfig, pointsConfig } from '../../config/mapLayers';

export interface LayerState {
  layersVisible: boolean;
  activeLayerDropdown: string;
  layersConfig: Array<any>;
  activeLayerToggles: Array<any>;
  adaSublayersTitles: Array<string>;
  currentMapViewScale: number;
}

const initialState: LayerState = {
  layersVisible: false,
  activeLayerDropdown: '',
  layersConfig: [...pointsConfig, ...adaConfig],
  activeLayerToggles: ['art', 'plantData'],
  adaSublayersTitles: [],
  currentMapViewScale: 0,
};

export const layerSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setLayersVisible: (state: LayerState, action: PayloadAction<LayerState['layersVisible']>) => {
      state.layersVisible = action.payload;
    },
    setLayersConfig: (state: LayerState, action: PayloadAction<LayerState['layersConfig']>) => {
      state.layersConfig = action.payload;
    },
    setActiveLayersDropdown: (state: LayerState, action: PayloadAction<LayerState['activeLayerDropdown']>) => {
      state.activeLayerDropdown = state.activeLayerDropdown === action.payload ? '' : action.payload;
    },
    setActiveLayers: (state: LayerState, action: any) => {
      const layerID = action.payload;
      const allToggles = [...state.activeLayerToggles];
      let allIDs;

      if (allToggles.includes(layerID)) {
        const index = allToggles.indexOf(layerID);
        allToggles.splice(index, 1);
      } else {
        allToggles.push(layerID);
      }

      if (state.adaSublayersTitles.includes(layerID)) {
        const toggles = [...state.activeLayerToggles];
        mapController.toggleADASublayers(layerID, toggles, state.adaSublayersTitles);
      } else {
        switch (layerID) {
          case 'plantData':
            mapController.togglePlantData();
            break;
          case 'art':
            mapController.toggleArtBasemap();
            break;
          case 'locationCodes':
            mapController.toggleLocationCodes();
            break;
          default:
            const allToggles = [...state.activeLayerToggles];
            allIDs = allToggles.includes(layerID)
              ? allToggles.filter((id) => layerID !== id)
              : [...allToggles, layerID];

            mapController.togglePointsOfInterest(allIDs);
            break;
        }
      }

      state.activeLayerToggles = allToggles;
    },
    setToggleAllLayers: (state: LayerState, action: PayloadAction<LayerState['activeLayerToggles']>) => {
      state.activeLayerToggles = action.payload;

      if (action.payload.length) {
        mapController.togglePointsOfInterest([...state.activeLayerToggles]);
        mapController.toggleAllMapLayers(true);
      } else {
        mapController.togglePointsOfInterest([...state.activeLayerToggles]);
        mapController.toggleAllMapLayers(false);
      }
    },
    setAllOptionalLayersVisibility: (state: LayerState, action: any) => {
      if (action.payload.visible) {
        state.activeLayerToggles = _uniq(_concat(state.activeLayerToggles, action.payload.layers));
      } else {
        state.activeLayerToggles = _pullAll(state.activeLayerToggles, action.payload.layers);
      }

      if (action.payload.id !== 'Points of Interest') {
        mapController.toggleAllADASublayers(action.payload.visible);
      } else {
        mapController.togglePointsOfInterest(state.activeLayerToggles);
      }
    },
    setAdaSublayersTitles: (state: LayerState, action: PayloadAction<LayerState['adaSublayersTitles']>) => {
      state.adaSublayersTitles = action.payload;
    },
    setCurrentMapViewScale: (state: LayerState, action: PayloadAction<LayerState['currentMapViewScale']>) => {
      state.currentMapViewScale = action.payload;
    },
  },
});

// ACTIONS
export const {
  setToggleAllLayers,
  setLayersVisible,
  setActiveLayers,
  setActiveLayersDropdown,
  setLayersConfig,
  setAllOptionalLayersVisibility,
  setAdaSublayersTitles,
  setCurrentMapViewScale,
} = layerSlice.actions;

// SELECTORS
export const layersVisible = (state: RootState) => state.layerReducer.layersVisible;
export const activeLayerDropdown = (state: RootState) => state.layerReducer.activeLayerDropdown;
export const layersConfig = (state: RootState) => state.layerReducer.layersConfig;
export const activeLayerToggles = (state: RootState) => state.layerReducer.activeLayerToggles;
export const $adaSublayersTitles = (state: RootState) => state.layerReducer.adaSublayersTitles;
export const $currentMapViewScale = (state: RootState) => state.layerReducer.currentMapViewScale;

export default layerSlice.reducer;
