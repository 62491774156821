import React from 'react';

import ShareWidget from '../share/ShareWidget';
import HeaderLogoSection from './HeaderLogoSection';
import HeaderMenu from './HeaderMenu';

import '../../styles/css/header.scss';
import { SEO } from '../seo/SEO';

function Header() {
  return (
    <header id='header'>
      <SEO />
      <div className='header-container'>
        <HeaderLogoSection />

        <ShareWidget />

        <HeaderMenu />
      </div>
    </header>
  );
}

export default Header;
