import React from 'react';

import './ScientificNameFormated.scss';

export default function ScientificNameFormatted({ plantName }: { plantName: string }) {
  // Match name between single quotes
  const regexMatch = plantName.match(/'(.*)'/);

  if (regexMatch) {
    const matchedName = regexMatch[0];
    const [plantNameFirst, plantNameLast] = plantName.split(matchedName);

    return (
      <>
        <i>{plantNameFirst}</i>
        <span className='font-style--normal'>{matchedName}</span>
        {plantNameLast}
      </>
    );
  } else {
    return <i>{plantName}</i>;
  }
}

export const scientificNameFormatted = (plantName: string) => {
  // Match name between single quotes
  const regexMatch = plantName.match(/'(.*)'/);

  if (regexMatch) {
    const matchedName = regexMatch[0];
    const [plantNameFirst, plantNameLast] = plantName.split(matchedName);

    return `${plantNameFirst}<span class='font-style--normal'>${matchedName}</span>${plantNameLast}`;
  } else {
    return plantName;
  }
};
