import * as React from 'react';
import { SVG_CONFIG } from '../../config/config';

function IconBurgerMenu({ fill }: { fill?: string }) {
  return (
    <svg width={35} height={35} viewBox='0 0 35 35'>
      <g fill={SVG_CONFIG.fill} fillRule='evenodd'>
        <path d='M5 9h25v2H5zM5 17h25v2H5zM5 25h25v2H5z' />
      </g>
    </svg>
  );
}

export default IconBurgerMenu;
