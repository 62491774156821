export const LOCATIONS_SEARCH_LABEL: string = 'Garden Location';
export const LOCATIONS_ESRI_FIELD: string = 'LOCATION_GROUP_NOW_FULL';
export const LOCATIONS_GROUP_FIELD: string = 'LOCATION_GROUP_NOW';
export const LOCATIONS_GARDEN_LOCATION: string = 'GardenLocation';
export const LOCATIONS_GARDEN_CODE: string = 'GardenCode';
export const LOCATIONS_GARDEN_WEBSITE: string = 'GardenWebsite';
export const LOCATIONS_GARDEN_XY = ['X', 'Y'];

export const FIELD_FAMILY_AND_FAMILY_COMMON = 'FAMILY_AND_FAMILY_COMMON';
export const FIELD_GENUS_AND_GENUS_COMMON = 'GENUS_AND_GENUS_COMMON';
export const FIELD_NAME = 'NAME';
export const FIELD_COMMON_NAME_ESRI = 'GENUS_COMMON_NAME_PRIMARY';

export const SEARCH_OPTIONS_FIELD_PLANTS = 'Plants';
export const SEARCH_OPTIONS_FIELD_ADVANCED = 'Advanced';
export const SEARCH_OPTIONS_FIELD_PHENOLOGY = "What's In Bloom?";
export const SEARCH_OPTIONS = [
  LOCATIONS_SEARCH_LABEL,
  SEARCH_OPTIONS_FIELD_PLANTS,
  SEARCH_OPTIONS_FIELD_ADVANCED,
  SEARCH_OPTIONS_FIELD_PHENOLOGY,
];
export const SEARCH_OPTIONS_ADVANCED = [
  { label: 'Family', esriField: FIELD_FAMILY_AND_FAMILY_COMMON, displayField: 'FAMILY' },
  { label: 'Genus', esriField: FIELD_GENUS_AND_GENUS_COMMON, displayField: 'GENUS' },
  { label: 'Scientific', esriField: FIELD_NAME, displayField: 'NAME' },
  { label: 'Common', esriField: FIELD_COMMON_NAME_ESRI, displayField: 'COMMON_NAME_PRIMARY' },
  { label: 'Accession And Qualifier', esriField: 'ACC_NUM_AND_QUAL', displayField: 'ACC_NUM_AND_QUAL' },
];

export const SEARCH_PLANT_ALPHABET_EXCLUDE_SECTIONS = [FIELD_FAMILY_AND_FAMILY_COMMON, FIELD_GENUS_AND_GENUS_COMMON];
export const SEARCH_PLANT_ALPHABET_SECTIONS = [FIELD_NAME, FIELD_COMMON_NAME_ESRI];
export const SEARCH_PLANT_ALPHABET_SECTIONS_DISABLE_SEARCH_PLACEHOLDER = 'Browse Plants from List';
export const SEARCH_PLANT_DISABLE_LOCATION_PLACEHOLDER = 'Browse Locations from List';

export const alphabet = [
  "'",
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const AUTOSIZER__COLUMN_WIDTH = 400;
export const AUTOSIZER__ROW_HEIGHT = 70;
export const AUTOSIZER_GARDEN_LOCATION__ROW_HEIGHT = 70;

// Plants URL path query
export const URL__SEARCH_VALUE = 'value';
export const URL__SEARCH_TYPE = 'field';
export const URL__SEARCH_TYPE_ADVANCED = 'fieldAdvanced';

// Tour URL path query
export const URL__TOUR_ID = 'tourID';
export const URL__TOUR_STOP = 'tourStop';
export const URL__TOUR_NAME = 'tourName';
