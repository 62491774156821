import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import _intersection from 'lodash/intersection';

import {
  $adaSublayersTitles,
  $currentMapViewScale,
  activeLayerToggles,
  setActiveLayers,
  setActiveLayersDropdown,
  setAllOptionalLayersVisibility,
  setLayersConfig,
} from '../../store/app/layerSlice';
import { setGALayerEvent } from '../../utils/googleAnalytics';

import { ADA_URL, INTERACTIVE_FEATURE_LAYER, visibilityScale } from '../../config/config';

import { UniqueValueInfo } from '../../interfaces/layersWidget';
import {
  ACCESSIBLE_ROUTES_PATHS,
  adaConfig,
  mapLayerToggles,
  pointsConfig,
  POINTS_OF_INTERENST,
} from '../../config/mapLayers';

import { RootState } from '../../store/store';

import '../../styles/css/filterPlants.scss';
import '../../styles/css/layers.scss';
import { PlantDataLegend } from '../legend/PlantDataLegend';
import { fetchLegendData, getImageSrc } from '../../utils/legend.util';
import { LayersDisabled } from '../legend/LayersDisabled';
import GreenSwitch from '../common/GreenSwitch/GreenSwitch';

export function FilterLayers() {
  const dispatch = useDispatch();
  const activeDropdown = useSelector((store: RootState) => store.layerReducer.activeLayerDropdown);
  const mapLayerOptions = useSelector((store: RootState) => store.layerReducer.layersConfig);
  const mapActiveLayerToggles = useSelector(activeLayerToggles);
  const adaSublayersTitles = useSelector($adaSublayersTitles);
  const currentMapViewScale = useSelector($currentMapViewScale);
  const [allToggleOptions, setAllToggles] = useState<Array<string>>(['art', 'ada', 'plantData', 'toggle-all']);

  useEffect(() => {
    getLayerOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adaSublayersTitles]);

  const getPointsOfInterest = async () => {
    const url = `${INTERACTIVE_FEATURE_LAYER.url}/f?f=pjson`;

    return fetch(url)
      .then((res) => res.json())
      .then((results) => {
        if (results) {
          const uniqueValueInfos = results.drawingInfo.renderer.uniqueValueInfos.filter(
            (uniqueValueInfo: any) => uniqueValueInfo.label !== 'Other'
          );

          const labels = uniqueValueInfos.map((value: UniqueValueInfo) => value.label);
          const allTogglesOptionsCopy = [...allToggleOptions, ...labels];
          setAllToggles(allTogglesOptionsCopy);

          return uniqueValueInfos.map((value: UniqueValueInfo) => {
            return {
              label: value.label,
              id: value.label,
              imageSource: `data:${value.symbol.contentType};base64,${value.symbol.imageData}`,
              imageAlt: value.label,
            };
          });
        }
      })
      .catch((e) => console.log('error in getPointsOfInterest()', e));
  };

  const getAndSetPointsOfInterest = async () => {
    const newLayers = pointsConfig.map((layer: any) => {
      const layerCopy = { ...layer };
      const optionsCopy = layer.options.map((option: any) => {
        return {
          ...option,
        };
      });

      return {
        label: layerCopy.label,
        options: optionsCopy,
      };
    });

    const pointsOfInterest = newLayers.find((layer: any) => layer.label === POINTS_OF_INTERENST);

    if (!pointsOfInterest) {
      return;
    }

    try {
      const points = await getPointsOfInterest();
      pointsOfInterest.options.push(...points);

      return newLayers;
    } catch {
      console.log('error in getAndSetPointsOfInterest()');
    }
  };

  const getAndSetAccessibilityRoutes = async () => {
    const newLayers = adaConfig.map((layer: any) => {
      const layerCopy = { ...layer };
      const optionsCopy = layer.options.map((option: any) => {
        return {
          ...option,
        };
      });

      return {
        label: layerCopy.label,
        options: optionsCopy,
      };
    });

    const accessibleRoutes = newLayers.find((layer: any) => layer.label === ACCESSIBLE_ROUTES_PATHS);

    if (!accessibleRoutes) {
      return;
    }

    try {
      const accessibilityOptions = adaSublayersTitles.map((option: string) => {
        return {
          id: option,
          imageAlt: '',
          imageSource: '',
          label: option,
        };
      });
      const allTogglesOptionsCopy = [...allToggleOptions, ...adaSublayersTitles];

      setAllToggles(allTogglesOptionsCopy);
      accessibleRoutes.options.push(...accessibilityOptions);
      return newLayers;
    } catch {
      console.log('error in getAndSetAccessibilityRoutes()');
    }
  };

  const getA11yLayers = (adaLegend: any, accessPoints?: { options: any; label: any }[]) => {
    const accessPointOptions: any = adaLegend?.layers.map((item: any) => {
      const legendElement = item.legend[0];
      return {
        id: item.layerName,
        label: item.layerName,
        imageAlt: item.layerName,
        imageSource: getImageSrc(legendElement),
        width: legendElement.width,
        height: legendElement.height,
      };
    });

    const [first] = accessPoints;
    return accessPointOptions
      ? [
          {
            label: first.label,
            options: accessPointOptions,
          },
        ]
      : accessPoints;
  };

  const getLayerOptions = async () => {
    const pointsOfInterest = await getAndSetPointsOfInterest();
    const adaLegend = await fetchLegendData(ADA_URL);
    const accessPoints = await getAndSetAccessibilityRoutes();
    const accessibilityLayers = getA11yLayers(adaLegend, accessPoints);

    const layersConfig = [...pointsOfInterest, ...accessibilityLayers];
    dispatch(setLayersConfig(layersConfig));
  };

  return (
    <>
      <div className='filter-container map-layers'>
        <div className='sub-header layers'>
          <span>Map Layers</span>
        </div>
        {mapLayerToggles.map(({ label, id }, index: number) => {
          return (
            <div key={index}>
              <div
                className='option-button layers'
                onClick={() => {
                  // setGALayerEvent({ selectedLayer: id, allActiveLayers: mapActiveLayerToggles });
                  dispatch(setActiveLayers(id));
                }}
              >
                <div className='label-wrapper'>
                  <span className='label'>{label}</span>
                </div>
                <FormControlLabel
                  control={<GreenSwitch checked={mapActiveLayerToggles.includes(id)} />}
                  label={''}
                  className='custom-form-control-label'
                />
              </div>
              {label === 'Plant Data' && <PlantDataLegend />}
            </div>
          );
        })}

        {mapLayerOptions.map(({ label: mainLabel, options }, index: number) => {
          const optionLayers = options.map((item: { id: string }) => item.id);
          const includeAtLeastOneLayer = _intersection(mapActiveLayerToggles, optionLayers).length !== 0;

          return (
            <div key={index} data-legend-layer={mainLabel} className='legend-layer__container'>
              <div className='option-button' onClick={() => dispatch(setActiveLayersDropdown(mainLabel))}>
                <div className='label-wrapper'>
                  <span className='label'>{mainLabel}</span>
                </div>
                <span className={`chevron ${activeDropdown === mainLabel ? 'up' : 'down'}`} />
              </div>

              {activeDropdown === mainLabel && options.length ? (
                <>
                  <div className='dropdown'>
                    {(mainLabel === ACCESSIBLE_ROUTES_PATHS || mainLabel === POINTS_OF_INTERENST) &&
                      currentMapViewScale > visibilityScale && <LayersDisabled />}

                    <div className='filter-layers__toggle-button'>
                      <FormControlLabel
                        label={'Toggle All'}
                        labelPlacement='start'
                        className='custom-form-control-label'
                        control={
                          <GreenSwitch
                            checked={includeAtLeastOneLayer}
                            disabled={currentMapViewScale > visibilityScale}
                            onClick={() => {
                              if (includeAtLeastOneLayer) {
                                dispatch(
                                  setAllOptionalLayersVisibility({
                                    id: mainLabel,
                                    layers: optionLayers,
                                    visible: false,
                                  })
                                );
                              } else {
                                dispatch(
                                  setAllOptionalLayersVisibility({ id: mainLabel, layers: optionLayers, visible: true })
                                );
                              }
                            }}
                          />
                        }
                      />
                    </div>

                    <ul>
                      {options.map(({ id, label, imageSource, imageAlt, width, height }: any, optionsIndex: number) => {
                        const isActive = mapActiveLayerToggles.includes(id);

                        return (
                          <li key={optionsIndex}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              {imageSource && (
                                <img
                                  src={imageSource}
                                  alt={imageAlt}
                                  style={{
                                    width: width && width,
                                    height: height && height,
                                    paddingRight: width && height && '0.5rem',
                                  }}
                                />
                              )}
                              <label>{label}</label>
                            </div>
                            <FormControlLabel
                              control={
                                <GreenSwitch
                                  disabled={currentMapViewScale > visibilityScale}
                                  checked={isActive}
                                  onClick={() => {
                                    // setGALayerEvent({ selectedLayer: id, allActiveLayers: mapActiveLayerToggles });
                                    dispatch(setActiveLayers(id));
                                  }}
                                />
                              }
                              label={''}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </>
              ) : null}
            </div>
          );
        })}
      </div>
    </>
  );
}

export default FilterLayers;
