import React from 'react';

import './PlantNotAlive.scss';

const PlantNotAlive = () => (
  <div className='plant-not-alive'>
    <p className='plant-not-alive__description'>There are no living specimens in the collection</p>
  </div>
);

export default PlantNotAlive;
