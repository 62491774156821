import * as React from 'react';
import { COLOR_PRIMARY } from '../../config/config';

function IconLocator({
  fill = COLOR_PRIMARY,
  width = 35,
  height = 32,
}: {
  fill?: string;
  width?: number;
  height?: number;
}) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width={width} height={height}>
      <path d='M0 0h24v24H0z' fill='none' />
      <path
        fill={fill}
        d='M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z'
      />
    </svg>
  );
}

export default IconLocator;
