import React from 'react';

import ShareWidget from '../../share/ShareWidget';
import SpecificPlantFavorPlant from '../SpecificPlantFavorPlant/SpecificPlantFavorPlant';
import WidgetImage from '../WidgetImage/WidgetImage';

import { PlantData } from '../../../interfaces/plantData';
import ScientificNameFormatted from '../searchResults/PlantResultScientificName/ScientificNameFormatted';

import './SpecificPlantTopDetails.scss';

export default function SpecificPlantTopDetails({
  plant,
  favorite,
  removeFavoritePlant,
  addFavoritePlant,
}: {
  plant: PlantData;
  favorite: boolean;
  removeFavoritePlant: () => void;
  addFavoritePlant: () => void;
}) {
  return (
    <div className='SpecificPlantTopDetails'>
      <div className='main'>
        <div className='main-details'>
          <h1 className='subtitle'>
            <ScientificNameFormatted plantName={plant?.NAME} />
          </h1>
          <div className='grid section-one'>
            {plant?.FAMILY && (
              <>
                <p className='plant-line'>
                  <strong>Family:</strong>
                </p>
                <p className='plant-line green-color'>{plant?.FAMILY}</p>
              </>
            )}
            {plant?.RANGE && (
              <>
                <p className='plant-line'>
                  <strong>Range:</strong>
                </p>
                <p className='plant-line'> {plant?.RANGE}</p>
              </>
            )}
            {plant?.HABIT_FULL && (
              <>
                <p className='plant-line'>
                  <strong>Habit:</strong>
                </p>
                <p className='plant-line green-color'> {plant?.HABIT_FULL}</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
