import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { $cardMinimized, $legendExpanded, setLegendExpanded, setMinimized } from '../../store/app/appSlice';
import FilterLayers from './FilterLayers';

import '../../styles/css/legend.scss';

const Legend = () => {
  const dispatch = useDispatch();
  const cardMinimized = useSelector($cardMinimized);
  const legendExpanded = useSelector($legendExpanded);

  return (
    <>
      <button
        className={`legend-button`}
        onClick={() => {
          dispatch(setLegendExpanded(!legendExpanded));
          if (!cardMinimized && !legendExpanded) {
            dispatch(setMinimized(true));
          }
        }}
      >
        <span className={`button-icon ${legendExpanded ? 'esri-icon-close' : 'burger-icon'}`} />
        <span className={`button-label legend__button-label`}>Map Layers</span>
      </button>

      <div id='legend-dropdown-container' className={`legend-dropdown-container ${legendExpanded ? 'show' : 'hide'}`}>
        <FilterLayers />
      </div>
    </>
  );
};

export default Legend;
