import _uniq from 'lodash/uniq';

import store from '../store/store';
import { setApiPlantImages } from '../store/app/appSlice';
import { DisplayAttribute, PlantData } from '../interfaces/plantData';
import { handleMultipleImages } from './images.util';

/**
 * Get result key
 *
 * @param {string} searchType
 * @param {string} esriField
 */
export const getResultKey = (searchType: string, esriField?: string) => {
  return searchType === 'Advanced' ? `Advanced__${esriField}` : searchType;
};

/**
 * Get Plant Images. Call Asset Bank API to get plant images links
 *
 * @param {number | []} NAME_NUM - Plant name number
 */
export async function getPlantImages(NAME_NUM?: number | string[]) {
  const { apiPlantImages } = store.getState().appReducer;

  try {
    const IMAGE_API =
      process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_ASSET_BANK_URL_PROD
        : process.env.REACT_APP_ASSET_BANK_URL_DEV;
    const apiURL__getPlantImages = `${IMAGE_API}/api/getPlantImages?NAME_NUMS=${NAME_NUM}`;

    const updatedApiPlantImagesState = await fetch(apiURL__getPlantImages)
      .then((res) => res.json())
      .then(({ data, NAME_NUMS }) => {
        const listOfNameNums =
          typeof NAME_NUM === 'number'
            ? [parseInt(NAME_NUMS)]
            : NAME_NUMS.split(',').map((item: string) => parseInt(item));

        return {
          NAME_NUMS: _uniq([...listOfNameNums, ...apiPlantImages.NAME_NUMS]).sort((a, b) => a - b),
          images: {
            ...apiPlantImages.images,
            ...handleMultipleImages(data, listOfNameNums),
          },
        };
      });

    store.dispatch(setApiPlantImages(updatedApiPlantImagesState));

    return updatedApiPlantImagesState;
  } catch (e) {
    console.log('getPlantImages()', e);
  }
}

/**
 * Check if Plant image is Title image
 *
 * @param {DisplayAttribute} displayAttribute
 * @return {boolean} - TRUE if image is Title/Cover image
 */
export const isPlantImageTitleImage = (displayAttribute: DisplayAttribute) => {
  return displayAttribute.label === 'Keywords' && displayAttribute.value.toLowerCase().includes('titleimage');
};

/**
 * Get Clean CATALOG_DESC
 *
 * @param {string} CATALOG_DESC - Plant catalog description
 * @return {string} - Clean string
 */
export const getClean__CATALOG_DESC = (CATALOG_DESC: string) => {
  return CATALOG_DESC.replace(/û/g, '');
};
