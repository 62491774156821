import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import mapController from '../../controllers/MapController';

import { setMinimized, setCardContent } from '../../store/app/appSlice';

import { RootState } from '../../store/store';

import { BASE_URL } from '../../config/config';
import { getPath } from '../../utils/path.util';

const SpecificFamily = () => {
  const dispatch = useDispatch();
  const { value: family } = useParams();
  const cardMinimized = useSelector((state: RootState) => state.appReducer.cardMinimized);

  const [genera, setGenera] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const getAndSetResults = async () => {
    const generaResults: Array<string> = await mapController.queryGeneraByFamily(family);
    setGenera(generaResults);
    setLoading(false);
  };

  useEffect(() => {
    if (family) {
      dispatch(setMinimized(false));
      getAndSetResults();
    } else {
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [family]);

  return (
    <div className='plants-container index'>
      <div className='plants-card'>
        <div className='sub-header' onClick={() => dispatch(setMinimized(!cardMinimized))}>
          <Link
            to={`${BASE_URL}/map/search?field=family`}
            onClick={() => {
              dispatch(setMinimized(true));
              dispatch(setCardContent(''));
            }}
          >
            Return To Map
          </Link>
          <h4>{family}</h4>
          <span className={`chevron ${cardMinimized ? 'down' : 'up'}`} />
        </div>
        <div className='plant-names-wrapper'>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ul>
              {genera.map((name: string, key: number) => {
                return (
                  <Link to={getPath('genus', name)} key={key}>
                    <li>{name}</li>
                  </Link>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default SpecificFamily;
