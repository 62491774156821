import React, { useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useDispatch, useSelector } from 'react-redux';

// Store
import { $allLivingRecordsCheckbox, $searchType, setAllLivingRecordsCheckbox } from '../../../store/app/appSlice';
import { $searchDefinitionExpression, setSearchDefinitionExpression } from '../../../store/app/filterSlice';

// Config
import { LOCATIONS_SEARCH_LABEL } from '../../../config/plant.config';

// Style
import './SearchPlantRecordsFilter.scss';
import GreenSwitch from '../../common/GreenSwitch/GreenSwitch';

export default function SearchPlantRecordsFilter() {
  const dispatch = useDispatch();
  const searchType = useSelector($searchType);
  const allLivingRecordsCheckbox = useSelector($allLivingRecordsCheckbox);
  const searchDefinitionExpression = useSelector($searchDefinitionExpression);

  useEffect(() => {
    if (searchType !== LOCATIONS_SEARCH_LABEL) {
      let definitionExpression = searchDefinitionExpression;
      const aliveDefinitionExpression = "ALIVE = 'A'";
      const aliveAndDefinitionExpression = ` AND ${aliveDefinitionExpression}`;
      const isAlive = definitionExpression.length ? aliveAndDefinitionExpression : aliveDefinitionExpression;

      if (allLivingRecordsCheckbox) {
        if (definitionExpression !== aliveDefinitionExpression) {
          definitionExpression += isAlive;
        }
      } else {
        definitionExpression = definitionExpression.replace(aliveAndDefinitionExpression, '');
        definitionExpression = definitionExpression.replace(aliveDefinitionExpression, '');
      }

      dispatch(setSearchDefinitionExpression(definitionExpression));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allLivingRecordsCheckbox]);

  return (
    <div id='SearchPlantRecordsFilter'>
      <div
        className='all-living-records'
        onClick={() => dispatch(setAllLivingRecordsCheckbox(!allLivingRecordsCheckbox))}
      >
        <span className='MuiTypography-root MuiFormControlLabel-label MuiTypography-body1 all-records'>
          All Records
        </span>
        <FormControlLabel control={<GreenSwitch checked={allLivingRecordsCheckbox} />} label={'Living Records Only'} />
      </div>
    </div>
  );
}
