import React from 'react';
import { Link } from 'react-router-dom';

import { BASE_URL } from '../../config/config';
import { PageMainTitle } from '../page/PageMainTitle';

import './home.scss';

const CONTENT_RIGHT_ALIGNED = false;

function Home() {
  return (
    <div id='Home'>
      <div id='HomeFilterLayer'>
        <main className='page-main'>
          <div className='page-main__content' data-content-right-aligned={CONTENT_RIGHT_ALIGNED}>
            <PageMainTitle title='Welcome to NYBG  Plant Tracker' />
            <p className='home__description'>
              Plant Tracker is the online catalog of the Garden’s living collections. Use it to find plants and navigate
              your way around the 250-acre landscape, get plant information, view photographs, and take curated tours.
            </p>
            <div className='home__buttons'>
              <button id='LaunchPlantTracker'>
                <Link className='LaunchPlantTracker__link' to={`${BASE_URL}/map`}>
                  Launch Plant Tracker
                </Link>
              </button>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Home;
