import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ImageGallery from 'react-image-gallery';

import { $apiPlantImages, $selectedPlant } from '../../../store/app/appSlice';
import { getPlantImages } from '../../../utils/plant.util';

import { PlantGalleryImage } from '../../../interfaces/plantData';

import './SpecificPlantGallery.scss';

function handleGalleryImages({ cover, items }: any) {
  const plantGalleryImages: PlantGalleryImage[] = [];

  plantGalleryImages.push({ original: cover.previewUrl, thumbnail: cover.thumbnailUrl, originalAlt: '0' });

  items.forEach((item: { id: number; previewUrl: string; thumbnailUrl: string }, index: number) => {
    if (item.id !== cover.id) {
      plantGalleryImages.push({ original: item.previewUrl, thumbnail: item.thumbnailUrl, originalAlt: `${index + 1}` });
    }
  });

  return plantGalleryImages;
}

export default function SpecificPlantGallery() {
  const { images, NAME_NUMS } = useSelector($apiPlantImages);
  const { NAME_NUM } = useSelector($selectedPlant);
  const [plantGalleryImages, setPlantGalleryImages] = useState<PlantGalleryImage[]>([]);

  useEffect(() => {
    if (NAME_NUM && !NAME_NUMS.includes(NAME_NUM!)) {
      getPlantImages(NAME_NUM).then((plantImagesData: any) => {
        const plantImages = plantImagesData?.images[NAME_NUM!];
        if (plantImages && plantImages['cover']) {
          setPlantGalleryImages(handleGalleryImages(plantImages));
        }
      });
    } else {
      const plantImages = images[NAME_NUM!];
      if (plantImages && plantImages['cover']) {
        setPlantGalleryImages(handleGalleryImages(plantImages));
      }
    }
  }, [NAME_NUM]);

  return (
    <div className='SpecificPlantGallery'>
      {plantGalleryImages && plantGalleryImages.length > 0 ? (
        <ImageGallery items={plantGalleryImages} showPlayButton={false} showFullscreenButton={false} />
      ) : (
        <div className='gallery-placeholder'>
          <p className='gallery-placeholder__description'>IMAGE COMING SOON</p>
        </div>
      )}
    </div>
  );
}
