import React from 'react';

import PlantResultCommonName from './PlantResultCommonName/PlantResultCommonName';
import PlantResultCommonNameItem from './PlantResultCommonNameItem/PlantResultCommonNameItem';
import PlantResultScientificName from './PlantResultScientificName/PlantResultScientificName';
import { FIELD_COMMON_NAME_ESRI } from '../../../config/plant.config';

import IconPlantCheckmark from '../../icons/IconPlantCheckmark';
import IconPlantClose from '../../icons/IconPlantClose';
// import IconPlantMinus from '../../icons/IconPlanMinus';

const PlantResult = (props: any) => {
  const { plant, width, searchFieldAdvanced, searchField } = props;
  const { ALIVE, NAME, OBJECTID } = plant;

  if (searchField === 'Advanced' && searchFieldAdvanced === FIELD_COMMON_NAME_ESRI) {
    return <PlantResultCommonNameItem key={OBJECTID} width={width} plant={plant} />;
  } else {
    return (
      <div key={OBJECTID} style={{ width: `${width}%` }} className='plant-result-container'>
        <div className='species-commonname'>
          {ALIVE === 'A' && <IconPlantCheckmark />}
          {ALIVE === null && <IconPlantClose />}
          <div className='PlantResultCommonNameItem__label'>
            <PlantResultScientificName NAME={NAME} />
            <PlantResultCommonName searchField={searchField} searchFieldAdvanced={searchFieldAdvanced} plant={plant} />
          </div>
        </div>
      </div>
    );
  }
};

export default PlantResult;
