import React from 'react';

const IconHeartRed = ({ width = 35, height = 35 }) => {
  return (
    <svg viewBox='0 0 35 35' fill='none' width={width} height={height}>
      <title>Red heart</title>
      <path
        fill='#D14444'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.533 9.644c-2.386-2.192-6.254-2.192-8.64 0l-1.62 1.488-1.62-1.488c-2.386-2.192-6.254-2.192-8.64 0-2.684 2.466-2.684 6.464 0 8.93L17.273 28l10.26-9.426c2.683-2.466 2.683-6.464 0-8.93z'
      />
    </svg>
  );
};

export default IconHeartRed;
