import React from 'react';

const IconInstagram = () => {
  return (
    <svg width='34px' height='33px' viewBox='0 0 34 33' version='1.1'>
      <title>Instagram</title>
      <g id='Homepage-Mobile_v2' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Mobile-Social_Share' transform='translate(-170.000000, -500.000000)'>
          <g id='Group-89' transform='translate(33.000000, 469.000000)'>
            <g id='Group-47' transform='translate(40.000000, 31.000000)'>
              <g id='IG' transform='translate(97.951816, -0.000000)'>
                <path
                  d='M3.55271368e-14,16.2828526 C3.55271368e-14,25.2760756 7.29935579,32.5657052 16.3038193,32.5657052 C25.3086226,32.5657052 32.6076387,25.2760756 32.6076387,16.2828526 C32.6076387,7.28996881 25.3086226,4.8316906e-13 16.3038193,4.8316906e-13 C7.29935579,4.8316906e-13 3.55271368e-14,7.28996881 3.55271368e-14,16.2828526 Z'
                  fill='#BC5387'
                ></path>
                <path
                  d='M11.3481838,10 C10.6331838,10 10.0481838,10.585 10.0481838,11.3 L10.0481838,21.7 C10.0481838,22.415 10.6331838,23 11.3481838,23 L21.7481838,23 C22.4631838,23 23.0481838,22.415 23.0481838,21.7 L23.0481838,11.3 C23.0481838,10.585 22.4631838,10 21.7481838,10 L11.3481838,10 Z M16.5481838,13.9 C17.9781838,13.9 19.1481838,15.07 19.1481838,16.5 C19.1481838,17.93 17.9781838,19.1 16.5481838,19.1 C15.1181838,19.1 13.9481838,17.93 13.9481838,16.5 C13.9481838,15.07 15.1181838,13.9 16.5481838,13.9 L16.5481838,13.9 Z M11.6731838,21.7 C11.4781838,21.7 11.3481838,21.57 11.3481838,21.375 L11.3481838,15.85 L12.7131838,15.85 C12.6481838,16.045 12.6481838,16.305 12.6481838,16.5 C12.6481838,18.645 14.4031838,20.4 16.5481838,20.4 C18.6931838,20.4 20.4481838,18.645 20.4481838,16.5 C20.4481838,16.305 20.4481838,16.045 20.3831838,15.85 L21.7481838,15.85 L21.7481838,21.375 C21.7481838,21.57 21.6181838,21.7 21.4231838,21.7 L11.6731838,21.7 L11.6731838,21.7 Z M21.7481838,12.925 C21.7481838,13.12 21.6181838,13.25 21.4231838,13.25 L20.1231838,13.25 C19.9281838,13.25 19.7981838,13.12 19.7981838,12.925 L19.7981838,11.625 C19.7981838,11.43 19.9281838,11.3 20.1231838,11.3 L21.4231838,11.3 C21.6181838,11.3 21.7481838,11.43 21.7481838,11.625 L21.7481838,12.925 L21.7481838,12.925 Z'
                  id='Shape'
                  fill='#FFFFFF'
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconInstagram;
