import { loadModules } from 'esri-loader';

import PlantMarkerSelected from '../../images/PlantMarkerSelected.svg';

export const highlightSelectedPlant = async (_mapview: __esri.MapView | undefined, event: any) => {
  const [Graphic] = await loadModules(['esri/Graphic']);

  if (event) {
    _mapview?.graphics.removeAll();

    const pictureMarkerSymbol = {
      type: 'picture-marker',
      url: PlantMarkerSelected,
      width: 16,
      height: 16,
    };

    const plantGraphic = new Graphic({
      geometry: event.geometry,
      symbol: pictureMarkerSymbol,
    });

    _mapview?.graphics.add(plantGraphic);
  } else {
    _mapview?.graphics.removeAll();
  }
};
