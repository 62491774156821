import React, { useEffect, useState } from 'react';

import { PLANT_DATA_LAYER_URL } from '../../config/config';
import { fetchLegendData, getImageSrc } from '../../utils/legend.util';

import './PlantDataLegend.scss';

const labelMapping: any = {
  Surveyed: 'Surveyed Plant',
  'Not Surveyed': 'Plants in Garden Area',
};

export function PlantDataLegend() {
  const [plantDataLegendData, setPlantDataLegendData] = useState<any>([]);

  useEffect(() => {
    fetchLegendData(PLANT_DATA_LAYER_URL)
      .then((res) => {
        setPlantDataLegendData(res.layers[0]);
      })
      .catch((e) => {
        console.log('Error fetching Legend Data', e);
      });
  }, []);

  return plantDataLegendData?.legend?.length > 0 ? (
    <div id='PlantDataLegend'>
      {plantDataLegendData?.legend &&
        plantDataLegendData?.legend.map((item: { imageData: string; label: string; contentType: string }) => {
          return (
            <p key={item.label} className='PlantDataLegend__item'>
              <img src={getImageSrc(item)} alt={labelMapping[item.label]} />
              <span className='PlantDataLegend__item-label'>{labelMapping[item.label]}</span>
            </p>
          );
        })}
    </div>
  ) : null;
}
