import _difference from 'lodash/difference';

// REDUX Store
import store from '../store/store';
import {
  setStopsByAccession,
  setTourStep,
  TourSelected,
  setTourStops as reduxSetTourStops,
} from '../store/app/tourSlice';
import { setTourDefinitionExpressionByAccession } from '../store/app/filterSlice';
import { appHistory } from '../store/appHistory';

import mapController from '../controllers/MapController';
import { encodePathValue, MAP_LINK__TOURS } from './path.util';
import { getPlantImages } from './plant.util';

export function resetTours() {
  store.dispatch(setTourStep('ViewTours'));
  store.dispatch(setTourDefinitionExpressionByAccession([]));
  mapController.resetTourStopsGraphicsLayer();
}

export function updateTourPathURL(tourID?: number, tourStop = 1) {
  const { Tour_Name } = store.getState().tourReducer.tourSelected;
  appHistory.push(
    `${MAP_LINK__TOURS}?tourID=${tourID}&tourStop=${tourStop}&tourName=${encodePathValue(Tour_Name || '')}`
  );
}

/**
 * Set Tour Stops
 * @param {Array<string>} accessionIDs - List of Accession IDs
 */
const setTourStops = async (accessionIDs: any) => {
  const { apiPlantImages } = store.getState().appReducer;
  const imagesInRedux = Object.keys(apiPlantImages.images);

  const results = await mapController.getTourStopsPlantData(accessionIDs);
  const tourPlants__NAME_NUM = results.map((result: { attributes: { NAME_NUM: number } }) => {
    return result.attributes.NAME_NUM.toString();
  });

  const nameNumDIFF = _difference(tourPlants__NAME_NUM, imagesInRedux);
  if (nameNumDIFF.length > 0) {
    await getPlantImages(nameNumDIFF);
  }

  mapController.setTourStopsGraphicsLayer(results);
};

/**
 * Start Curated Tour
 * @param {number} selectedTour - Selected Tour ID
 */
export const startCuratedTour = async (selectedTour: number) => {
  const accessionIDs = await mapController.queryAccessionsByTourID(selectedTour);

  if (accessionIDs.length) {
    setTourStops(accessionIDs);
    store.dispatch(setStopsByAccession(accessionIDs));
    store.dispatch(setTourStep('TourStopsOpen'));
  }
};

/**
 * Get Tour Selected
 * @param  {object} toursAll - All tours
 * @param {number} tourID - Tour ID
 * @return {object} - Tour Selected
 */
export function getTourSelected(toursAll: any, tourID: number) {
  return toursAll.find((item: TourSelected) => item.Tour_ID === tourID);
}

/**
 * Get And Set in REDUX Tour Stops
 */
export const getAndSetTourStops = async () => {
  const { stopsByAccession } = store.getState().tourReducer;
  const plantStops = await mapController.queryPlantsByAccession(stopsByAccession);

  if (plantStops) {
    store.dispatch(reduxSetTourStops(plantStops));
  }
};
