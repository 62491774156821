import React from 'react';

const IconGmail = () => {
  return (
    <svg width='34px' height='33px' viewBox='0 0 34 33' version='1.1'>
      <title>Gmail</title>
      <g id='Homepage-Mobile_v2' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Mobile-Social_Share' transform='translate(-219.000000, -500.000000)'>
          <g id='Group-89' transform='translate(33.000000, 469.000000)'>
            <g id='Group-47' transform='translate(40.000000, 31.000000)'>
              <g id='gmail' transform='translate(146.927724, 0.000000)'>
                <circle id='Combined-Shape' fill='#FF0000' cx='16.1999994' cy='16.1999994' r='16.1999994'></circle>
                <path
                  d='M22.4722756,10 L9.67227564,10 C8.79227564,10 8.07227564,10.72 8.07227564,11.6 L8.07227564,21.2 C8.07227564,22.08 8.79227564,22.8 9.67227564,22.8 L22.4722756,22.8 C23.3522756,22.8 24.0722756,22.08 24.0722756,21.2 L24.0722756,11.6 C24.0722756,10.72 23.3522756,10 22.4722756,10 L22.4722756,10 Z M22.4722756,21.2 L20.8722756,21.2 L20.8722756,14.16 L16.0722756,17.2 L11.2722756,14.16 L11.2722756,21.2 L9.67227564,21.2 L9.67227564,11.6 L10.6322756,11.6 L16.0722756,14.96 L21.5122756,11.6 L22.4722756,11.6 L22.4722756,21.2 L22.4722756,21.2 Z'
                  id='Shape'
                  fill='#FFFFFF'
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconGmail;
