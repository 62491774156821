import React from 'react';
import { useDispatch } from 'react-redux';
import IconReset from '../icons/IconReset';

import { resetFilterOptions } from '../../store/app/filterSlice';

const FilterPlantsClear = () => {
  const dispatch = useDispatch();
  return (
    <button className='modal-clear__button' onClick={() => dispatch(resetFilterOptions())}>
      <IconReset />
    </button>
  );
};

export default FilterPlantsClear;
