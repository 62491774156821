import * as React from 'react';

export default function IconPhotoLibrary() {
  return (
    <svg
      className='IconPhotoLibrary'
      width={19}
      height={20}
      viewBox='0 0 19 20'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>Photo Library Icon</title>
      <defs>
        <filter x='-2.8%' y='-2.6%' width='111.1%' height='110.5%' filterUnits='objectBoundingBox' id='prefix__a'>
          <feOffset dx={1} dy={1} in='SourceAlpha' result='shadowOffsetOuter1' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.694875437 0' in='shadowOffsetOuter1' />
        </filter>
        <path
          d='M18 13.818V1.728C18 .777 17.264 0 16.364 0H4.909c-.9 0-1.636.777-1.636 1.727v12.091c0 .95.736 1.727 1.636 1.727h11.455c.9 0 1.636-.777 1.636-1.727zM7.773 9.068l2.045 2.591 2.864-3.886 3.682 5.182H4.909l2.864-3.887zM0 3.455v13.818C0 18.223.736 19 1.636 19h13.091v-1.727H1.637V3.455H0z'
          id='prefix__b'
        />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <use fill='#000' filter='url(#prefix__a)' xlinkHref='#prefix__b' />
        <use fill='#FFF' xlinkHref='#prefix__b' />
      </g>
    </svg>
  );
}
