import React from 'react';

import './TourStopNumber.scss';

export default function TourStopNumber({ index }: { index: number }) {
  return (
    <div className='TourStopNumber'>
      <strong className='stop-number'>{index + 1}</strong>
    </div>
  );
}
