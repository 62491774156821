import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface GardenLocation {
  LOCATION_GROUP_NOW: string;
  LOCATION_GROUP_NOW_FULL: string;
  thumbnailUrl: string;
  previewUrl: string;
  GardenCode: string;
  GardenWebsite: string;
  GardenLocation: string;
}

export interface ModalState {
  modalContent: string;
  modalGardenLocation?: GardenLocation;
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    modalContent: '',
    modalGardenLocation: undefined,
  },
  reducers: {
    setModalContent: (state: ModalState, action: PayloadAction<ModalState['modalContent']>) => {
      state.modalContent = action.payload;
    },
    setModalGardenLocation: (state: ModalState, action: PayloadAction<ModalState['modalGardenLocation']>) => {
      state.modalGardenLocation = action.payload;
    },
  },
});

export const { setModalContent, setModalGardenLocation } = modalSlice.actions;

export const $modalContent = (state: RootState) => state.modalReducer.modalContent;
export const $modalGardenLocation = (state: RootState) => state.modalReducer.modalGardenLocation;

export default modalSlice.reducer;
