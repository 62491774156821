import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import store from '../../store/store';

import Legend from '../../components/map/Legend';
import Camera from '../../components/map/buttons/Camera';
import AccessibilityWidget from '../../components/map/buttons/AccessibilityWidget';

import Favorite from '../../components/map/buttons/Favorite';
import Filter from '../../components/map/buttons/Filter';
import Tour from '../../components/map/buttons/Tour';

export const mapWidgetLegend = (_mapview: __esri.MapView | undefined, widgetLocation: string) => {
  const node = document.createElement('div');
  _mapview?.ui.add(node, widgetLocation);
  ReactDOM.render(
    <Provider store={store}>
      <Legend />
    </Provider>,
    node
  );
};

export const mapWidgetFilter = (_mapview?: __esri.MapView, widgetLocation?: string) => {
  const node = document.createElement('div');
  _mapview?.ui.add(node, widgetLocation);
  ReactDOM.render(
    <Provider store={store}>
      <Filter />
    </Provider>,
    node
  );
};

export const mapWidgetFavorite = (_mapview: __esri.MapView | undefined, widgetLocation: string) => {
  const node = document.createElement('div');
  _mapview?.ui.add(node, widgetLocation);
  ReactDOM.render(
    <Provider store={store}>
      <Favorite />
    </Provider>,
    node
  );
};

export const mapWidgetAccessibility = (_mapview: __esri.MapView | undefined, widgetLocation: string) => {
  const node = document.createElement('div');
  _mapview?.ui.add(node, widgetLocation);

  ReactDOM.render(
    <Provider store={store}>
      <AccessibilityWidget />
    </Provider>,
    node
  );
};

export const mapWidgetCamera = (_mapview: __esri.MapView | undefined, widgetLocation: string) => {
  const node = document.createElement('div');
  _mapview?.ui.add(node, widgetLocation);
  ReactDOM.render(
    <Provider store={store}>
      <Camera />
    </Provider>,
    node
  );
};

export const mapWidgetTour = (_mapview: __esri.MapView | undefined, widgetLocation: string) => {
  const node = document.createElement('div');
  _mapview?.ui.add(node, widgetLocation);
  ReactDOM.render(
    <Provider store={store}>
      <Tour />
    </Provider>,
    node
  );
};
