import { LOCATIONS_ESRI_FIELD, LOCATIONS_SEARCH_LABEL } from './plant.config';

export const filterOptionButtonHeight = 60;

const filterOptionsConfig = [
  {
    label: 'Type of Plant',
    esriField: 'HABIT_FULL',
    esriQueryMatch: 'like',
    icon: 'IconPlantType',
    type: 'radio',
    options: [
      { label: 'All', values: ['all'] },
      { label: 'Bulb/Tuber/Rhizome', values: ['bulb', 'tuber', 'rhizome'] },
      { label: 'Cactus/Succulent', values: ['cactus', 'succulent'] },
      { label: 'Grass/Grasslike', values: ['grass', 'grasslike'] },
      { label: 'Herbaceous', values: ['herbaceous'] },
      { label: 'Shrub', values: ['shrub'] },
      { label: 'Tree', values: ['tree'] },
      { label: 'Vine', values: ['vine'] },
    ],
  },
  {
    label: 'Flower Color',
    esriField: 'FL_COLOR_FULL',
    esriQueryMatch: 'like',
    icon: 'IconFlower',
    type: 'radio',
    options: [
      { label: 'All', values: ['all'] },
      { label: 'Black', values: ['black'] },
      { label: 'Blue', values: ['blue'] },
      { label: 'Brown', values: ['brown'] },
      { label: 'Bronze', values: ['bronze'] },
      { label: 'Copper', values: ['copper'] },
      { label: 'Cream', values: ['cream'] },
      { label: 'Gold', values: ['gold'] },
      { label: 'Gray', values: ['gray'] },
      { label: 'Green', values: ['green'] },
      { label: 'Orange', values: ['orange'] },
      { label: 'Pink', values: ['pink'] },
      { label: 'Purple', values: ['purple'] },
      { label: 'Red', values: ['red'] },
      { label: 'Red-Violet', values: ['red-violet'] },
      { label: 'Silver', values: ['silver'] },
      { label: 'White', values: ['white'] },
      { label: 'Yellow', values: ['yellow'] },
      { label: 'Multi-Colored', values: ['multi-colored'] },
    ],
  },
  {
    label: 'Leaf Color',
    esriField: 'LEAF_COLOR_FULL',
    esriQueryMatch: 'like',
    icon: 'IconLeaf',
    type: 'radio',
    options: [
      { label: 'All', values: ['all'] },
      { label: 'Black', values: ['black'] },
      { label: 'Blue', values: ['blue'] },
      { label: 'Brown', values: ['brown'] },
      { label: 'Bronze', values: ['bronze'] },
      { label: 'Copper', values: ['copper'] },
      { label: 'Cream', values: ['cream'] },
      { label: 'Gold', values: ['gold'] },
      { label: 'Gray', values: ['gray'] },
      { label: 'Green', values: ['green'] },
      { label: 'Orange', values: ['orange'] },
      { label: 'Pink', values: ['pink'] },
      { label: 'Purple', values: ['purple'] },
      { label: 'Red', values: ['red'] },
      { label: 'Red-Violet', values: ['red-violet'] },
      { label: 'Silver', values: ['silver'] },
      { label: 'White', values: ['white'] },
      { label: 'Yellow', values: ['yellow'] },
      { label: 'Multi-Colored', values: ['multi-colored'] },
    ],
  },
  {
    label: 'Deciduous or Evergreen',
    esriField: 'DECID_FULL',
    esriQueryMatch: 'like',
    icon: 'IconSunlightNeeds',
    type: 'radio',
    options: [
      { label: 'All', values: ['all'] },
      { label: 'Either', values: ['either'] },
      { label: 'Deciduous', values: ['deciduous'] },
      { label: 'Evergreen', values: ['evergreen'] },
      { label: 'Semi-Evergreen', values: ['semi-evergreen'] },
    ],
  },
  {
    label: 'Sunlight Requirements',
    esriField: 'SUN_FULL',
    esriQueryMatch: 'like',
    icon: 'IconSunlightNeeds',
    type: 'radio',
    options: [
      { label: 'All', values: ['all'] },
      { label: 'Full Sun', values: ['full', 'sun'] },
      { label: 'Part Sun', values: ['part', 'sun'] },
      { label: 'Part Shade', values: ['part', 'shade'] },
      { label: 'Full Shade', values: ['full', 'shade'] },
    ],
  },
  {
    label: LOCATIONS_SEARCH_LABEL,
    esriField: LOCATIONS_ESRI_FIELD,
    esriQueryMatch: 'like',
    icon: 'IconSunlightNeeds',
    type: 'radio',
    options: [{ label: 'All', values: ['all'] }],
  },
  {
    label: 'Special Characteristics',
    esriField: 'SPEC_CHAR_FULL',
    esriQueryMatch: 'like',
    icon: 'IconBurgerMenu',
    type: 'checkbox',
    options: [
      { label: 'All', values: ['All'] },
      { label: 'Bark', values: ['Bark'] },
      { label: 'Bird Attractant', values: ['Bird Attractant'] },
      { label: 'Butterfly Attractant', values: ['Butterfly Attractant'] },
      { label: 'Disease Resistance', values: ['Disease Resistance'] },
      { label: 'Drought Tolerant', values: ['Drought Tolerant'] },
      { label: 'Early Spring Interest', values: ['Early Spring Interest'] },
      { label: 'Early Summer Interest', values: ['Early Summer Interest'] },
      { label: 'Fall Color', values: ['Fall Color'] },
      { label: 'Fall Interest', values: ['Fall Interest'] },
      { label: 'Flower Color', values: ['Flower Color'] },
      { label: 'Flower Shape', values: ['Flower Shape'] },
      { label: 'Flower Size', values: ['Flower Size'] },
      { label: 'Foliage Characteristics', values: ['Foliage Characteristics'] },
      { label: 'Food', values: ['Food'] },
      { label: 'Fragrance', values: ['Fragrance'] },
      { label: 'Fruiting Characteristics', values: ['Fruiting Characteristics'] },
      { label: 'Hardiness', values: ['Hardiness'] },
      { label: 'Invasive', values: ['Invasive'] },
      { label: 'Late Spring Interest', values: ['Late Spring Interest'] },
      { label: 'Late Summer Interest', values: ['Late Summer Interest'] },
      { label: 'Medicinal', values: ['Medicinal'] },
      { label: 'Mid Spring Interest', values: ['Mid Spring Interest'] },
      { label: 'Mid Summer Interest', values: ['Mid Summer Interest'] },
      { label: 'Pest Resistance', values: ['Pest Resistance'] },
      { label: 'Poisonous/Toxic', values: ['Poisonous', 'Toxic'] },
      { label: 'Rarity', values: ['Rarity'] },
      { label: 'Wildlife Attractant', values: ['Wildlife Attractant'] },
      { label: 'Winter Interest', values: ['Winter Interest'] },
      { label: 'Year Round Interest', values: ['Year Round Interest'] },
    ],
  },
];

export default filterOptionsConfig;
