import React from 'react';

import './PlantSearchFindPlantInGarden.scss';

export const PlantSearchFindPlantInGarden = () => {
  return (
    <div id='PlantSearchFindPlantInGarden'>
      <h3 className='PlantSearchFindPlantInGarden__title'>Find a Plant in the Garden</h3>
      <h5 className='PlantSearchFindPlantInGarden__sub-title'>
        Search for a plant by name by entering part or all of plant name
      </h5>
    </div>
  );
};
