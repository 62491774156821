import React from 'react';

import './LayersDisabled.scss';

export function LayersDisabled() {
  return (
    <div className='ada-layers-disabled'>
      <p className='ada-layers-disabled__label'>Zoom in to see layers</p>
    </div>
  );
}
