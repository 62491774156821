import React from 'react';
import { Link } from 'react-router-dom';

import './SpecificPlantFindOthers.scss';

export default function SpecificPlantFindOthers({ GENUS }: { GENUS: string }) {
  return (
    <Link className='find-others-plants-like-this__link' to={`/map/search?field=Plants&value=${GENUS}`}>
      Find other plants like this: {GENUS}
    </Link>
  );
}
