import React from 'react';
import { Link } from 'react-router-dom';

import { SEARCH_OPTIONS_ADVANCED } from '../../../../config/plant.config';
import { splitByCharacter } from '../../../../utils/plantSyntax.util';

export default function PlantResultAdvancedField({
  searchFieldAdvanced,
  plant,
}: {
  searchFieldAdvanced: string;
  plant: any;
}) {
  const { displayField }: any = SEARCH_OPTIONS_ADVANCED.find(({ esriField }) => esriField === searchFieldAdvanced);

  return (
    <span className='PlantResultAdvancedField commonname'>
      <Link to={`/map/${displayField.toLowerCase()}/${splitByCharacter(plant[displayField])}`}>
        {splitByCharacter(plant[displayField])}
      </Link>
    </span>
  );
}
