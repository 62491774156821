import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _every from 'lodash/every';

import { RootState } from '../store';
import filterOptionsConfig from '../../config/filterPlants';

import mapController from '../../controllers/MapController';

const filterSelectedOptions = filterOptionsConfig.map((option) => {
  if (option.type === 'radio') {
    return {
      ...option,
      activeValues: [],
      activeRadioButton: 'All',
    };
  } else {
    return {
      ...option,
      activeValues: ['All'],
    };
  }
});

export interface DropdownOption {
  label: string;
  esriField: string;
  esriQueryMatch: string;
  icon: string;
  options: Array<object>;
  activeValues: Array<string>;
  activeRadioButton?: string;
  type: string;
}

export interface FilterState {
  filterVisible: boolean;
  filterActiveDropdowns: string;
  filterSelectedOptions: Array<DropdownOption>;
  filterDefinitionExpression: string;
  searchDefinitionExpression: string;
  tourDefinitionExpression: string;
}

const initialState: FilterState = {
  filterVisible: false,
  filterActiveDropdowns: '',
  filterSelectedOptions: filterSelectedOptions,
  filterDefinitionExpression: '',
  searchDefinitionExpression: '',
  tourDefinitionExpression: '',
};

export const filterSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setFilterVisible: (state: FilterState, action: PayloadAction<FilterState['filterVisible']>) => {
      state.filterVisible = action.payload;
    },
    setFilterActiveDropdowns: (state: FilterState, action: PayloadAction<FilterState['filterActiveDropdowns']>) => {
      state.filterActiveDropdowns = state.filterActiveDropdowns === action.payload ? '' : action.payload;
    },
    setFilterSelectedOptions: (state: FilterState, action: PayloadAction<FilterState['filterSelectedOptions']>) => {
      state.filterSelectedOptions = action.payload;
    },
    resetFilterOptions: (state: FilterState) => {
      state.filterDefinitionExpression = '';
      state.filterActiveDropdowns = '';
      state.filterSelectedOptions = filterSelectedOptions;

      mapController.updatePlantsLayerDefinitionExpression({
        filter: '',
        search: state.searchDefinitionExpression,
      });
    },
    setFilterActiveOptions: (state: FilterState, action: PayloadAction<FilterState['filterSelectedOptions']>) => {
      state.filterSelectedOptions = [...action.payload];
    },
    setFilterDefinitionExpression: (state: FilterState, action) => {
      const filters = action.payload;
      let definitionExpression;

      const allDefaultFilterValuesSelected = _every(filters, ['activeRadioButton', 'All']);

      if (allDefaultFilterValuesSelected) {
        definitionExpression = '';
      } else {
        const definitionExpressionList: string[] = [];
        filters.forEach((filterOption: any) => {
          const { activeRadioButton, esriQueryMatch, esriField, activeValues, type } = filterOption;
          const activeSelection = activeRadioButton && activeRadioButton !== 'All';

          if (type === 'checkbox' && !activeValues.includes('All')) {
            const definitionExpressionPart: string[] = activeValues.map(
              (value: any) => `(${esriField} LIKE '%${value}%')`
            );
            definitionExpressionList.push(`(${definitionExpressionPart.join(' AND ')})`);
          }

          if (type === 'radio' && activeSelection) {
            const definitionExpressionPart: string[] =
              esriQueryMatch === 'like'
                ? activeValues.map((value: any) => `(${esriField} LIKE '%${value}%')`)
                : activeValues.map((value: any) => `(${esriField} = '${value}')`);
            definitionExpressionList.push(`(${definitionExpressionPart.join(' OR ')})`);
          }
        });
        definitionExpression = definitionExpressionList.join(' AND ');
      }

      state.filterDefinitionExpression = definitionExpression;

      mapController.updatePlantsLayerDefinitionExpression({
        search: state.searchDefinitionExpression,
        filter: definitionExpression,
        tour: state.tourDefinitionExpression,
      });
    },
    setSearchDefinitionExpression: (state: FilterState, action) => {
      state.searchDefinitionExpression = action.payload;

      mapController.updatePlantsLayerDefinitionExpression({
        search: action.payload,
        filter: state.filterDefinitionExpression,
        tour: state.tourDefinitionExpression,
      });
    },
    setTourDefinitionExpressionByAccession: (state: FilterState, action) => {
      state.tourDefinitionExpression = action.payload
        .map((accession: string) => `ACC_NUM_AND_QUAL IN ('${accession}')`)
        .join(' OR ');

      mapController.updatePlantsLayerDefinitionExpression({
        search: state.searchDefinitionExpression,
        filter: state.filterDefinitionExpression,
        tour: state.tourDefinitionExpression,
      });
    },
  },
});

// ACTIONS
export const {
  setFilterVisible,
  setFilterActiveDropdowns,
  setFilterSelectedOptions,
  resetFilterOptions,
  setFilterActiveOptions,
  setSearchDefinitionExpression,
  setFilterDefinitionExpression,
  setTourDefinitionExpressionByAccession,
} = filterSlice.actions;

// SELECTORS
export const selectMapFilterVisible = (state: RootState) => state.filterReducer.filterVisible;
export const selectMapFilterActiveDropdowns = (state: RootState) => state.filterReducer.filterActiveDropdowns;
export const $filterSelectedOptions = (state: RootState) => state.filterReducer.filterSelectedOptions;
export const selectMapFilterSelectedOptions = (state: RootState) => state.filterReducer.filterSelectedOptions;
export const $filterDefinitionExpression = (state: RootState) => state.filterReducer.filterDefinitionExpression;
export const $searchDefinitionExpression = (state: RootState) => state.filterReducer.searchDefinitionExpression;
export const tourDefinitionExpression = (state: RootState) => state.filterReducer.searchDefinitionExpression;

export default filterSlice.reducer;
