import React from 'react';
import { useSelector } from 'react-redux';

import SearchPlantsAdvancedFilters from '../SearchPlantsAdvancedFilters/SearchPlantsAdvancedFilters';
import SearchPlantsAlphabet from '../../plants/SearchPlantsAlphabet/SearchPlantsAlphabet';
import SearchPlantsAdvancedResults from '../../plants/SearchPlantsAdvancedResults/SearchPlantsAdvancedResults';

// Store
import { $searchTypeAdvanced } from '../../../store/app/appSlice';

// Config
import { SEARCH_PLANT_ALPHABET_SECTIONS } from '../../../config/plant.config';

// Style
import './SearchPlantsAdvancedModal.scss';

export default function SearchPlantsAdvancedModal() {
  const searchTypeAdvanced = useSelector($searchTypeAdvanced);

  return (
    <div id='SearchPlantsAdvancedModal'>
      <SearchPlantsAdvancedFilters />

      {SEARCH_PLANT_ALPHABET_SECTIONS.includes(searchTypeAdvanced) && <SearchPlantsAlphabet />}

      <div
        id='SearchPlantsAdvancedModal__results-container'
        data-search-type-advanced={SEARCH_PLANT_ALPHABET_SECTIONS.includes(searchTypeAdvanced)}
      >
        <SearchPlantsAdvancedResults />
      </div>
    </div>
  );
}
