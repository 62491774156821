import { createTheme } from '@material-ui/core';
import { COLOR_PRIMARY, NYBG_GOTHIC_FONT, NYBG_GT_TEXT_FONT } from '../config/config';

export const theme = createTheme({
  palette: {
    primary: {
      main: COLOR_PRIMARY,
    },
    secondary: {
      main: '#c8b500',
    },
  },
  overrides: {
    MuiButtonBase: {
      root: {
        fontFamily: NYBG_GT_TEXT_FONT,
      },
    },
    MuiSwitch: {
      switchBase: {
        color: COLOR_PRIMARY,
        '&$checked': {
          color: COLOR_PRIMARY,
        },
        '&$checked + $track': {
          backgroundColor: COLOR_PRIMARY,
        },
      },
      colorPrimary: {
        '&$checked': {
          color: COLOR_PRIMARY,
          '& + $track': {
            backgroundColor: COLOR_PRIMARY,
          },
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontFamily: NYBG_GT_TEXT_FONT,
      },
    },
    MuiInput: {
      input: {
        fontFamily: NYBG_GT_TEXT_FONT,
      },
    },
    MuiTypography: {
      h1: {
        fontFamily: NYBG_GOTHIC_FONT,
        textTransform: 'uppercase',
      },
      h2: {
        fontFamily: NYBG_GOTHIC_FONT,
        textTransform: 'uppercase',
      },
      h3: {
        fontFamily: NYBG_GOTHIC_FONT,
        textTransform: 'uppercase',
      },
      h4: {
        fontFamily: NYBG_GOTHIC_FONT,
        textTransform: 'uppercase',
      },
      h5: {
        fontFamily: NYBG_GOTHIC_FONT,
        textTransform: 'uppercase',
      },
      h6: {
        fontFamily: NYBG_GOTHIC_FONT,
        textTransform: 'uppercase',
      },
      body1: {
        fontFamily: NYBG_GT_TEXT_FONT,
      },
      body2: {
        fontFamily: NYBG_GT_TEXT_FONT,
      },
      subtitle1: {
        fontFamily: NYBG_GT_TEXT_FONT,
      },
      subtitle2: {
        fontFamily: NYBG_GT_TEXT_FONT,
      },
      caption: {
        fontFamily: NYBG_GT_TEXT_FONT,
      },
    },
  },
});
