import React from 'react';

import PhenologyChartLegendFlowering from './PhenologyChartLegendFlowering';
import PhenologyChartLegendFruiting from './PhenologyChartLegendFruiting';

const PhenologyChartLegend = ({
  selectedValue,
  uniqueYears,
  handleYearChange,
}: {
  selectedValue: number | undefined;
  uniqueYears: any;
  handleYearChange: any;
}) => (
  <div id='PhenologyChartLegend' className='phenology-chart__legend'>
    <div className='phenology-chart__legend-year'>
      <p className='phenology-chart__select-year--title'>Select Year:</p>
      <select
        className='phenology-chart__select-year'
        value={selectedValue}
        onChange={(evt) => handleYearChange(evt.target.value)}
      >
        {uniqueYears &&
          uniqueYears.map((year: number) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
      </select>
    </div>
    <div className='phenology-chart__legend-labels'>
      <PhenologyChartLegendFlowering />
      <PhenologyChartLegendFruiting />
    </div>
  </div>
);

export default PhenologyChartLegend;
