import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { getPlantsInWeek } from './PhenologyChart.util';
import { WEEKS_IN_YEAR_RANGE } from './PhenologyChart.config';

const PhenologyTableFruitingYear = ({
  year,
  selectedYear,
  selectedPlantsByYear,
}: {
  year: number;
  selectedYear: number;
  selectedPlantsByYear: string[];
}) => {
  return (
    <tr
      key={`Fruiting_${year}_${uuidv4()}`}
      className='phenology-chart__table-row--fruiting table-row'
      data-selected-year={year === selectedYear || null}
    >
      <td className='table-cell cell-long cell-year'>{year}</td>
      {WEEKS_IN_YEAR_RANGE.map((weekInt) => {
        const plantsInWeek = getPlantsInWeek(selectedPlantsByYear, weekInt);
        return (
          <td
            key={uuidv4()}
            className='table-cell cell-long'
            title={`week: ${weekInt}`}
            data-week-match={plantsInWeek.length > 0 || null}
            data-week-match-fruiting={plantsInWeek.length > 0 || null}
          />
        );
      })}
    </tr>
  );
};

export default PhenologyTableFruitingYear;
