import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import loadable from '@loadable/component';
import { ThemeProvider } from '@material-ui/core';
import { StylesProvider } from '@material-ui/core/styles';
import { theme } from './styles/muiThemeProvider';

import store from './store/store';

import Home from './components/home/Home';
import Header from './components/header/Header';
import FamilyIndex from './components/plants/SpecificFamily';
import GenusIndex from './components/plants/SpecificGenus';
import PlantIndex from './components/plants/SpecificPlant';
import ModalView from './components/modal/ModalView';
import SpecificAccession from './components/plants/SpecificAccession';

import * as serviceWorker from './serviceWorker';
import { BASE_URL } from './config/config';

import './index.scss';

const Map = loadable(() => import('./components/map/Map'));
const Error = loadable(() => import('./components/error/Error'));

declare global {
  interface Window {
    gtag: any;
  }
}
// function usePageViews() {
//   let location = useLocation();
//   useEffect(() => {
//     window.gtag('config', 'UA-163210212-1', {
//       page_path: location.pathname,
//     });
//   }, [location]);
// }
// usePageViews();

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <StylesProvider injectFirst={false}>
            <Router>
              <Header />
              <Switch>
                <Route exact path={`${BASE_URL}/`} component={Home} />
                <Route path={`${BASE_URL}/plants/family/:value`} component={() => <FamilyIndex />} />
                <Route path={`${BASE_URL}/plants/genus/:value`} component={GenusIndex} />
                <Route path={`${BASE_URL}/plants/accession`} component={SpecificAccession} />
                <Route path={`${BASE_URL}/plants/:field/:value`} component={PlantIndex} />
                <Route path={`${BASE_URL}/map`} />
                <Route path='*' component={Error} />
              </Switch>
              <Map />
              <ModalView />
            </Router>
          </StylesProvider>
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
