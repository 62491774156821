import React from 'react';

export function ModalHeader(props: { title?: string; subTitle?: string; headerAction?: any }) {
  const { subTitle, title, headerAction } = props;
  return (
    <div id='ModalHeader'>
      <div id='ModalHeader__title-container'>
        {title && <h3 id='ModalHeader__title'>{title}</h3>}
        {subTitle && <h4 id='ModalHeader__sub-title'>{subTitle}</h4>}
      </div>
      {headerAction && <div id='ModalHeader__action'>{headerAction}</div>}
    </div>
  );
}
