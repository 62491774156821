import * as React from 'react';

export default function IconPlantCheckmark(props: any) {
  return (
    <svg
      className='IconPlantCheckmark plant-result-icon'
      viewBox='0 0 30 30'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fill='#333'
        d='M15 3C8.373 3 3 8.373 3 15s5.373 12 12 12 12-5.373 12-12S21.627 3 15 3zm6.707 9.707l-7.56 7.56a1 1 0 01-1.414 0L9.28 16.814a.999.999 0 111.414-1.414l2.746 2.746 6.853-6.853a.999.999 0 111.414 1.414z'
      />
    </svg>
  );
}
