import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { setModalContent } from '../../store/app/modalSlice';
import { selectMobileMenuOpen, setCardContent, setMobileMenuOpen, setMinimized } from '../../store/app/appSlice';

import { BASE_URL } from '../../config/config';
import { menuOptions } from '../../config/menu';
import { HeaderMenuButton } from './HeaderMenuButton';
import IconChevronUp from '../icons/IconChevronUp';
import mapController from '../../controllers/MapController';

const HeaderMenu = () => {
  const dispatch = useDispatch();
  const mobileMenuOpen = useSelector(selectMobileMenuOpen);

  const resetMap = () => {
    mapController.resetMapGraphics();
  };

  return (
    <nav className='header-menu'>
      <div className='header-menu__links' data-mobile-menu-open={mobileMenuOpen}>
        <Link
          className='header-menu__link'
          to={`${BASE_URL}/map`}
          onClick={() => {
            resetMap();
            dispatch(setMobileMenuOpen(false));
            dispatch(setMinimized(true));
            dispatch(setCardContent(''));
          }}
        >
          Map
        </Link>
        <Link
          className='header-menu__link'
          to={`${BASE_URL}/map`}
          onClick={() => {
            resetMap();
            dispatch(setMobileMenuOpen(false));
            dispatch(setMinimized(false));
            dispatch(setCardContent(''));
          }}
        >
          Find a plant
        </Link>

        {menuOptions.map(({ menuTitle, url, subOptions }, index) => {
          return (
            <div className='header-menu__links-nest sub-options' key={index}>
              <a
                key={index}
                className={`header-menu__link ${menuTitle === 'Join & Support' ? 'green' : ''}`}
                href={url}
                target='_blank'
                rel='noopener noreferrer'
              >
                {menuTitle}
                {subOptions.length ? <IconChevronUp width={17.5} height={17.5} /> : null}
              </a>
              <ul className='sub-menu-options sub-options'>
                {subOptions.map(({ menuTitle, url }, index) => {
                  return (
                    <a
                      className={`header-menu__link`}
                      target='_blank'
                      rel='noopener noreferrer'
                      key={index}
                      href={url}
                      onClick={() => {
                        dispatch(setMobileMenuOpen(false));
                      }}
                    >
                      {menuTitle}
                    </a>
                  );
                })}
              </ul>
            </div>
          );
        })}

        <button
          className='header-menu__link'
          onClick={() => {
            dispatch(setMobileMenuOpen(false));
            dispatch(setModalContent('CollectionsLanguageModal'));
          }}
        >
          Collections Language
        </button>
      </div>

      <HeaderMenuButton />
    </nav>
  );
};

export default HeaderMenu;
