import React, { useEffect, useState } from 'react';

import PhenologyChartLegend from './PhenologyChartLegend';
import PhenologyTableHead from './PhenologyTableHead';
import PhenologyTableAverage from './PhenologyTableAverage';
import PhenologyTableFloweringYear from './PhenologyTableFloweringYear';
import PhenologyTableFruitingYear from './PhenologyTableFruitingYear';
import PhenologyChartLegendFlowering from './PhenologyChartLegendFlowering';
import PhenologyChartLegendFruiting from './PhenologyChartLegendFruiting';

import {
  filterByYear,
  getPlantsChartDatasets,
  getUniqueYears,
  PhenologyChartDataInterface,
} from './PhenologyChart.util';

import { WEEKS_IN_YEAR_RANGE } from './PhenologyChart.config';

import './PhenologyChart.scss';

const PhenologyChart = ({
  plant,
  phenologyChartData,
}: {
  plant: any;
  phenologyChartData: PhenologyChartDataInterface;
}) => {
  const uniqueYears = getUniqueYears(phenologyChartData);
  const [selectedYear, setSelectedYear] = useState<number>(uniqueYears[0]);

  useEffect(() => {
    if (!selectedYear) {
      setSelectedYear(uniqueYears[0]);
    }
  }, [selectedYear, uniqueYears]);

  const handleYearChange = (year: string) => {
    const yearInt = parseInt(year);
    setSelectedYear(yearInt);
  };

  return (
    phenologyChartData && (
      <div id='PhenologyChart' className='phenology-chart'>
        {uniqueYears && uniqueYears?.length > 0 && (
          <React.Fragment>
            <h4 className='phenology-chart__title'>Phenology Chart</h4>
            <PhenologyChartLegend
              selectedValue={selectedYear}
              uniqueYears={uniqueYears}
              handleYearChange={handleYearChange}
            />
            <div className='phenology-chart__table-container'>
              <table className='phenology-chart__table'>
                <tbody>
                  <tr className='row__flowering hide-on-mobile'>
                    <td />
                    <td colSpan={WEEKS_IN_YEAR_RANGE.length} className='table-row__flowering--title'>
                      When <i className='table-row__flowering--plant'>{plant?.NAME} has been observed flowering</i>
                      <br />
                      at The New York Botanical Garden
                      <div className='phenology-chart__legend-labels'>
                        <PhenologyChartLegendFlowering />
                      </div>
                    </td>
                  </tr>

                  <PhenologyTableHead />
                  <PhenologyTableAverage phenologyChartData={phenologyChartData} type='flowering' />
                  {uniqueYears.map((year) => {
                    const selectedPlantsByYear = filterByYear(phenologyChartData, year, 'flowering');
                    return (
                      selectedPlantsByYear.length > 0 && (
                        <PhenologyTableFloweringYear
                          key={`Flowering_${year}`}
                          year={year}
                          selectedYear={selectedYear}
                          selectedPlantsByYear={selectedPlantsByYear}
                        />
                      )
                    );
                  })}

                  <tr className='row__fruiting hide-on-mobile'>
                    <td />
                    <td colSpan={WEEKS_IN_YEAR_RANGE.length} className='table-row__fruiting--title'>
                      When <i className='table-row__fruiting--plant'>{plant?.NAME} has been observed fruiting</i>
                      <br />
                      at The New York Botanical Garden
                      <div className='phenology-chart__legend-labels'>
                        <PhenologyChartLegendFruiting />
                      </div>
                    </td>
                  </tr>

                  <PhenologyTableHead class='hide-on-mobile' />
                  <PhenologyTableAverage phenologyChartData={phenologyChartData} type='fruiting' />
                  {uniqueYears.map((year) => {
                    const selectedPlantsByYear = filterByYear(phenologyChartData, year, 'fruiting');
                    return (
                      selectedPlantsByYear.length > 0 && (
                        <PhenologyTableFruitingYear
                          key={`Fruiting_${year}`}
                          year={year}
                          selectedYear={selectedYear}
                          selectedPlantsByYear={selectedPlantsByYear}
                        />
                      )
                    );
                  })}
                </tbody>
              </table>
            </div>
          </React.Fragment>
        )}
      </div>
    )
  );
};

export default PhenologyChart;
