import * as React from 'react';
import { SVG_CONFIG } from '../../config/config';

function IconLeaf({ fill, width = 35, height = 35 }: { fill?: string; width?: number; height?: number }) {
  return (
    <svg width={width} height={height} viewBox='0 0 35 35'>
      <path
        fill={fill ? fill : SVG_CONFIG.fill}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.529 11.443c-3.66 2.12-3.45 5.56-3.32 6.85 4.72-5.59 11.8-5.32 11.8-5.32s-10.04 3.43-12.98 10.32c-.24.54 1.09 1.25 1.39.6.9-1.9 2.16-3.34 2.16-3.34 1.85.68 5.06 1.5 7.33-.1 3.02-2.13 2.7-6.83 7.02-9.12 1-.53-8.45-2.77-13.41.1l.01.01z'
      />
    </svg>
  );
}

export default IconLeaf;
