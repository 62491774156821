import * as React from 'react';

function IconRadioButton(props: { checked: boolean }) {
  return (
    <svg width={20} height={20} style={{ padding: '0 10px' }}>
      <g fill='none' fillRule='evenodd' transform='translate(1 1)'>
        <circle cx={9} cy={9} r={9} stroke='#000' strokeWidth={2} />
        <circle cx={9} cy={9} r={5} fill={props.checked ? '#000' : 'transparent'} />
      </g>
    </svg>
  );
}

export default IconRadioButton;
