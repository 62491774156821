export const POINTS_OF_INTERENST = 'Points of Interest';
export const pointsConfig = [
  {
    label: POINTS_OF_INTERENST,
    options: [],
  },
];

export const mapLayerToggles = [
  {
    label: 'Plant Data',
    id: 'plantData',
  },
  {
    label: 'Artistic Basemap',
    id: 'art',
  },
  // {
  //   label: 'Location Codes',
  //   id: 'locationCodes',
  // },
];

export const ACCESSIBLE_ROUTES_PATHS = 'Accessible Routes & Paths';
export const adaConfig = [
  {
    label: ACCESSIBLE_ROUTES_PATHS,
    options: [],
  },
];
