import * as React from 'react';
import { SVG_CONFIG } from '../../config/config';

function IconPlantType({ fill }: { fill?: string }) {
  return (
    <svg width={35} height={35} viewBox='0 0 35 35'>
      <path
        fill={fill ? fill : SVG_CONFIG.fill}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.4 9h-2.859C9.794 9 9 9.794 9 11.541V14.4c0 1.737.794 2.53 2.541 2.53H14.4c1.747 0 2.541-.793 2.541-2.54V11.54C16.941 9.805 16.147 9 14.4 9zm10.059 0H21.6c-1.747 0-2.541.794-2.541 2.541V14.4c0 1.737.794 2.53 2.541 2.53h2.859c1.747 0 2.541-.793 2.541-2.54V11.54C27 9.805 26.206 9 24.459 9zM14.4 19.048h-2.859C9.794 19.048 9 19.842 9 21.59v2.849c0 1.747.794 2.54 2.541 2.54H14.4c1.747 0 2.541-.793 2.541-2.54v-2.86c0-1.736-.794-2.54-2.541-2.54v.01zm10.059 0H21.6c-1.747 0-2.541.794-2.541 2.541v2.849c0 1.747.794 2.54 2.541 2.54h2.859c1.747 0 2.541-.793 2.541-2.54v-2.86c0-1.736-.794-2.54-2.541-2.54v.01z'
      />
    </svg>
  );
}

export default IconPlantType;
