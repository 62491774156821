import React from 'react';

import './CollectionsLanguageModal.scss';

export default function CollectionsLanguageModal() {
  return (
    <div className='CollectionsLanguageModal'>
      <p className='CollectionsLanguageModal__description'>
        The New York Botanical Garden offers public access to a wide range of information, including historical
        materials and collections that may contain offensive and unacceptable language. Such information does not
        reflect the views and values of NYBG. We are committed to preserving collections data in their original forms
        for historical accuracy and to facilitate research. We welcome feedback and questions about language found in
        our databases. Please contact{' '}
        <a href='mailto:collectionsidea@nybg.org' target='_blank' rel='noreferrer'>
          collectionsidea@nybg.org
        </a>
      </p>
    </div>
  );
}
