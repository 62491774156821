import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import {
  decodePathValue,
  MAP_LINK__COMMON_NAME,
  MAP_LINK__GENUS,
  MAP_LINK__LOCATION,
  MAP_LINK__SPECIES,
  MAP_LINK__TOURS,
  PLANTS_LINK__ACCESSION,
  PLANTS_LINK__SPECIES,
} from '../../utils/path.util';

import { $selectedAccession } from '../../store/app/appSlice';
import { $toursAll, $tourSelected } from '../../store/app/tourSlice';
import { $modalContent } from '../../store/app/modalSlice';

const WEBSITE_NAME = process.env.REACT_APP_WEBSITE_SHORT_NAME;

export function SEO() {
  const location = useLocation();
  const toursAll = useSelector($toursAll);
  const tourSelected = useSelector($tourSelected);
  const selectedAccession = useSelector($selectedAccession);
  const modalContent = useSelector($modalContent);
  const [label, setLabel] = useState('');
  const [type, setType] = useState('');

  useEffect(() => {
    if (location.pathname.includes(MAP_LINK__GENUS)) {
      setLabel(decodePathValue(location.pathname.split(MAP_LINK__GENUS)[1]));
      setType('Genus');
    } else if (location.pathname.includes(MAP_LINK__SPECIES)) {
      setLabel(decodePathValue(location.pathname.split(MAP_LINK__SPECIES)[1]));
      setType('Species');
    } else if (location.pathname.includes(MAP_LINK__LOCATION)) {
      setLabel(decodePathValue(location.pathname.split(MAP_LINK__LOCATION)[1]));
      setType('Location');
    } else if (location.pathname.includes(MAP_LINK__COMMON_NAME)) {
      setLabel(decodePathValue(location.pathname.split(MAP_LINK__COMMON_NAME)[1]));
      setType('Common Name');
    } else if (location.pathname.includes(PLANTS_LINK__SPECIES)) {
      setLabel(decodePathValue(location.pathname.split(PLANTS_LINK__SPECIES)[1]));
      setType('Species');
    } else if (location.pathname.includes(PLANTS_LINK__ACCESSION)) {
      setLabel(selectedAccession.attributes.ACC_NUM_AND_QUAL);
      setType('Accession');
    } else if (location.pathname.includes(MAP_LINK__TOURS)) {
      setLabel(tourSelected?.Tour_Name || '');
      setType('Tour');
    } else {
      setLabel('');
    }
  }, [location.pathname, selectedAccession.attributes.ACC_NUM_AND_QUAL, toursAll, tourSelected, modalContent]);

  return (
    <Helmet>
      {label.length > 0 ? (
        <title>
          {label} - {type} | {WEBSITE_NAME}
        </title>
      ) : (
        <title>{WEBSITE_NAME}</title>
      )}
      <link rel='canonical' href={window.location.href} />
    </Helmet>
  );
}
