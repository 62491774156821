import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMobileMenuOpen, setMobileMenuOpen } from '../../store/app/appSlice';

import IconClose from '../icons/IconClose';
import IconBurgerMenu from '../icons/IconBurgerMenu';

export const HeaderMenuButton = () => {
  const dispatch = useDispatch();
  const mobileMenuOpen = useSelector(selectMobileMenuOpen);

  return (
    <button className='header-menu__button' onClick={() => dispatch(setMobileMenuOpen(!mobileMenuOpen))}>
      {mobileMenuOpen ? <IconClose /> : <IconBurgerMenu />}
    </button>
  );
};
