import React from 'react';
import { WEEKS_IN_YEAR_RANGE } from './PhenologyChart.config';
import { getWeek } from 'date-fns';

const PhenologyTableAverage = ({ phenologyChartData, type }: { phenologyChartData: any; type: string }) => {
  const dates = phenologyChartData[type];
  const getCount = (week: number) => {
    const count = dates.reduce((acc: number, cur: string) => {
      const date = new Date(cur);
      const weekInYear = getWeek(date);
      return week === weekInYear ? acc + 1 : acc;
    }, 0);

    return count;
  };

  return (
    <tr>
      <td className='table-cell-label cell-short'>Range</td>
      {WEEKS_IN_YEAR_RANGE.map((week) => {
        const numberOfPlants = getCount(week);
        return (
          <td
            key={`week_${week}`}
            className={`table-cell cell-short type_${type}`}
            data-plants-checked={numberOfPlants > 0 ? (numberOfPlants < 4 ? numberOfPlants : 4) : null}
          />
        );
      })}
    </tr>
  );
};

export default PhenologyTableAverage;
