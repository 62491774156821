import _range from 'lodash/range';

export const MONTHS_SHORT: string[] = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const WEEKS_IN_YEAR_RANGE = _range(1, 53, 1); // [1 .. 52]
