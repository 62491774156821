import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import ShareWidget from '../share/ShareWidget';

import mapController from '../../controllers/MapController';

import { setMinimized, setCardContent } from '../../store/app/appSlice';

import { RootState } from '../../store/store';

import { BASE_URL } from '../../config/config';

import { splitByCharacter } from '../../utils/plantSyntax.util';
import { getPath } from '../../utils/path.util';

const SpecificGenus = () => {
  const dispatch = useDispatch();
  const { value: genus } = useParams();
  const cardMinimized = useSelector((state: RootState) => state.appReducer.cardMinimized);

  const [species, setSpecies] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAndSetResults = async () => {
      const results = await mapController.querySpeciesByGenera(genus);
      setSpecies(results.sort());
      setLoading(false);
    };

    if (genus) {
      dispatch(setMinimized(false));
      getAndSetResults();
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genus]);

  return (
    <div className='plants-container index'>
      <div className='plants-card'>
        <div className='sub-header' onClick={() => dispatch(setMinimized(!cardMinimized))}>
          <Link
            to={`${BASE_URL}/map/search?field=genus`}
            onClick={() => {
              dispatch(setMinimized(true));
              dispatch(setCardContent(''));
            }}
          >
            Return To Map
          </Link>
          <h4>
            {genus} <ShareWidget />
          </h4>
          <span className={`chevron ${cardMinimized ? 'down' : 'up'}`} />
        </div>
        <div className='plant-names-wrapper'>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ul>
              {species.map((name: string, index: number) => {
                return (
                  <Link to={getPath('species', name)} key={index}>
                    <li>{splitByCharacter(name)}</li>
                  </Link>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default SpecificGenus;
