import React, { useEffect, useState } from 'react';
import _orderBy from 'lodash/orderBy';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import ExploreIcon from '@material-ui/icons/Check';
import ExploreOffIcon from '@material-ui/icons/Remove';

import Loader from '../Loader';
import IconGreenMap from '../icons/IconGreenMap';
import IconWhiteMap from '../icons/IconWhiteMap';
import ResultsError from '../error/ResultsError';
import IconChevronUp from '../icons/IconChevronUp';
import PhenologyChart from './SpecificPlant/PhenologyChart';
import PlantNotAlive from './SpecificPlant/PlantNotAlive';
import SpecificPlantTopDetails from './SpecificPlantTopDetails/SpecificPlantTopDetails';
import SpecificPlantGallery from './SpecificPlantGallery/SpecificPlantGallery';
import SpecificPlantFindOthers from './SpecificPlantFindOthers/SpecificPlantFindOthers';
import WidgetImage from './WidgetImage/WidgetImage';
import ShareWidget from '../share/ShareWidget';

// Utils
import mapController from '../../controllers/MapController';
import { decodePathValue, encodePathValue } from '../../utils/path.util';
import { getClean__CATALOG_DESC } from '../../utils/plant.util';

// Interfaces
import { PlantData } from '../../interfaces/plantData';
import { PhenologyChartDataInterface } from './SpecificPlant/PhenologyChart.util';

// REDUX Store
import { RootState } from '../../store/store';
import { setModalContent } from '../../store/app/modalSlice';
import { setSelectedPlant, updateFavoritePlants } from '../../store/app/appSlice';

// Configs
import { BASE_URL, isMobileDevice } from '../../config/config';

// Style
import '../../styles/css/plant.scss';
import './SpecificPlant.scss';

interface FavoritePlantData {
  commonName: string;
  scientificName: string;
  img: string;
}

function SpecificPlant() {
  const dispatch = useDispatch();
  const searchWidgetLocation = useSelector((state: RootState) => state.appReducer.searchWidgetLocation);
  const { value: specificPlant } = useParams();
  const { pathname } = useLocation();
  const { goBack, push } = useHistory();

  const allFavoritesString = localStorage.getItem('favoritePlants');
  const allFavorites = allFavoritesString ? (JSON.parse(allFavoritesString) as Array<FavoritePlantData>) : [];

  const [favorite, setFavorite] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [phenologyChartData, setPhenologyChartData] = useState<any>();
  const [plant, setPlant] = useState<PlantData>({
    OBJECTID: 0,
    ACC_NUM_AND_QUAL: '',
    NAME: '',
    NAME_NUM: 0,
    ACCEPT_FULL: '',
    CURRENT_CONDITION_FULL: '',
    ACC_YR: 0,
    RECD_YR: 0,
    PSOURCE_CURRENT: '',
    CURRENT_NUM_PLTS: '',
    CURRENT_LOCATION: '',
    CURRENT_LOCATION_FULL: '',
    RECD_HOW: '',
    PROV_TYPE: '',
    CURRENT_CHECK_NOTE: '',
    CURRENT_CHECK_DT: '',
    LEDITED_BY: '',
    CURRENT_CHECK_BY: '',
    ALIVE: '',
    ACCEPT: '',
    ACCEPT_NAME: null,
    FAMILY: '',
    GENUS: '',
    COMMON_NAME_PRIMARY: '',
    COMMON_NAMES: '',
    KEYWORD: '',
    RANGE: '',
    HABITAT: null,
    SOIL: '',
    SUN_FULL: '',
    SPEC_CHAR_FULL: '',
    HABIT_FULL: '',
    GROWTH_FORM_FULL: '',
    DECID_FULL: '',
    HARDI_ZONE_USDA: 7,
    FL_COLOR_FULL: '',
    FL_COLOR_NOTE: '',
    LEAF_COLOR_FULL: '',
    LEAF_COLOR_NOTE: '',
    FR_COLOR_FULL: '',
    HEIGHT: '',
    HEIGHT_X: null,
    HEIGHT_Y: null,
    HEIGHT_UNIT_DISP: '',
    SPREAD: 0,
    SPREAD_UNIT_DISP: '',
    CATALOG_DESC: '',
    CODE: null,
    MAPPED: '',
    X_COORD: 0,
    Y_COORD: 0,
    Z_COORD: null,
    LOCATION__CURRENT: '',
    PUBLICLY_ACCESSIBLE: '',
  });
  const [accessionData, setAccessionData] = useState<any>([]);
  const taxonomyType = pathname.includes('species') ? 'species' : 'commonname';
  const taxonomyValue = taxonomyType === 'species' ? plant?.NAME : plant?.COMMON_NAME_PRIMARY;

  useEffect(() => {
    const plantDataQuery = pathname.includes('commonname')
      ? mapController.queryPlantByCommonName
      : mapController.queryPlantBySpeciesName;
    const acessionDataQuery = pathname.includes('commonname')
      ? mapController.queryPlantsForAccessionIDsByCommonName
      : mapController.queryPlantsForAccessionIDsBySpecies;

    const handleSpecificPlant = decodePathValue(specificPlant);
    plantDataQuery(handleSpecificPlant).then((plantDataResults) => {
      if (plantDataResults && plantDataResults.length > 0) {
        const plantData = plantDataResults[0].attributes;
        setPlant(plantData);
        dispatch(setSelectedPlant(plantData));

        const { COMMON_NAME_PRIMARY, NAME } = plantData;
        const matchFound = allFavorites.find(({ commonName, scientificName }) => {
          return commonName === COMMON_NAME_PRIMARY && scientificName === NAME;
        });

        if (matchFound) {
          setFavorite(true);
        }

        setLoading(false);
      } else {
        setError(true);
      }
    });

    acessionDataQuery(handleSpecificPlant).then((accessionQueryResults) => {
      if (accessionQueryResults) {
        setAccessionData(accessionQueryResults);
      } else {
        setError(true);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specificPlant]);

  useEffect(() => {
    mapController.queryPlantBySpeciesNameForPhenologyChartData(plant.NAME).then((chartDataResults) => {
      if (chartDataResults) {
        setPhenologyChartData(chartDataResults);
      }
    });
  }, [plant]);

  const updateFavoritePlant = () => {
    localStorage.setItem('favoritePlants', JSON.stringify(allFavorites));
    dispatch(updateFavoritePlants(allFavorites));
    setFavorite(!favorite);
  };

  const removeFavoritePlant = () => {
    const favoritePlant = allFavorites.find((favoritePlant: FavoritePlantData) => {
      return favoritePlant.commonName === plant?.COMMON_NAME_PRIMARY && favoritePlant.scientificName === plant?.NAME;
    }) as FavoritePlantData;
    const index = allFavorites.indexOf(favoritePlant);
    allFavorites.splice(index, 1);

    updateFavoritePlant();
  };

  const addFavoritePlant = () => {
    allFavorites.push({
      commonName: plant?.COMMON_NAME_PRIMARY,
      scientificName: plant?.NAME,
      img: `image of ${plant?.NAME}`,
    });

    updateFavoritePlant();
  };

  const Accessions = ({ data }: any) => {
    const [showAll, setShowAll] = useState(false);

    const categories = ['Key', 'Surveyed', 'Accession', 'Year', 'Location', 'Publicly Accessible'];
    const prepareData = data.map((record: string[]) => {
      const accessionNumberAndQualifiers = record[2];
      const accessNumber = parseInt(accessionNumberAndQualifiers.split('/')[0]);
      const accessCharacter = accessionNumberAndQualifiers.split('*')[1];
      return [...record, accessNumber, accessCharacter];
    });
    const orderedData = _orderBy(prepareData, [3, 5, 6], ['asc', 'asc', 'asc']).map((plant) =>
      plant.slice(0, categories.length)
    );
    const accessionData = showAll ? orderedData : orderedData.slice(0, 5);

    return (
      <div className='accession-table'>
        <table>
          <thead>
            <tr>
              {categories.map((name: string, index: number) => (
                <th key={index} className={name}>
                  {name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {accessionData.map((row: any, index: number) => {
              const returnMapPin = (MAPPED: string) => {
                if (MAPPED === 'Y') {
                  return <ExploreIcon />;
                }

                if (MAPPED === 'N') {
                  return <ExploreOffIcon />;
                }

                /**
                 * * NOTE: MAPPED can be 'Y', 'N' or 'NULL'
                 * Y - alive & mapped
                 * N - alive & not mapped
                 * NULL - not alive, not mapped
                 */
              };

              return (
                <tr key={index} style={index % 2 === 0 ? {} : { backgroundColor: '#F6F1E4' }}>
                  {row.map((option: string, index: number) => {
                    const renderOption = index === 1 ? returnMapPin(option) : option;
                    return (
                      <td key={index} className={categories[index]}>
                        {index === 2 ? <Link to={`/plants/accession?id=${option}`}>{option}</Link> : renderOption}
                      </td>
                    );
                  })}
                </tr>
              );
            })}

            <tr className={orderedData.length <= 5 ? 'hidden' : ''}>
              <td colSpan={categories.length}>
                {orderedData.length > 5 && (
                  <button onClick={() => setShowAll(!showAll)}>
                    {showAll ? 'Hide Locations' : 'Show More Locations'}
                  </button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const viewOnMapURL = loading
    ? `${BASE_URL}/map`
    : `${BASE_URL}/map/${taxonomyType}/${encodePathValue(taxonomyValue)}`;

  return (
    <div className='SpecificPlant plant-container__wrapper'>
      <div className='plant-container index'>
        <div id='SpecificPlantTop' className='header'>
          <div className='SpecificPlantTop__plant-details'>
            <div className='SpecificPlantTop__buttons'>
              <button
                id='ViewOnMap'
                onClick={() => {
                  if (searchWidgetLocation) {
                    goBack();
                  } else {
                    push(viewOnMapURL);
                  }
                }}
              >
                <IconChevronUp fill={'#fff'} rotate={-90} />
                View on Map
                <IconWhiteMap />
              </button>

              <div className='SpecificPlantTopDetails'>
                <div className='header buttons'>
                  <div className='BackToSearch__button'>
                    <span className='BackToSearch__title'>{plant?.COMMON_NAME_PRIMARY}</span>
                  </div>

                  <ShareWidget />
                </div>
              </div>
            </div>

            {isMobileDevice && (
              <WidgetImage alt={plant.NAME} NAME_NUM={plant.NAME_NUM} height={90} width={75} gallery={true} />
            )}
          </div>

          <SpecificPlantTopDetails
            plant={plant}
            favorite={favorite}
            removeFavoritePlant={() => removeFavoritePlant()}
            addFavoritePlant={() => addFavoritePlant()}
          />
        </div>

        {loading ? (
          <Loader />
        ) : error ? (
          <ResultsError value={specificPlant} />
        ) : (
          <>
            {plant?.CATALOG_DESC && (
              <p
                className='description'
                dangerouslySetInnerHTML={{ __html: getClean__CATALOG_DESC(plant?.CATALOG_DESC) }}
              />
            )}

            <div className='grid section-two'>
              {plant?.GROWTH_FORM_FULL && (
                <>
                  <p className='plant-line'>
                    <strong>Growth Form:</strong>
                  </p>
                  <p className='plant-line'> {plant?.GROWTH_FORM_FULL}</p>
                </>
              )}
              {plant?.DECID_FULL && (
                <>
                  <p className='plant-line'>
                    <strong>Leaf Type:</strong>
                  </p>
                  <button
                    className='plant-line green-font button'
                    onClick={() => dispatch(setModalContent(`LeafType - ${plant?.DECID_FULL}`))}
                  >
                    {plant?.DECID_FULL}
                  </button>
                </>
              )}
              {plant?.HARDI_ZONE_USDA && (
                <>
                  <p className='plant-line'>
                    <strong>Hardiness:</strong>
                  </p>
                  <p className='plant-line'>
                    <span className='green-font'>
                      <a
                        href='https://planthardiness.ars.usda.gov/phzmweb/interactivemap.aspx'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        USDA Zone {plant?.HARDI_ZONE_USDA}
                      </a>
                    </span>
                  </p>
                </>
              )}
              {plant?.SOIL && (
                <>
                  <p className='plant-line'>
                    <strong>Soil:</strong>
                  </p>
                  <p className='plant-line'>{plant?.SOIL}</p>
                </>
              )}
              {plant?.SUN_FULL && (
                <>
                  <p className='plant-line'>
                    <strong>Sun Req:</strong>
                  </p>
                  <p className='plant-line'>{plant?.SUN_FULL}</p>
                </>
              )}
              {plant?.FL_COLOR_FULL && (
                <>
                  <p className='plant-line'>
                    <strong>Flowers:</strong>
                  </p>
                  <p className='plant-line'>{plant?.FL_COLOR_FULL}</p>
                </>
              )}
              {plant?.FR_COLOR_FULL && (
                <>
                  <p className='plant-line'>
                    <strong>Fruit:</strong>
                  </p>
                  <p className='plant-line'>{plant?.FR_COLOR_FULL}</p>
                </>
              )}
              {plant?.LEAF_COLOR_FULL && (
                <>
                  <p className='plant-line'>
                    <strong>Foliage Color:</strong>
                  </p>
                  <p className='plant-line'>{plant?.LEAF_COLOR_FULL}</p>
                </>
              )}
            </div>

            {/* Show if plant is not Alive and there is no accession Data */}
            {plant.ALIVE !== 'A' && accessionData.length === 0 && <PlantNotAlive />}

            {accessionData.length ? <Accessions data={accessionData} /> : null}
            {phenologyChartData && <PhenologyChart plant={plant} phenologyChartData={phenologyChartData} />}

            <div className='SpecificPlantFooter index-buttons-wrapper'>
              <Link className='view-on-map' to={viewOnMapURL}>
                View on Map
                <IconGreenMap />
              </Link>

              <SpecificPlantFindOthers GENUS={plant.GENUS} />
            </div>
          </>
        )}
      </div>

      {!isMobileDevice && <SpecificPlantGallery />}
    </div>
  );
}

export default SpecificPlant;
