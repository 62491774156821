import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Store
import {
  selectMapFilterActiveDropdowns,
  selectMapFilterSelectedOptions,
  setFilterActiveDropdowns,
  setFilterActiveOptions,
  setFilterDefinitionExpression,
  DropdownOption,
} from '../../store/app/filterSlice';
import { $searchType } from '../../store/app/appSlice';

import { setGAFilterEvent } from '../../utils/googleAnalytics';
import { filterOptionButtonHeight } from '../../config/filterPlants';

import IconBurgerMenuFilter from '../icons/IconBurgerMenuFilter';
import IconFlower from '../icons/IconFlower';
import IconLeaf from '../icons/IconLeaf';
import IconPlantType from '../icons/IconPlantType';
import IconSunlightNeeds from '../icons/IconSunlightNeeds';
import IconRadioButton from '../icons/IconRadioButton';
import SearchPlantRecordsFilter from './SearchPlantRecordsFilter/SearchPlantRecordsFilter';

import { LOCATIONS_SEARCH_LABEL } from '../../config/plant.config';

import '../../styles/css/filterPlants.scss';

const FilterPlants = () => {
  const dispatch = useDispatch();
  const searchType = useSelector($searchType);
  const mapFilterActiveDropdowns = useSelector(selectMapFilterActiveDropdowns);
  const mapFilterSelectedOptions = useSelector(selectMapFilterSelectedOptions);

  const [filterOptions, setFilterOptions] = useState(mapFilterSelectedOptions);

  useEffect(() => {
    if (searchType !== LOCATIONS_SEARCH_LABEL) {
      setFilterOptions(
        mapFilterSelectedOptions.filter((option) => {
          return option.label !== LOCATIONS_SEARCH_LABEL;
        })
      );
    }
  }, [mapFilterSelectedOptions, searchType]);

  const getOptionsCopy = () => {
    const filters = filterOptions.map((option: any) => {
      const optionsCopy = option.options.map((subOption: any) => {
        return {
          label: subOption.label,
          values: [...subOption.values],
        };
      });

      const configOptionCopy: DropdownOption = {
        activeValues: option.activeValues,
        esriField: option.esriField,
        esriQueryMatch: option.esriQueryMatch,
        icon: option.icon,
        label: option.label,
        options: optionsCopy,
        type: option.type,
      };

      if (option.activeRadioButton) {
        configOptionCopy.activeRadioButton = option.activeRadioButton;
      }

      return configOptionCopy;
    });

    return filters;
  };

  const setActiveRadioButton = (filterIndex: any, { label, values }: any) => {
    const filters = getOptionsCopy();

    const selectedDropdown = filters[filterIndex];
    selectedDropdown.activeValues = values;
    selectedDropdown.activeRadioButton = label;

    // setGAFilterEvent({ label: selectedDropdown.label, activeOption: selectedDropdown.activeRadioButton });
    dispatch(setFilterActiveOptions(filters));
    dispatch(setFilterDefinitionExpression(filters));
  };

  const setActiveCheckbox = (filterIndex: any, { label }: any) => {
    const filters = getOptionsCopy();

    const selectedDropdown = filters[filterIndex];
    let activeValues = [...selectedDropdown.activeValues];

    if (label === 'All') {
      activeValues = activeValues.includes('All') ? [] : ['All'];
    } else {
      activeValues = activeValues.filter((activeValue: string) => activeValue !== 'All');
      activeValues = activeValues.includes(label)
        ? activeValues.filter((activeValue: string) => activeValue !== label)
        : [...activeValues, label];
    }

    selectedDropdown.activeValues = activeValues;

    // setGAFilterEvent({ label: selectedDropdown.label, activeOption: label });
    dispatch(setFilterActiveOptions(filters));
    dispatch(setFilterDefinitionExpression(filters));
  };

  return (
    <div id='FilterModalContent'>
      <div className='filter-container' style={{ height: filterOptions?.length * filterOptionButtonHeight }}>
        {filterOptions.map((filter: any, filterIndex: number) => {
          const { label, options, activeRadioButton, type, activeValues } = filter;
          let icon;
          switch (filter.icon) {
            case 'IconFlower':
              icon = <IconFlower fill='#000' />;
              break;
            case 'IconLeaf':
              icon = <IconLeaf fill='#000' />;
              break;
            case 'IconPlantType':
              icon = <IconPlantType fill='#000' />;
              break;
            case 'IconSunlightNeeds':
              icon = <IconSunlightNeeds fill='#000' />;
              break;
            case 'IconBurgerMenu':
              icon = <IconBurgerMenuFilter fill='#000' />;
              break;
            default:
              icon = <IconBurgerMenuFilter fill='#000' />;
          }

          return (
            <div key={filterIndex}>
              <div className='option-button' onClick={() => dispatch(setFilterActiveDropdowns(label))}>
                <div className='label-wrapper'>
                  <span className='option-icon__container'>{icon}</span>
                  <span className='label'>{label}</span>
                  {type === 'checkbox' && !activeValues.includes('All') && activeValues.length > 0 && (
                    <span className='active-checkbox-num'>{activeValues.length}</span>
                  )}
                  {type === 'radio' && activeRadioButton && activeRadioButton !== 'All' && (
                    <span className='active-checkbox-num'>1</span>
                  )}
                </div>
                <span className={`chevron ${mapFilterActiveDropdowns === label ? 'up' : 'down'}`} />
              </div>
              {mapFilterActiveDropdowns === label && options.length ? (
                <div className='dropdown'>
                  <ul>
                    {(options as Array<object>).map((option: any, optionKey: number) => {
                      const selectedDropdown = filterOptions.find(
                        (dropdownOption: any) => dropdownOption.label === label
                      );
                      if (type === 'checkbox') {
                        return (
                          <li key={optionKey}>
                            <div
                              className='custom-checkbox-container'
                              onClick={() => setActiveCheckbox(filterIndex, option)}
                            >
                              <input
                                type='checkbox'
                                className='checkbox'
                                checked={selectedDropdown?.activeValues.includes(option.label)}
                              />
                              <span className='checkmark' />
                              <label>{option.label}</label>
                            </div>
                          </li>
                        );
                      } else {
                        return (
                          <li key={optionKey} onClick={() => setActiveRadioButton(filterIndex, option)}>
                            <IconRadioButton checked={selectedDropdown?.activeRadioButton === option.label} />
                            <label>{option.label}</label>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>

      <SearchPlantRecordsFilter />
    </div>
  );
};

export default FilterPlants;
