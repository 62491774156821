import React from 'react';

import './IconReset.scss';

const IconReset = () => (
  <div className='IconReset__container'>
    <span className='IconReset__desktop'>reset</span>
    <span className='IconReset__mobile esri-icon-close-circled' />
  </div>
);

export default IconReset;
