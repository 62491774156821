import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { $apiPlantImages } from '../../../store/app/appSlice';
import { getPlantImages } from '../../../utils/plant.util';

import IconPhotoLibrary from '../../icons/IconPhotoLibrary';
import { setModalContent, setModalGardenLocation } from '../../../store/app/modalSlice';
import NYBGLogo from '../../../images/NYBG_Logo_Black_RGB.svg';

import './WidgetImage.scss';

export default function WidgetImage({
  alt,
  width = 125,
  height = 80,
  NAME_NUM,
  garden,
  gallery = false,
  tour = false,
}: {
  alt: string;
  width?: number;
  height?: number;
  NAME_NUM?: number;
  garden?: any;
  gallery?: boolean;
  tour?: boolean;
}) {
  const dispatch = useDispatch();
  const [plantImage, setPlantImage] = useState<string | undefined>(undefined);
  const apiPlantImages = useSelector($apiPlantImages);

  useEffect(() => {
    if (!tour && NAME_NUM && !apiPlantImages.NAME_NUMS.includes(NAME_NUM!)) {
      getPlantImages(NAME_NUM).then((plantsImages) => {
        handleSetPlantImage(plantsImages, NAME_NUM);
      });
    }
  }, [NAME_NUM]);

  useEffect(() => {
    if (apiPlantImages?.NAME_NUMS.includes(NAME_NUM!)) {
      handleSetPlantImage(apiPlantImages, NAME_NUM);
    }

    if (garden?.thumbnailUrl) {
      setPlantImage(garden.thumbnailUrl);
    }
  }, [NAME_NUM, garden]);

  function handleSetPlantImage(plantsImages: any, NAME_NUM: any) {
    const plantImagesCollection = plantsImages?.images[NAME_NUM];

    if (plantImagesCollection?.cover) {
      const thumbnailUrl = plantImagesCollection?.cover?.thumbnailUrl;
      setPlantImage(thumbnailUrl);
    } else {
      setPlantImage('');
    }
  }

  const handlePlantGalleryModal = () => {
    dispatch(setModalContent('PlantGalleryModal'));
  };

  const handleGardenGalleryModal = () => {
    dispatch(setModalGardenLocation(garden));
    dispatch(setModalContent('GardenGalleryModal'));
  };

  return (
    <div
      className='WidgetImage__container'
      style={{ alignSelf: 'center', width: width, minWidth: width, height: height, backgroundColor: '#ccc' }}
    >
      {plantImage === '' ? (
        <span className='WidgetImage__placeholder'>IMAGE COMING SOON</span>
      ) : (
        <>
          <img
            className='WidgetImage'
            src={plantImage || NYBGLogo}
            alt={alt}
            style={{ minWidth: width, objectFit: plantImage ? 'cover' : undefined }}
            width={width}
            height={height}
          />

          {gallery && (
            <button
              className='PlantGalleryModal__button'
              onClick={() => {
                if (garden?.thumbnailUrl) {
                  handleGardenGalleryModal();
                } else {
                  handlePlantGalleryModal();
                }
              }}
            >
              <IconPhotoLibrary />
            </button>
          )}
        </>
      )}
    </div>
  );
}
