import * as React from 'react';
import { SVG_CONFIG } from '../../config/config';

function IconSunlightNeeds({ fill }: { fill?: string }) {
  return (
    <svg width={35} height={35} viewBox='0 0 35 35'>
      <path
        fill={fill ? fill : SVG_CONFIG.fill}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 13.88a4.11 4.11 0 100 8.22 4.11 4.11 0 000-8.22zm0 6.74a2.635 2.635 0 110-5.27 2.635 2.635 0 010 5.27zm-7.71-3.66C9.58 16.96 9 17.42 9 18c0 .57.58 1.03 1.29 1.03.7 0 1.26-.46 1.26-1.03 0-.57-.55-1.03-1.26-1.03v-.01zM24.3 11.7c-.4-.4-1.13-.32-1.64.18-.5.5-.58 1.23-.18 1.63.4.4 1.14.32 1.64-.18s.58-1.23.18-1.63zm1.4 5.27c-.7 0-1.26.46-1.26 1.03 0 .57.55 1.03 1.26 1.03s1.3-.47 1.3-1.04c0-.57-.58-1.03-1.29-1.03l-.01.01zM18 24.41c-.57 0-1.03.57-1.03 1.28 0 .71.46 1.29 1.03 1.29.57 0 1.03-.58 1.03-1.29 0-.7-.46-1.28-1.03-1.28zm-6.13-1.63c-.5.5-.58 1.24-.18 1.64.4.4 1.14.32 1.64-.18s.58-1.24.18-1.64c-.4-.4-1.13-.32-1.64.18zm-.24-11.03c-.4.4-.32 1.13.18 1.64.5.5 1.23.58 1.64.18.4-.4.32-1.14-.19-1.64-.5-.5-1.23-.58-1.63-.18zm11.1 12.42c.5.5 1.24.59 1.64.18.4-.4.32-1.13-.18-1.63s-1.23-.58-1.63-.18c-.4.4-.32 1.13.18 1.63h-.01zM18 11.57c.57 0 1.03-.58 1.03-1.29 0-.7-.46-1.28-1.03-1.28-.57 0-1.03.57-1.03 1.28 0 .71.46 1.29 1.03 1.29z'
      />
    </svg>
  );
}

export default IconSunlightNeeds;
