import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PHENOLOGY_SEARCH_YEAR } from '../../utils/phenologySearch';
import { RootState } from '../store';

export interface FloweringRecord {
  dateString: string;
  dayOfYear: number;
  name: string;
  commonName: string;
  NAME: string;
  COMMON_NAME_PRIMARY: string;
  ALIVE: string;
}

export interface FloweringDay {
  date: string;
  dayOfYear: number;
}

export interface PhenologyState {
  phenologyFlowering: Array<FloweringRecord> | undefined;
  phenologyFloweringDayStart: FloweringDay | undefined;
  phenologyFloweringDayEnd: FloweringDay | undefined;
  phenologyFloweringResults: Array<FloweringRecord> | undefined;
  phenologyDisplayFloweringResults: boolean;
  phenologySliderStartingDate: string;
  phenologySliderValues:
    | {
        start: number;
        end: number;
      }
    | undefined;
}

const initialState: PhenologyState = {
  phenologyFlowering: undefined,
  phenologyFloweringDayStart: undefined,
  phenologyFloweringDayEnd: undefined,
  phenologyFloweringResults: undefined,
  phenologyDisplayFloweringResults: false,
  phenologySliderStartingDate: new Date(PHENOLOGY_SEARCH_YEAR, 0, 1).toString(),
  phenologySliderValues: undefined,
};

export const phenologySlice = createSlice({
  name: 'phenology',
  initialState,
  reducers: {
    setPhenologyFlowering: (state: PhenologyState, action: PayloadAction<PhenologyState['phenologyFlowering']>) => {
      state.phenologyFlowering = action.payload;
    },
    setPhenologyFloweringDateStart: (
      state: PhenologyState,
      action: PayloadAction<PhenologyState['phenologyFloweringDayStart']>
    ) => {
      state.phenologyFloweringDayStart = action.payload;
    },
    setPhenologyFloweringDateEnd: (
      state: PhenologyState,
      action: PayloadAction<PhenologyState['phenologyFloweringDayEnd']>
    ) => {
      state.phenologyFloweringDayEnd = action.payload;
    },
    setPhenologyFloweringResults: (
      state: PhenologyState,
      action: PayloadAction<PhenologyState['phenologyFloweringResults']>
    ) => {
      state.phenologyFloweringResults = action.payload;
    },
    setPhenologyDisplayFloweringResults: (
      state: PhenologyState,
      action: PayloadAction<PhenologyState['phenologyDisplayFloweringResults']>
    ) => {
      state.phenologyDisplayFloweringResults = action.payload;
    },
    setPhenologySliderStartingDate: (
      state: PhenologyState,
      action: PayloadAction<PhenologyState['phenologySliderStartingDate']>
    ) => {
      state.phenologySliderStartingDate = action.payload;
    },
    setPhenologySliderValues: (
      state: PhenologyState,
      action: PayloadAction<PhenologyState['phenologySliderValues']>
    ) => {
      state.phenologySliderValues = action.payload;
    },
  },
});

export const {
  setPhenologyFlowering,
  setPhenologyFloweringDateStart,
  setPhenologyFloweringDateEnd,
  setPhenologyFloweringResults,
  setPhenologyDisplayFloweringResults,
  setPhenologySliderValues,
  setPhenologySliderStartingDate,
} = phenologySlice.actions;

export const $phenologyFlowering = (state: RootState) => state.phenologyReducer.phenologyFlowering;
export const $phenologyFloweringDayStart = (state: RootState) => state.phenologyReducer.phenologyFloweringDayStart;
export const $phenologyFloweringDayEnd = (state: RootState) => state.phenologyReducer.phenologyFloweringDayEnd;
export const $phenologyFloweringResults = (state: RootState) => state.phenologyReducer.phenologyFloweringResults;
export const $phenologyDisplayFloweringResults = (state: RootState) =>
  state.phenologyReducer.phenologyDisplayFloweringResults;
export const $phenologySliderStartingDate = (state: RootState) => state.phenologyReducer.phenologySliderStartingDate;
export const $phenologySliderValues = (state: RootState) => state.phenologyReducer.phenologySliderValues;

export default phenologySlice.reducer;
