// Map link
export const MAP_LINK__TOURS = '/map/tours';
export const MAP_LINK__GENUS = '/map/genus/';
export const MAP_LINK__SPECIES = '/map/species/';
export const MAP_LINK__LOCATION = '/map/location/';
export const MAP_LINK__COMMON_NAME = '/map/commonname/';

// Plants link
export const PLANTS_LINK__SPECIES = '/plants/species/';
export const PLANTS_LINK__ACCESSION = '/plants/accession';

export const SPACE__ORIGINAL = ' ';
export const SPACE__REPLACE_PATTERN = '--';
export const SLASH__ORIGINAL = '/';
export const SLASH__REPLACE_PATTERN = '~';

/**
 * Decode path value by replacing:
 * - double dash with space (`--` => ` `)
 * - tilde with slash (`~` => `/`)
 *
 * @param {string} pathValue - Path value
 * @return {string} Handled path value
 */
export function decodePathValue(pathValue: string) {
  const spaceRegExp = new RegExp(SPACE__REPLACE_PATTERN, 'g');
  const slashRegExp = new RegExp(SLASH__REPLACE_PATTERN, 'g');
  let path = pathValue.includes(SPACE__REPLACE_PATTERN) ? pathValue.replace(spaceRegExp, SPACE__ORIGINAL) : pathValue;
  path = path.includes(SLASH__REPLACE_PATTERN) ? path.replace(slashRegExp, SLASH__ORIGINAL) : path;
  return path;
}

/**
 * Encode path value by replacing:
 * - space with double dash (` ` => `--`)
 * - slash with tilde (`/` => `~`)
 *
 * @param {string} pathValue - Path value
 * @return {string} Updated path value
 */
export function encodePathValue(pathValue: string) {
  return pathValue
    .split(SPACE__ORIGINAL)
    .join(SPACE__REPLACE_PATTERN)
    .split(SLASH__ORIGINAL)
    .join(SLASH__REPLACE_PATTERN);
}

/**
 * Get Path by section type
 *
 * @param {string} type
 * @param {string} pathValue
 * @return {string} - Path
 */
export const getPath = (type: string, pathValue: string) => {
  let path;
  switch (type) {
    case 'species':
      path = MAP_LINK__SPECIES + encodePathValue(pathValue);
      break;
    case 'genus':
      path = MAP_LINK__GENUS + encodePathValue(pathValue);
      break;
    case 'location':
      path = MAP_LINK__LOCATION + encodePathValue(pathValue);
      break;
    case 'commonname':
      path = MAP_LINK__COMMON_NAME + encodePathValue(pathValue);
      break;
    default:
      path = '';
  }

  return path;
};
