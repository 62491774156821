import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import '../../styles/css/tours.scss';
import { TourOptionsItem } from './TourOptionsItem/TourOptionsItem';
import { $toursAll, $tourSelected, setTourSelected, setTourStopIndex } from '../../store/app/tourSlice';
import { getTourSelected, updateTourPathURL } from '../../utils/tour.util';

const TourOptions = ({ startCuratedTour }: any) => {
  const dispatch = useDispatch();
  const toursAll = useSelector($toursAll);
  const tourSelected = useSelector($tourSelected);
  const [activeDropdown, setActiveDropdown] = useState<Array<number>>([0]);

  useEffect(() => {
    if (tourSelected.Tour_ID) {
      setActiveDropdown([tourSelected.Tour_ID]);
    }
  }, []); // eslint-disable-line

  const setDropdowns = (tourID: number) => {
    const activeDropdownCopy = [...activeDropdown];
    if (activeDropdownCopy.includes(tourID)) {
      const index = activeDropdownCopy.indexOf(tourID);
      activeDropdownCopy.splice(index);
    } else {
      activeDropdownCopy.push(tourID);
    }
    setActiveDropdown(activeDropdownCopy);
  };

  const setTourSelection = (tourID: number) => {
    setDropdowns(tourID);
    const tourSelected = getTourSelected(toursAll, tourID);
    if (tourSelected) {
      dispatch(setTourSelected(tourSelected));
    }

    updateTourPathURL(tourID);
  };

  const selectedTour = tourSelected.Tour_ID;
  return (
    <>
      <div className='TourMain tour-options-container'>
        <div className='options-container'>
          {toursAll.map(({ Description, Tour_ID, Tour_Name }, key) => {
            return (
              <div className='option' key={`tour_${key}`}>
                <TourOptionsItem
                  tourID={Tour_ID}
                  tourName={Tour_Name}
                  selectedTour={selectedTour}
                  numbers={activeDropdown}
                  onClick={() => setDropdowns(Tour_ID)}
                  onChange={() => {
                    setTourSelection(Tour_ID);
                    dispatch(setTourStopIndex(0));
                  }}
                />

                {activeDropdown.includes(Tour_ID) && (
                  <>
                    <div className='description-wrapper'>
                      <p className='description'>
                        {Description}
                        {/* {tourStops?.length ? (
                          <button onClick={() => setRenderTourStops(!tourStopsActive)}>
                            {tourStopsActive ? 'view less' : 'view more'}
                          </button>
                        ) : null} */}
                        {/* {tourStops?.length && tourStopsActive ? (
                          <>
                            <h4 className='tour-title'>Stops Along The Tour</h4>
                            <ol className='tour-stops'>
                              {tourStops.map(({ scientificName, commonName }) => {
                                return (
                                  <li>
                                    <span className='scientific-name'>{scientificName} </span>
                                    <br />
                                    <span className='common-name'>({commonName})</span>
                                  </li>
                                );
                              })}
                            </ol>
                          </>
                        ) : null} */}
                      </p>
                      {/* <p className='duration'>
                        <strong>Duration: </strong>
                        {duration}hr Est.
                      </p> */}
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <div className='TourFooter tour-buttons-container'>
        <button
          className={`start-tour ${selectedTour ? '' : 'faded'}`}
          onClick={() => startCuratedTour(selectedTour)}
          disabled={selectedTour ? false : true}
        >
          Next <span className='chevron' />
        </button>
      </div>
    </>
  );
};

export default TourOptions;
