import React from 'react';
import { MONTHS_SHORT, WEEKS_IN_YEAR_RANGE } from './PhenologyChart.config';

const PhenologyTableHead = (prop: { class?: string }) => (
  <tr className={prop.class}>
    <td />
    <td className='phenology-chart__table-header--cell' colSpan={WEEKS_IN_YEAR_RANGE.length}>
      <div className={`phenology-chart__table-header`}>
        {MONTHS_SHORT.map((month, index) => (
          <div key={index} className='phenology-chart__table-header--month table-cell'>
            {month}
          </div>
        ))}
      </div>
    </td>
  </tr>
);

export default PhenologyTableHead;
