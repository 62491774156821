import React from 'react';

import { setGAShareEvent } from '../../utils/googleAnalytics';

import IconFacebook from '../icons/IconFacebook';
import IconX from '../icons/IconX';
import IconInstagram from '../icons/IconInstagram';
import IconGmail from '../icons/IconGmail';

import '../../styles/css/share.scss';

// import shareIcon from '../../styles/images/shareWidget/copyLink.svg';

const Share = () => {
  const popupDimensions = 'toolbar=0,status=0,height=650,width=450';

  const shareTwitter = (): void => {
    window.open(`https://twitter.com/intent/tweet?url=${window.location.href}`, 'Twitter', popupDimensions);
  };

  const shareFacebook = (): void => {
    window.open(`https://www.facebook.com/sharer.php?u=${window.location.href}`, 'Facebook', popupDimensions);
  };

  return (
    <div className='share-container'>
      <p>Share with:</p>
      <div className='button-wrapper'>
        <button
          onClick={() => {
            // setGAShareEvent('facebook');
            shareFacebook();
          }}
        >
          <IconFacebook />
        </button>
        <button
          onClick={() => {
            // setGAShareEvent('twitter');
            shareTwitter();
          }}
        >
          <IconX className='x-icon' />
        </button>
        <button
          onClick={() => {
            // setGAShareEvent('instagram');
            window.open('https://www.instagram.com/nybg/');
          }}
        >
          <IconInstagram />
        </button>

        <button
          onClick={() => {
            // setGAShareEvent('gmail');
            window.open(
              `https://mail.google.com/mail/u/0/?view=cm&fs=1&subject=Plant%20Tracker&body=Visit ${window.location.href}`
            );
          }}
        >
          <IconGmail />
        </button>

        {/* <button onClick={() => console.log('open a modal that renders a shareable link!')}>
          <img src={shareIcon} alt='chain link' />
        </button> */}
      </div>
    </div>
  );
};

export default Share;
