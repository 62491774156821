import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

import { decodePathValue } from '../../utils/path.util';
import { TourStop } from '../../interfaces/plantData';
import { URL__TOUR_ID, URL__TOUR_NAME, URL__TOUR_STOP } from '../../config/plant.config';

export const defaultTourStopIndex = -1;

const query = new URLSearchParams(window.location.search);
const searchTourID: string | null = query.get(URL__TOUR_ID);
const searchTourName: string | null = query.get(URL__TOUR_NAME);
const searchTourStop: string | null = query.get(URL__TOUR_STOP);

export type TourSelected = {
  Tour_ID: number | undefined;
  Tour_Name: string | undefined;
  Description: string | undefined;
};

export interface TourState {
  stopsByAccession: Array<string> | [];
  toursAll: Array<TourSelected>;
  tourSelected: TourSelected;
  tourStep: string;
  tourStops: Array<TourStop> | [];
  tourStopIndex: number;
}

export const tourSlice = createSlice({
  name: 'tour',
  initialState: {
    stopsByAccession: [],
    toursAll: [],
    tourSelected: {
      Tour_ID: searchTourID ? parseInt(searchTourID) : undefined,
      Tour_Name: searchTourName ? decodePathValue(searchTourName) : undefined,
      Description: undefined,
    },
    tourStep: 'ViewTours',
    tourStops: [],
    tourStopIndex: searchTourStop ? parseInt(searchTourStop) - 1 : defaultTourStopIndex,
  },
  reducers: {
    setStopsByAccession: (state: TourState, action: PayloadAction<TourState['stopsByAccession']>) => {
      state.stopsByAccession = action.payload;
    },
    setToursAll: (state: TourState, action: PayloadAction<TourState['toursAll']>) => {
      state.toursAll = action.payload;
    },
    setTourSelected: (state: TourState, action: PayloadAction<TourState['tourSelected']>) => {
      state.tourSelected = action.payload;
    },
    setTourStep: (state: TourState, action: PayloadAction<TourState['tourStep']>) => {
      state.tourStep = action.payload;
    },
    setTourStops: (state: TourState, action: PayloadAction<TourState['tourStops']>) => {
      state.tourStops = action.payload;
    },
    setTourStopIndex: (state: TourState, action: PayloadAction<TourState['tourStopIndex']>) => {
      state.tourStopIndex = action.payload;
    },
  },
});

export const { setStopsByAccession, setToursAll, setTourSelected, setTourStep, setTourStops, setTourStopIndex } =
  tourSlice.actions;

export const stopsByAccession = (state: RootState) => state.tourReducer.stopsByAccession;
export const $toursAll = (state: RootState) => state.tourReducer.toursAll;
export const $tourSelected = (state: RootState) => state.tourReducer.tourSelected;
export const tourStep = (state: RootState) => state.tourReducer.tourStep;
export const $tourStep = (state: RootState) => state.tourReducer.tourStep;
export const $tourStops = (state: RootState) => state.tourReducer.tourStops;
export const $tourStopIndex = (state: RootState) => state.tourReducer.tourStopIndex;

export default tourSlice.reducer;
