import React from 'react';
import { useSelector } from 'react-redux';
import ModalUI from '@material-ui/core/Modal';

// REDUX Store
import { $modalContent } from '../../store/app/modalSlice';

import { ModalHeader } from './ModalHeader';
import { ModalFooter } from './ModalFooter';
import { ModalMain } from './ModalMain';
import { ModalClose } from './ModalClose';

import './Modal.scss';

function Modal(props: {
  open: boolean;
  showModal: boolean;
  handleClose: any;
  title?: string;
  subTitle?: string;
  headerAction?: any;
  mainModalPaddingHide?: boolean;
  footerLeftLabel?: string;
  footerLeftClass?: string;
  footerRightLabel?: string;
  footerRightClass?: string;
  content?: any;
  onRequestClose?: any;
}) {
  const modalContent = useSelector($modalContent);
  const {
    open,
    showModal,
    handleClose,
    title,
    subTitle,
    headerAction,
    mainModalPaddingHide,
    footerLeftLabel,
    footerLeftClass,
    footerRightLabel,
    footerRightClass,
    content,
  } = props;

  return (
    <ModalUI
      open={open}
      onClose={handleClose}
      style={{
        display: showModal ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '1rem',
      }}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
    >
      <div
        id='Modal'
        className={`${modalContent}__modal`}
        style={{
          outline: 0,
        }}
      >
        {title || subTitle ? <ModalHeader title={title} subTitle={subTitle} headerAction={headerAction} /> : null}

        {content && <ModalMain content={content} mainModalPaddingHide={mainModalPaddingHide} />}

        {footerLeftLabel || footerRightLabel ? (
          <ModalFooter
            footerLeftLabel={footerLeftLabel}
            footerLeftClass={footerLeftClass}
            footerRightLabel={footerRightLabel}
            footerRightClass={footerRightClass}
          />
        ) : null}

        <ModalClose handleClose={handleClose} />
      </div>
    </ModalUI>
  );
}

export default Modal;
