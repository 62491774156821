import React from 'react';

import PlantResultGenusCommonNamePrimary from '../PlantResultCommonNamePrimary/PlantResultGenusCommonNamePrimary';
import PlantResultCommonNamePreferred from '../PlantResultCommonNamePreferred/PlantResultCommonNamePreferred';
import PlantResultScientificName from '../PlantResultScientificName/PlantResultScientificName';

import IconPlantCheckmark from '../../../icons/IconPlantCheckmark';
import IconPlantClose from '../../../icons/IconPlantClose';

import './PlantResultCommonNameItem.scss';

export default function PlantResultCommonNameItem({ plant, width }: { width: any; plant: any }) {
  const { NAME, GENUS_COMMON_NAME_PRIMARY, COMMON_NAME_PRIMARY } = plant;

  return (
    <div style={{ width: `${width}%` }} className='plant-result-container'>
      <div className='species-commonname'>
        <div className='PlantResultCommonNameItem__image'>
          {plant.ALIVE === 'A' && <IconPlantCheckmark />}
          {plant.ALIVE === null && <IconPlantClose />}
        </div>
        <div className='PlantResultCommonNameItem__label'>
          {GENUS_COMMON_NAME_PRIMARY && <PlantResultGenusCommonNamePrimary plant={plant} />}
          {COMMON_NAME_PRIMARY && <PlantResultCommonNamePreferred plant={plant} />}
          <PlantResultScientificName NAME={NAME} />
        </div>
      </div>
    </div>
  );
}
