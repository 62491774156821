import React from 'react';

const IconGreenMap = () => {
  return (
    <svg width='14px' height='14px' viewBox='0 0 14 14' version='1.1'>
      <title>Green map</title>
      <g id='Homepage-Mobile_v2' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Mobile-Plant_Page-Full' transform='translate(-223.000000, -896.000000)' fill='#328555'>
          <g id='Group-12' transform='translate(8.000000, 72.000000)'>
            <g id='Group-74' transform='translate(62.000000, 817.000000)'>
              <g id='Group-77' transform='translate(56.000000, 0.000000)'>
                <g id='Group-76' transform='translate(13.000000, 5.000000)'>
                  <g id='map' transform='translate(84.000000, 2.000000)'>
                    <path
                      d='M13.6111111,0 L13.4555556,0 L9.33333333,1.63333333 L4.66666667,0 L0.311111111,1.47777778 C0.155555556,1.55555556 0,1.63333333 0,1.86666667 L0,13.6111111 C0,13.8444444 0.155555556,14 0.388888889,14 L0.544444444,14 L4.66666667,12.3666667 L9.33333333,14 L13.6888889,12.5222222 C13.8444444,12.4444444 14,12.2888889 14,12.1333333 L14,0.388888889 C14,0.155555556 13.8444444,0 13.6111111,0 L13.6111111,0 Z M9.33333333,12.4444444 L4.66666667,10.8111111 L4.66666667,1.55555556 L9.33333333,3.18888889 L9.33333333,12.4444444 L9.33333333,12.4444444 Z'
                      id='Shape'
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconGreenMap;
