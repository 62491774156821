import * as React from 'react';
import { SVG_CONFIG } from '../../config/config';

const IconSearch = () => (
  <svg width={35} height={35} viewBox='0 0 35 35'>
    <title>Icon Search</title>
    <path
      fill={SVG_CONFIG.fill}
      d='M22.28 20.182h-.98l-.367-.367a7.425 7.425 0 001.953-5.023c0-4.309-3.538-7.792-7.938-7.792S7 10.483 7 14.792c0 4.308 3.547 7.791 7.947 7.791a7.789 7.789 0 005.134-1.925l.367.367v.953l6.104 5.995 1.834-1.796-6.105-5.995zm-7.333 0c-3.061 0-5.5-2.402-5.5-5.39a5.426 5.426 0 015.5-5.4c3.053 0 5.5 2.402 5.5 5.4a5.428 5.428 0 01-5.5 5.39z'
      fillRule='evenodd'
    />
  </svg>
);

export default IconSearch;
